<template>
    <div class="w-full h-full flex items-center justify-center">
        <img v-if="ticker.indexOf('_') == -1" :src="`https://logo.synthfinance.com/ticker/${ticker?.split('.')[0]}`" class="rounded-full w-full h-full object-contain" />
         <span v-else>{{ticker[0]}}</span>
    </div>
</template>
<script>
export default{
    name:'TickerIcon',
    props:['ticker'],
}
</script>
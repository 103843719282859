<template>
  <div class="w-full h-full">
    <FormLayout>
      <div v-if="success">
        <div>
          <span class="largeText" style="font-size: 44px"
            >Your password has been reset.
            <router-link :to="{ name: 'SignIn' }">
              <span
                style="color: #f20e17"
                class="hover:underline cursor-pointer"
                >Sign In</span
              >
            </router-link>
          </span>
        </div>
      </div>
      <div v-else class="w-full h-full md:space-y-7">
        <div
          class="formFreeTrial mt-[1.5rem]"
          style="
            height: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 10px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <span class="headerTitle text-appGrayDark"> Reset Password </span>
          <span
            v-if="showResponseError"
            class="errorText"
            style="
              color: #f20e17;
              font-size: 15px;
              margin-top: 4%;
              font-weight: 300;
            "
            >{{ showResponseError }}</span
          >
        </div>
        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            :type="showPassword ? 'text' : 'password'"
            placeholder="New password"
            :iconImg="
              require(`@/assets/${
                isPasswordError
                  ? 'redEye.svg'
                  : showPassword
                  ? 'openEye.svg'
                  : 'closeEye.svg'
              }`)
            "
            v-model="password"
            :isError="isPasswordError"
            @password-visibility-toggled="handlePasswordVisibilityToggle"
          />
        </div>
        <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            passwordErrorMsg
          }}</span>
        </div>
        <div class="inputHeight mt-[2rem]" style="height: 8%">
          <DynamicInput
            :type="showPassword ? 'text' : 'password'"
            placeholder="Confirm password"
            :iconImg="
              require(`@/assets/${
                isNewPasswordError
                  ? 'redEye.svg'
                  : showPassword
                  ? 'openEye.svg'
                  : 'closeEye.svg'
              }`)
            "
            v-model="confirmPassword"
            :isError="isNewPasswordError"
            @enter-pressed="handleReset"
            @password-visibility-toggled="handlePasswordVisibilityToggle"
          />
        </div>
        <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            newPasswordErrorMsg
          }}</span>
        </div>

        <div style="height: 8%" class="btnStyle inputHeight !mt-10">
          <button
            :class="
              password && confirmPassword
                ? 'bg-appBlue hover:bg-blueHover'
                : 'bg-[#E0E0E0] hover:bg-lightGrayHover'
            "
            @click="handleReset"
            :style="{
              color: password && confirmPassword ? 'white' : '#828282',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
            }"
            style="
              height: 100%;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
          >
            <span v-if="isLoading" style="margin-right: 10px">
              <Loader customClass="w-8 h-8" />
            </span>
            <span>
              {{ isLoading ? 'Sending link' : 'Send link' }}
            </span>
            <div v-if="password && confirmPassword" style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import FormLayout from '../component/FormLayout.vue'
import DynamicInput from '../component/DynamicInput.vue'
import Loader from '../component/Loading.vue'
import { ResetPassword } from '../services/user'

export default {
  name: 'ResetPassword',
  components: {
    FormLayout,
    DynamicInput,
    Loader,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordErrorMsg: '',
      newPasswordErrorMsg: '',
      showPassword: false,
      isPasswordError: false,
      isNewPasswordError: false,
      showResponseError: '',
      isLoading: false,
      success: false,
      passwordRegix:
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,}$/,
    }
  },
  methods: {
    async handleReset() {
      if (!this.password && !this.confirmPassword) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = '*Required Field'
        return
      }

      if (!this.password) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        return
      }
      if (!this.confirmPassword) {
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = '*Required Field'
        return
      }
      if (this.password) {
        if (!this.passwordRegix.test(this.password)) {
          this.passwordErrorMsg =
            'The password must be more than 9 characters including uppercase,lowercase,numbers and special characters.'
          return
        }
      }

      if (this.password != this.confirmPassword) {
        this.isNewPasswordError = true
        this.newPasswordErrorMsg = 'Passwords do not match.'
        return
      }

      this.isLoading = true
      let en = this.$route.query.en
      let iv = this.$route.query.iv
      const payload = {
        password: this.password,
        confirmPassword: this.confirmPassword,
        en: en,
        iv: iv,
      }

      const res = await ResetPassword(payload)
      if (!res?.data?.error) {
        this.success = true
      } else {
        this.showResponseError = res?.data?.message
        setTimeout(() => {
          this.showResponseError = ''
        }, 5000)
      }
      this.isLoading = false
    },
    handlePasswordVisibilityToggle() {
      this.showPassword = !this.showPassword
    },
  },
  watch: {
    password(newPassword) {
      this.isPasswordError = false
      this.passwordErrorMsg = ''
    },
    confirmPassword(newPassword) {
      this.isNewPasswordError = false
      this.newPasswordErrorMsg = ''
    },
  },
}
</script>

<template>
  <div class="w-full h-full bg-slate-200 rounded-[8px] border-red-300 border space-y-[1px]">
    <!-- first filter -->
    <Wrapper
    :activeClass="true"
      id="bollingerSqueeze"
      :selectedKey="'Bollinger Squeeze Detection'"
    >
      <div
        class="flex flex-col justify-between gap-3 -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Bollinger Squeeze Detection
        </h3>

        <div class="w-[100%] gap-3 flex items-center">
          <span>Min scanning period</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            value="200"
            min="0"
            v-model="settings.minScanningPeriod"
          />
          <span>days.</span>
        </div>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Max scanning period</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            value="250"
            min="0"
            v-model="settings.maxScanningPeriod"
          />
          <span>days</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Set the range for the scanning period to analyze potential squeeze events over different time frames.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- Second filter -->
    <Wrapper
      :activeClass="true"
      id="bollingerSqueeze"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">Breakout Timing</h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span class=""
            >Days from today when squeeze started breaking out</span
          >
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            value="5"
            min="0"
            v-model="settings.dayFromToday"
          />
          <span>days.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Specify how many days ago the Bollinger Band squeeze started to break out.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- Third filter -->
    <Wrapper
      :activeClass="true"
      id="bollingerSqueeze"
    >
      <div
        class="flex flex-col justify-between gap-3 -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">Squeeze Duration</h3>

        <div class="w-[100%] gap-3 flex items-center">
          <span>Min squeeze length</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            value="20"
            min="0"
            v-model="settings.minSqueezeLength"
          />
          <span>days.</span>
        </div>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Max squeeze length</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="number"
            value="40"
            min="0"
            v-model="settings.maxSqueezeLength"
          />
          <span>days.</span>
        </div>

        <div>
          <DescriptionInput
            :description="` Define the minimum and maximum length of time the squeeze should last to qualify for this scan.`"
          />
        </div>
      </div>
    </Wrapper>

    <!-- fourth filter -->
    <Wrapper
      :activeClass="true"
      id="bollingerSqueeze"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Spike to Squeeze Ratio
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Ratio of spike height to squeeze height</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 12%; height: 45px; font-size: 16px"
            type="number"
            value="10"
            min="0"
            v-model="settings.ratioOfSpikeHeight"
          />
        </div>

        <div>
          <DescriptionInput
            :description="` Set the ratio of the spike (price movement) height compared to the squeeze height to identify significant breakouts.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'Filters',
  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      scanType: 'bollingerSqueeze',
      settingsData: {
        minScanningPeriod: 200,
        maxScanningPeriod: 250,
        dayFromToday: 5,
        minSqueezeLength: 200,
        maxSqueezeLength: 250,
        ratioOfSpikeHeight: 10,
      },
      isLoading: false,
      isShow: false,
    }
  },
  components: { DescriptionInput, Wrapper },
  computed: {
    ...mapGetters([
      'gurusScanSettings',
      'filterSettingsForScan',
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'favSelectedScanId',
      'userChoice',
      'themeDarkMode',
    ]),
    /** @returns {Boolean} */
    isValid() {
      return (
        this.settings.minScanningPeriod > 0 &&
        this.settings.maxScanningPeriod > 0 &&
        this.settings.minScanningPeriod > 0 &&
        this.settings.maxScanningPeriod > 0 &&
        this.settings.dayFromToday > 0 &&
        this.settings.ratioOfSpikeHeight > 0 &&
        this.settings.minScanningPeriod < this.settings.maxScanningPeriod &&
        this.settings.minSqueezeLength < this.settings.maxSqueezeLength
      )
    },
    settings: {
      get() {
        return this.gurusScanSettings.bollingerSqueeze || this.settingsData
      },
      set(value) {
        this.setBollingerSqueezeSettings(value)
      },
    },
  },
  methods: {
    ...mapActions([
      'sendBollingerSqueezePatternRequest',
      'setBollingerSqueezeSettings',
      'cancelGuruScans',
      'getFilterSettings',

      'setScanFilterSettings',
      'setScanFilterSettingsOld',
      'resetGurusSettings',
      'getUserScanList',
      'setScanFilterSettings',
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendBollingerSqueezePatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    setSetting(key1, key2, value) {
      let settings = this.bollingerSqueezeSettingData
      if (key1 == 'matchCombination') {
        settings[key1] = value
      } else {
        settings[key1][key2] = value
      }
      this.setEventsFilterSettings({
        l: 'settings_single',
        id: this.id,
        settings,
      })
    },
    handleCheck(key) {
      let val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
    },
    getSetting(key1, key2) {
      let settings = this.filterSettingData
      console.log('patterns=======>', key1, key2)
      console.log('settings===>', key1, key2, settings)
      if (settings && settings[key1] && settings[key1][key2]) {
        console.log('if setting===>', settings, settings[key1][key2])
        return settings[key1][key2]
      }
    },
    getActive(key1, key2) {
      let settings = this.getFilterSettings(this.id)
      if (key1 == 'matchCombination') {
        return settings[key1]
      } else {
        return settings[key1][key2]
      }
    },
  },
}
</script>

<style></style>

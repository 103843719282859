<template>
  <div
    :class="
      width <= 430 && height <= 810
        ? '!h-[93vh] -border-4 -border-red-500'
        : '!h-[100vh]'
    "
    class="flex flex-col justify-between xxs:!w-[100vw] -xxs:!h-[93vh] qm:!h-[83vh] qm:!w-[80vw] md:!w-[55vw] !overflow-hidden -bg-black"
  >
    <div class="w-full h-[90%] -bg-red-400">
      <!-- header -->
      <div
        class="w-full h-[4rem] flex flex-row items-center justify-between -bg-gray-400 py-0 mt-0 px-3 border -bg-slate-300"
      >
        <div class="w-auto flex jus h-auto text-[20px] font-[600]">
          <!-- <svg
          width="26"
          class="text-[#4C4C4C] dark:text-[#989898]"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.8335 7.04102H17.3335"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.49984 7.04102H2.1665"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.8332 10.8333C12.9273 10.8333 14.6248 9.13575 14.6248 7.04167C14.6248 4.94758 12.9273 3.25 10.8332 3.25C8.73909 3.25 7.0415 4.94758 7.0415 7.04167C7.0415 9.13575 8.73909 10.8333 10.8332 10.8333Z"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.6665 18.959H2.1665"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.8333 18.957H19.5"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.1667 22.7493C17.2607 22.7493 18.9583 21.0518 18.9583 18.9577C18.9583 16.8636 17.2607 15.166 15.1667 15.166C13.0726 15.166 11.375 16.8636 11.375 18.9577C11.375 21.0518 13.0726 22.7493 15.1667 22.7493Z"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> -->
          <span
            @click="handleBack"
            :class="width <= 600 && isScanType == false ? '' : 'hidden'"
            class="cursor-pointer mr-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Settings
        </div>

        <div
          @click="closeModal"
          class="p-2.5 -bg-[#ececec] hover:bg-[#F0F3F5] xl:ml-[-4rem] -!bg-red-500 hover:opacity-70 dark:bg-[#2d2d2d] cursor-pointer rounded-[3px] flex justify-center items-center"
        >
          <!-- <img :src="require('../../src/assets/cross.svg')" class="w-[20px] h-[20px]" alt="close"> -->
          <svg
            width="20"
            height="20"
            :class="getTheme ? 'text-white' : 'text-black'"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <!-- New Settings Modal showing when width is greater than 600px -->
      <div
        :class="width <= 600 ? 'hidden' : ''"
        class="flex w-full !h-[96%] -xxs:!h-[81%] overflow-hidden -!bg-slate-400"
      >
        <!-- Right Bar Menu -->
        <div
          class="xxs:w-[100%] qm:w-[25%] border-r -bg-black -h-full -bg-black overflow-y-auto no-scrollbar"
        >
          <div
            class="w-full h-[3rem] -bg-slate-400 flex gap-3 !items-center !-justify-center xl:text-[14px] font-medium px-4"
          >
            <span>Scan Types</span>
          </div>
          <div
            v-for="(item, index) in settingsBtn"
            :key="index"
            class="w-full xxs:h-[4.5rem] sm:h-[3rem] -bg-slate-400 flex gap-3 !items-center !-justify-center xl:text-[14px] relative font-medium cursor-pointer px-4"
            @click="selectScanType(item.heading)"
            :class="
              selectedBox == item.heading
                ? 'text-appBtnActiveTxt font-medium bg-appBtnActive'
                : 'text-appBtnText dark:text-[#f6f6f6] hover:bg-appBtnHover'
            "
          >
            <span
              :class="selectedBox == item.heading ? 'text-appBtnActiveTxt' : ''"
              v-html="getSvg(item.iconName)"
            ></span>
            <span>
              {{ item.heading }}
            </span>
            <!-- <div
            v-if="selectedBox === heading"
            class="absolute left-[-0.5rem] xxs:w-[4rem] self-end 2xl:w-[10rem] xl:w-[70%] h-[1.2px] !mb-[-.7rem] bg-appBlue"
          ></div> -->
          </div>
        </div>
        <!-- components -->
        <div
          class="xxs:w-full qm:w-[75%] -bg-black -h-full py-4 xxs:px-4 2lg:px-[3rem] -bg-black !flex-wrap !flex-col overflow-y-auto no-scrollbar"
        >
          <IndicatorSetting
            v-if="selectedBox == 'Indicator Settings'"
            @update-indicator-settings="updateIndicatorSettings"
            :indicatorSettingsProp="indicatorSettings"
            @update-indicator-settings-with-name="
              updateIndicatorSettingsWithName
            "
            :indicatorsSettingWithNameProps="indicatorsSettingWithName"
          />
          <StyleTheme
            v-if="selectedBox == 'Styles & Theme'"
            :chartSettingProp="chartSettingData"
            :themeSettingProp="themeSetting"
            @update-theme-settings="updateThemeSettings"
            @update-chart-settings="updateChartSettings"
          />
          <AccountSetting
            v-if="selectedBox == 'Account'"
            :accountSettingProp="accountSettingData"
            @update-account-settings="updateAccountSettings"
            :subscriptionSettingProp="subscriptionSetting"
            @update-subscription-settings="updateSubscriptionSettings"
          />
        </div>
      </div>

      <!-- New Settings Modal showing when width is less than 600px -->
      <div
        :class="[
          width <= 600 ? '' : 'hidden',
          width <= 430 && height <= 810 ? 'xxs:h-[93%] -bg-green-200' : 'xxs:h-[100%] -bg-red-200',
        ]"
        class="flex w-full -border overflow-hidden"
      >
        <!-- Right Bar Menu -->
        <div
          v-if="isScanType"
          class="xxs:w-[100%] qm:w-[25%] border-r -bg-black h-full -bg-slate-300 overflow-y-auto no-scrollbar"
        >
          <div
            class="w-full h-[3rem] -bg-slate-400 flex gap-3 !items-center !-justify-center xl:text-[14px] font-medium px-4"
          >
            <span>Scan Types</span>
          </div>
          <div
            v-for="(item, index) in settingsBtn"
            :key="index"
            class="w-full xxs:h-[4.5rem] sm:h-[3rem] -bg-slate-400 flex gap-3 !items-center !-justify-center xl:text-[14px] relative font-medium cursor-pointer px-4"
            @click="selectScanType(item.heading)"
            :class="
              selectedBox == item.heading
                ? 'text-appBtnActiveTxt font-medium bg-appBtnActive'
                : 'text-appBtnText dark:text-[#f6f6f6] hover:bg-appBtnHover'
            "
          >
            <span
              :class="selectedBox == item.heading ? 'text-appBtnActiveTxt' : ''"
              v-html="getSvg(item.iconName)"
            ></span>
            <span>
              {{ item.heading }}
            </span>
            <!-- <div
            v-if="selectedBox === heading"
            class="absolute left-[-0.5rem] xxs:w-[4rem] self-end 2xl:w-[10rem] xl:w-[70%] h-[1.2px] !mb-[-.7rem] bg-appBlue"
          ></div> -->
          </div>
        </div>
        <!-- components -->
        <div
          v-if="!isScanType"
          class="xxs:w-[100%] qm:w-[25%] border-r -mt-4 -bg-black h-[100%] -bg-slate-300 overflow-y-auto no-scrollbar py-4 px-4"
        >
          <IndicatorSetting
            v-if="selectedBox == 'Indicator Settings'"
            @update-indicator-settings="updateIndicatorSettings"
            :indicatorSettingsProp="indicatorSettings"
            @update-indicator-settings-with-name="
              updateIndicatorSettingsWithName
            "
            :indicatorsSettingWithNameProps="indicatorsSettingWithName"
          />
          <StyleTheme
            v-if="selectedBox == 'Styles & Theme'"
            :chartSettingProp="chartSettingData"
            :themeSettingProp="themeSetting"
            @update-theme-settings="updateThemeSettings"
            @update-chart-settings="updateChartSettings"
          />
          <AccountSetting
            v-if="selectedBox == 'Account'"
            :accountSettingProp="accountSettingData"
            @update-account-settings="updateAccountSettings"
            :subscriptionSettingProp="subscriptionSetting"
            @update-subscription-settings="updateSubscriptionSettings"
          />
        </div>
      </div>
    </div>

    <!--Footer component  -->
    <div
    :class="!isScanType ? '-mt-4' : ''"
      class="flex -xxs:flex-col h-[4rem] md:flex-row justify-between items-center dark:border-[#181818] border-t xxs:px-3 md:px-6 -bg-red-300"
    >
      <div
        class="xxs:w-auto xxs:h-[4rem] -border md:w-auto md:h-auto flex xxs:flex-col qm:flex-row gap-1 xxs:!items-start md:justify-items-center"
      >
        <div class="text-[#4F4F4F] dark:text-[#ffffff]">Last updated on</div>
        <div class="text-[#888888]">05:15 pm EST</div>
      </div>
      <div class="flex flex-start items-center xxs:gap-3 md:gap-4 -border">
        <!-- <div
          @click="resetToDefault"
          class="hover:bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] border cursor-pointer font-bold border-[#D1D1D1] text-[#292929] dark:text-[#F6F6F6] px-[20px] py-[6px] rounded-[10px]"
        >
          Reset
        </div> -->
        <div
          @click="closeModal"
          class="cursor-pointer flex border hover:bg-[#F6F6F6] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] font-bold border-[#D1D1D1] dark:!border-[#2D2D2D] text-[#292929] dark:text-[#F6F6F6] shrink-0 w-auto px-[20px] py-[6px] rounded-[10px]"
        >
          <span> Cancel Changes </span>
        </div>
        <div
          @click="handleSave"
          class="cursor-pointer text-[#fff] bg-appBlue hover:bg-blueHover !border-none border dark:border-[#2D2D2D] border-[#D1D1D1] px-[20px] py-[6px] rounded-[10px]"
        >
          Save
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleTheme from './StyleTheme.vue'
import IndicatorSetting from './IndicatorSetting.vue'
import AccountSetting from './AccountSetting.vue'
import { getSvgByName } from '../assets/NewSettingsSvg/NewSettingsSvg'

import { mapGetters, mapActions } from 'vuex'
import {
  copyChartSettingData,
  copyIndicatorSettings,
  copyIndicatorSettingName,
  copyThemeSetting,
  copyAccountInfo,
} from '../utils/chartSetting'

export default {
  components: {
    StyleTheme,
    IndicatorSetting,
    AccountSetting,
  },
  data() {
    return {
      selectedBox: 'Indicator Settings',
      isScanType: true,
      width: window.innerWidth,
      height: window.innerHeight,
      settingsBtn: [
        { heading: 'Indicator Settings', iconName: 'settings' },
        { heading: 'Styles & Theme', iconName: 'styles' },
        { heading: 'Account', iconName: 'account' },
      ],
      indicatorSettings: [
        {
          headingName: 'Simple Moving Average (SMA)',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 30 },
              ],
            },
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#110404' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Simple Moving Average of OBV',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#008724' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Simple Moving Average of OBV of Volume',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Weighted Moving Average',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Exponential Moving Average',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#1E31DB' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
      ],
      indicatorsSettingWithName: [
        {
          headingName: 'Trend Channels',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 70 },
              ],
            },
          ],
        },
        {
          headingName: 'Bollinger Bands',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 21 },
              ],
            },
            {
              name: 'Deviations',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 40 },
              ],
            },
          ],
        },
        {
          headingName: 'Average True Range',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Multiplier',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'MACD',
          settings: [
            {
              name: 'Fast Period',
              inputs: [
                { type: 'color', defaultValue: '#1E31DB' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Slow Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Signal Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Rate Of Change',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Relative Strength Index',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Stochastic Oscillator',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 15 },
              ],
            },
            {
              name: '%K Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 5 },
              ],
            },
            {
              name: '%D Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 5 },
              ],
            },
          ],
        },
        {
          headingName: 'Williams %R',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 15 },
              ],
            },
          ],
        },
        {
          headingName: 'Parabolic SAR',
          settings: [
            { name: 'Start', inputs: [{ type: 'number', defaultValue: 15 }] },
            {
              name: 'Increament',
              inputs: [{ type: 'number', defaultValue: 5 }],
            },
            { name: 'Max', inputs: [{ type: 'number', defaultValue: 5 }] },
            {
              name: 'Color',
              inputs: [{ type: 'color', defaultValue: '#FC0C0C' }],
            },
          ],
        },
        {
          headingName: 'VWAP',
          settings: [
            {
              name: 'Color',
              inputs: [{ type: 'color', defaultValue: '#FC0C0C' }],
            },
          ],
        },
      ],
      themeSetting: [
        {
          heading: 'Theme',
          key: 'theme',
          settings: [
            {
              isGroup: [
                // {
                //   name: 'Legacy Charts',
                //   type: 'checkbox',
                //   defaultValue: false,
                //   key: 'legacyChart',
                //   variant: 'fifthVariant',
                //   variantList: {
                //     firstVariant: false,
                //     secondVariant: false,
                //     thirdVariant: false,
                //     fourthVariant: false,
                //     fifthVariant: true,
                //   },
                // },
                {
                  name: 'Yahoo Charts',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'yahooChart',
                  variant: 'firstVariant',
                  variantList: {
                    firstVariant: true,
                    secondVariant: false,
                    thirdVariant: false,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Standard Green/Red',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'standardGR',
                  variant: 'secondVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: true,
                    thirdVariant: false,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Standard Blue/Red',
                  type: 'checkbox',
                  defaultValue: true,
                  key: 'standardBR',
                  variant: 'fourthVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: false,
                    thirdVariant: false,
                    fourthVariant: true,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Greyscale',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'grayScale',
                  variant: 'thirdVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: false,
                    thirdVariant: true,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
              ],
            },
          ],
        },
        // {
        //   heading: 'Dark/Light Mode',
        //   key: 'darkLightMode',
        //   settings: [
        //     {
        //       isGroup: [
        //         {
        //           name: 'System Preference',
        //           type: 'checkbox',
        //           defaultValue: false,
        //           key: 'system',
        //         },
        //         {
        //           name: 'Dark Mode',
        //           type: 'checkbox',
        //           defaultValue: false,
        //           key: 'dark',
        //         },
        //         {
        //           name: 'Light Mode',
        //           type: 'checkbox',
        //           defaultValue: true,
        //           key: 'light',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      subscriptionSetting: [
        {
          heading: 'Subscription',
          key: 'subscription',
          settings: [
            {
              isGroup: [
                {
                  name: 'Monthly Plan',
                  type: 'checkbox',
                  defaultValue: true,
                  key: 'monthly',
                },
                {
                  name: 'Yearly Plan',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'yearly',
                },
              ],
            },
          ],
        },
      ],
      accountSettingData: [
        {
          heading: 'Account Information',
          key: 'accountInformation',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              name: 'First Name',
              type: 'text',
              defaultValue: '',
              key: 'firstName',
              icon: '',
            },
            {
              name: 'Last Name',
              type: 'text',
              defaultValue: '',
              key: 'lastName',
              icon: '',
            },
            {
              name: 'Email',
              type: 'text',
              defaultValue: '',
              key: 'email',
              icon: 'email',
            },
            {
              name: 'Old',
              type: 'text',
              defaultValue: '',
              key: 'old',
              icon: '',
            },
            {
              name: 'New Password',
              type: 'password',
              defaultValue: '',
              key: 'newPassword',
              icon: '',
            },
            {
              name: 'Retype New Password',
              type: 'password',
              defaultValue: '',
              key: 'retypeNewPassword',
              icon: '',
            },
          ],
        },
      ],
      chartSettingData: [
        {
          heading: 'Line Chart',
          key: 'lineChart',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              name: 'Line Color',
              type: 'color',
              defaultValue: '#3853B4',
              key: 'lineColor',
            },
          ],
        },
        {
          heading: 'Up/Down Bar Chart Color',
          showToggle: true,
          key: 'upDownBarChart',
          isToggle: true,
          settings: [
            {
              name: 'Bar Chart Color',
              type: 'color',
              key: 'barChartColor',
              defaultValue: '#3853B4',
            },
            {
              isGroup: [
                {
                  name: 'Up Color',
                  type: 'color',
                  defaultValue: '#0D66CE',
                  key: 'barUpColor',
                },
                {
                  name: 'Down Color',
                  type: 'color',
                  defaultValue: '#F23645',
                  key: 'barDownColor',
                },
              ],
            },
          ],
        },
        {
          heading: 'Up/Down Volume Color',
          showToggle: true,
          isToggle: true,
          key: 'upDownVolume',
          settings: [
            {
              name: 'Volume Color',
              type: 'color',
              key: 'volumeColor',
              defaultValue: '#3853B4',
            },
            {
              isGroup: [
                {
                  name: 'Up Volume Color',
                  type: 'color',
                  key: 'upVolumeColor',
                  defaultValue: '#0D66CE',
                },
                {
                  name: 'Down Volume Color',
                  type: 'color',
                  key: 'downVolumeColor',
                  defaultValue: '#F23645',
                },
              ],
            },
            {
              name: 'On-Balance Volume Color',
              type: 'color',
              key: 'onBalanceVolume',
              defaultValue: '#3853B4',
            },
          ],
        },
        {
          heading: 'Cross Hair',
          key: 'crossHair',
          showToggle: true,
          isToggle: true,
          settings: [
            {
              name: 'Line Color',
              type: 'color',
              key: 'crossLineColor',
              defaultValue: '#6C757D',
            },
            {
              name: 'Magnet',
              type: 'checkbox',
              key: 'magnet',
              defaultValue: true,
            },
          ],
        },
        {
          heading: 'Hover Y-Axis',
          key: 'yAxis',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              isGroup: [
                {
                  name: 'Box Color',
                  type: 'color',
                  key: 'boxColor',
                  defaultValue: '#0D66CE',
                },
                {
                  name: 'Text Color',
                  type: 'color',
                  key: 'boxTextColor',
                  defaultValue: '#ffffff',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['getTheme', 'getAppSetting', 'getUserEmail']),
  },
  methods: {
    ...mapActions(['updateAppSetting', 'updateTheme']),

    getSvg(name) {
      return getSvgByName(name)
    },

    selectScanType(type) {
      this.selectedBox = type
      this.isScanType = false
    },
    handleBack() {
      this.isScanType = true
    },
    updateScreenWidth() {
      this.Width = window.innerWidth
      this.height = window.innerHeight
    },

    resetToDefault() {
      this.indicatorSettings = copyIndicatorSettings
      this.indicatorsSettingWithName = copyIndicatorSettingName
      this.chartSettingData = copyChartSettingData
      this.themeSetting = copyThemeSetting
      this.accountSettingData = copyAccountInfo
    },
    updateIndicatorSettingsWithName(data) {
      this.indicatorsSettingWithName = data
    },
    updateThemeSettings(data) {
      this.themeSetting = data
    },
    updateSubscriptionSettings(data) {
      this.subscriptionSetting = data
    },
    updateAccountSettings(data) {
      this.accountSettingData = data
    },
    updateChartSettings(data) {
      this.chartSettingData = data
    },
    updateIndicatorSettings(data) {
      this.indicatorSettings = data
    },
    closeModal() {
      this.$emit('closeModal')
    },

    DarkLightMode() {
      let data = this.themeSetting?.find((val) => val?.key === 'darkLightMode')
      if (data) {
        let setting = data?.settings[0]?.isGroup?.find(
          (item) => item?.defaultValue == true
        )
        if (setting?.key == 'dark') {
          this.updateTheme(true)
        } else if (setting?.key == 'light') {
          this.updateTheme(false)
        } else {
          const isLightMode = window.matchMedia(
            '(prefers-color-scheme: light)'
          ).matches
          if (isLightMode) {
            this.updateTheme(false)
          } else {
            this.updateTheme(true)
          }
        }
      }
    },
    handleSave() {
      let obj = {
        themeSetting: this.themeSetting,
        chartSetting: this.chartSettingData,
        indicatorSetting: this.indicatorSettings,
        indicatorsSettingWithName: this.indicatorsSettingWithName,
        accountSetting: this.accountSettingData,
        subscriptionSetting: this.subscriptionSetting,
      }
      this.updateAppSetting(obj)
      this.DarkLightMode()
      this.closeModal()
    },
    updateThemeValue() {
      let systemPref = this.themeSetting[1].settings[0].isGroup?.find(
        (item) => item.key == 'system'
      )
      if (!systemPref?.defaultValue) {
        this.themeSetting[1].settings[0].isGroup.forEach((setting) => {
          if (this.getTheme) {
            if (setting.key === 'dark') {
              setting.defaultValue = true
            } else {
              setting.defaultValue = false
            }
          } else {
            if (setting.key === 'light') {
              setting.defaultValue = true
            } else {
              setting.defaultValue = false
            }
          }
        })
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth)

    if (this.getAppSetting) {
      this.themeSetting = this.getAppSetting.themeSetting
      this.chartSettingData = this.getAppSetting.chartSetting
      this.indicatorSettings = this.getAppSetting.indicatorSetting
      this.indicatorsSettingWithName =
        this.getAppSetting.indicatorsSettingWithName
      this.accountSettingData = this.getAppSetting.accountSetting
      this.subscriptionSetting = this.getAppSetting.subscriptionSetting
    }

    if (this.getUserEmail) {
      let updatedArr = this.accountSettingData?.map((item) => {
        return {
          ...item,
          settings: item?.settings?.map((setting) =>
            setting.key == 'email'
              ? { ...setting, defaultValue: this.getUserEmail }
              : setting
          ),
        }
      })
      console.log("updatedArr",updatedArr)
      this.accountSettingData = updatedArr
    }

    this.updateThemeValue()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped></style>

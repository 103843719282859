<template>
  <div class="p-8">
    <DivergengeScanCom v-if="selectedButtonTypeName == 'Divergense Scan'" />
  </div>
</template>
  
  <script>
import Filters from './Filters.vue'
import DivergengeScanCom from './divergengeScanCom.vue'

export default {
  name: 'DivergenceScan',
  data() {
    return {
      id: 'divergenceScan',
    }
  },
  components: { Filters, DivergengeScanCom },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  watch: {},
  mounted() {},
}
</script>
  
  <style>
</style>
<template>
  <div class="w-full h-full">
    <div
      class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
    >
      <span>Nav buttons</span>
    </div>
    <ul
      class="!w-full h-full bg-white -overflow-y-scroll dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] overflow-y-scroll scroll-container"
    >
      <div class="w-full h-full flex flex-col -space-y-1">
        <li
          @click="newSettings(chartType.name)"
          class="flex w-full h-[5rem] items-center space-x-2 gap-2 px-8 py-4 -border-b text-appBtnTxt dark:!text-[#6C707A] -rounded-md"
          v-for="(chartType, index) in navBtns"
          :key="index"
          :class="[
            isActive == chartType?.name
              ? 'bg-appBtnActive dark:!bg-transparent !text-appBtnActiveTxt dark:!text-[#F6F6F6] !border-appBtnActive'
              : '-hover:border-[#E4E4E4]  dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover  -!border-[transparent]',
            getDisable(chartType.key)
              ? 'pointer-events-none opacity-50 cursor-default'
              : 'cursor-pointer',
          ]"
        >
          <div
            :class="
              chartType?.name === isActive
                ? '!text-appBtnActiveTxt'
                : 'text-[#323232]'
            "
          >
            <span v-html="getSvg(chartType.icon, isActive)"></span>
          </div>
          <div class="!text-[16px]">{{ chartType.btn }}</div>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
import { getSvgByName } from '../../assets/rightsidebarsvgs/utils/SvgUtils'
import { mapGetters, mapActions } from 'vuex'
import { chartEvents } from '../../chartEvents';

export default {
  name: 'ChartTypeVue',
  data() {
    return {
      isActive: 'watchlist',
      navBtns: [
        {
          id: 1,
          icon: 'watchlist',
          name: 'watchlist',
          btn: 'Favourite',
        },
        {
          id: 2,
          icon: 'scanResult',
          name: 'scannerResults',
          btn: 'Scan Result',
        },
        {
          id: 3,
          icon: 'trainingMode',
          name: 'trainingMode',
          btn: 'Training Mode',
        },
        {
          id: 4,
          icon: 'comparingSymbols',
          name: 'comparingSymbols',
          btn: 'Comparing Symbols',
        },
        {
          id: 5,
          icon: 'indicator',
          name: 'indicator',
          btn: 'Indicators',
        },
        {
          id: 6,
          icon: 'scanner',
          name: 'scanner',
          btn: 'Scanners',
        },
        {
          id: 7,
          icon: 'settings',
          name: 'settings',
          btn: 'Settings',
        },
        {
          id: 8,
          icon: 'logout',
          name: 'logout',
          btn: 'Logout',
        },
      ],
    }
  },
  components: {},

  methods: {
    ...mapActions([
      'setNewChartType',
      'toggleTheme',
      'handleNextSymbol',
      'handlePreviousSymbol',
      'toggleScannerModal',
      'setNewChartTimeFrame',
      'updateTrainingModeState',
    ]),
    getSvg(name, chartType) {
      return getSvgByName(name, chartType)
    },

    getDisable(key) {
      if (key == 'CompareChartMain') {
        return true
      } else {
        return false
      }
    },

    newSettings(item) {
        this.isActive = item
        chartEvents.$emit('newSettings', item)

      //   if (item?.key == 'CompareChartMain') {
      //     this.compareDialogOpen = true
      //   } else {
      //     this.selectedChartType = item
      //     this.setNewChartType({ type: item?.key, location: 'FromChartTypeVue' })
      //   }
      //   this.$emit('handleSelectChartType')
    },
  },
  computed: {},
  mounted() {
    let type = localStorage.getItem('SET_CHART_TYPE')
    if (type) {
      let obj = this.chartTypes?.find((item) => item.key == type)
      if (obj) {
        this.selectedChartType = obj
      }
    }
  },
  watch: {
    getChartType(type) {
      if (type) {
        let obj = this.chartTypes?.find((item) => item.key == type)
        if (obj) {
          this.selectedChartType = obj
        }
      }
    },
  },
}
</script>
<style scoped></style>

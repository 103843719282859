<template>
  <div
    class="space-y-2 w-full h-full"
  >
    <!-- First Filters -->
    <div
      :class="getActive('break','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black  focus:outline-none cursor-pointer focus:ring-0 -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between  -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Price Break Analysis
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings_break_period"
          />
          <span>period</span>
          <select
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="select"
            v-model="settings_break_direction"
          >
            <option
              v-for="direction of breakDirectionOptions"
              :value="direction.value"
              :key="direction.value"
            >
              {{ direction.text }}
            </option>
          </select>
          <select
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="select"
            v-model="settings_break_type"
          >
            <option
              v-for="type of breakingTypeOptions"
              :value="type.value"
              :key="type.value"
            >
              {{ type.text }}
            </option>
          </select>
        </div> 
        
        <div>
          <DescriptionInput :description="`Stocks that experienced a ${getSetting('break','direction')} at the ${getSetting('break','type')} price within the last ${getSetting('break','period')} days.`" />
        </div>
      </div>
      <!-- check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('break')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('break','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- pre-breakout filter -->
    <div
    :class="getActive('prebreak','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Price Range Proximity
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Within</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="0"
            step="1"
            max="100"
            tabindex="1"
            v-model="settings_prebreak_pct"
          />
          <span>% of last</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="1"
            v-model="settings_prebreak_period"
          />
          <span>period(s)</span>
          <select
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 16%; height: 45px; font-size: 16px"
            type="select"
            v-model="settings_prebreak_type"
          >
            <option
              v-for="type in peakTypeOptions"
              :value="type.value"
              :key="type.value"
            >
              {{ type.text }}
            </option>
          </select>
        </div>

        
        <div>
          <DescriptionInput :description="`Stocks that are within ${getSetting('prebreak','pct')}% of the ${getSetting('prebreak','type')} price over the last ${getSetting('prebreak','period')} days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('prebreak')">
          <svg
             width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('prebreak','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 2nd filter -->

    <div
    :class="getActive('volumeIncrease','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">Volume Surge</h3>
        <div class="w-[80%] gap-3 flex items-center">
          <span>Increase volume for</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="1"
            v-model="settings_volumeIncrease_period"
          />
          <span>day(s) of data</span>
        </div>

        <div>
          <DescriptionInput :description="`Stocks with an increase in trading volume over the last ${getSetting('volumeIncrease','period')} days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('volumeIncrease')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
                :fill="getActive('volumeIncrease','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- <base-check-box
        label="Increase volume for"
        v-model="volumeIncreaseComputed"
        @click="handleUserChoice"
      /> -->

    <!-- 3rd filter -->

    <div
     :class="getActive('tciBreak','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <h3 class="text-[16px] font-[700] leading-[15px]">
          TCI Break Direction
        </h3>
        <div class="w-[80%] gap-6 flex items-center">
          <!-- <base-check-box label="TCI Break " /> -->
          <div class="w-auto h-full">
            <span>TCI Break</span>
          </div>

          <div class="w-auto  flex gap-2 items-center h-full">
            <input
              type="radio"
              style="width: 20px; height: 20px"
              :id="'tciBreakDirection~up' + dialogId"
              name="tciBreakDirection"
              value="up"
              v-model="settings_tciBreak_direction"
            />
            <label
              :for="'tciBreakDirection~up' + dialogId"
              :style="{ ...themeFontColor, cursor: 'pointer' }"
              >Up</label
            >
          </div>

          <div class="w-auto  flex gap-2 items-center h-full">
            <input
              type="radio"
              :id="'tciBreakDirection~down' + dialogId"
              style="width: 20px; height: 20px"
              name="tciBreakDirection"
              value="down"
              :style="{ cursor: 'pointer' }"
              v-model="settings_tciBreak_direction"
            />
            <label
              :for="'tciBreakDirection~down' + dialogId"
              :style="{ ...themeFontColor, cursor: 'pointer' }"
              >Down</label
            >
          </div>

          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              :id="'tciBreakDirection~all' + dialogId"
              style="width: 20px; height: 20px"
              name="tciBreakDirection"
              value="all"
              :style="{ cursor: 'pointer' }"
              v-model="settings_tciBreak_direction"
            />

            <label
              :for="'tciBreakDirection~all' + dialogId"
              :style="{ cursor: 'pointer' }"
              >All</label
            >
          </div>
        </div>

        <div>
          <DescriptionInput :description="`Stocks in an ${getSetting('tciBreak','direction')} TCI trend for the past 20 days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('tciBreak')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('tciBreak','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 4th filter -->

    <div
     :class="getActive('tciTrend','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <span class="text-[16px] font-[700] leading-[15px]">
          TCI Trend Analysis
        </span>
        <div class="w-[80%] gap-3 pb-1 mt-3 flex items-center">
          <div class="w-auto flex gap-6 items-center h-full">
            <div v-for="(v, i) of tciBreakProps" class="flex gap-2">
              <input
                type="radio"
                :id="'tciTrendDirection~' + dialogId + i"
                style="width: 20px; height: 20px"
                :key="'tciTrendDirection~' + dialogId + i"
                name="tciTrendDirection"
                :value="v.value"
                :style="{ cursor: 'pointer' }"
                v-model="settings_tciTrend_direction"
              />
              <label
                :for="'tciTrendDirection~' + dialogId + i"
                :style="{ cursor: 'pointer' }"
                >{{ v.label }}</label
              >
            </div>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_tciTrend_period"
            />
            <span>day(s)</span>
          </div>

          <!-- <div class="w-auto py-3 flex gap-2 items-center h-full">
            <input
              type="radio"
              :id="'tciBreakDirection~down' + dialogId"
              style="width: 20px; height: 20px"
              name="tciBreakDirection"
              value="down"
              :style="{ cursor: 'pointer' }"
              v-model="settings_tciBreak_direction"
            />
            <label
              :for="'tciBreakDirection~down' + dialogId"
              :style="{ ...themeFontColor, cursor: 'pointer' }"
              >Down</label
            >
          </div>

          <div class="w-auto py-3 flex gap-2 items-center h-full">
            <input
              type="radio"
              :id="'tciBreakDirection~all' + dialogId"
              style="width: 20px; height: 20px"
              name="tciBreakDirection"
              value="all"
              :style="{ cursor: 'pointer' }"
              v-model="settings_tciBreak_direction"
            />

            <label
              :for="'tciBreakDirection~all' + dialogId"
              :style="{ cursor: 'pointer' }"
              >All</label
            >
          </div> -->
        </div>

        <div>
          <DescriptionInput :description="`Stocks in an ${getSetting('tciTrend','direction')} TCI trend for the past ${getSetting('tciTrend','period')} days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('tciTrend')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('tciTrend','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 5th filter -->

    <div
    :class="getActive('macd','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <span class="text-[16px] font-[700] leading-[15px]">
          MACD Crossover Distance
        </span>
        <div class="w-[80%] gap-3 mt-3 flex items-center">
          <div class="w-auto flex gap-6 items-center h-full">
            <span>MACD</span>
            <!-- <base-check-box label="MACD " /> -->
          
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_macd_period"
            />
            <span>day(s) from a crossover.</span>
          </div>
        </div>

       
        <div>
          <DescriptionInput :description="`Stocks where the MACD is ${getSetting('macd','period')} days away from a crossover.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('macd')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('macd','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 6th filter -->

    <div
     :class="getActive('obv','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between  -items-center w-full h-full"
      >
        <span class="text-[16px] font-[700] leading-[15px]">
          OBV Level Comparison
        </span>
        <div class="w-[80%] gap-3 mt-3 flex items-center">
          <!-- <base-check-box label="OBV " /> -->
          
          <div class="w-auto flex gap-3 items-center h-full">
           <span>OBV</span>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_obv_pctAbove"
            />
            <span>% above the level of</span>
          </div>
          <div class="w-auto flex gap-3 items-center h-full">
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_obv_period"
            />
            <span>day(s) ago</span>
          </div>
        </div>

       <div>
          <DescriptionInput :description="`Stocks with OBV ${getSetting('obv','pctAbove')}% above the level from ${getSetting('obv','period')} days ago.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('obv')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('obv','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 7th filter -->

    <div
    :class="getActive('volumeMore','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <span class="text-[16px] font-[700] leading-[15px]">
          Volume Surge Above Previous High
        </span>
        <div class="w-[80%] mt-3 gap-3 flex items-center">
          <!-- <base-check-box label="Volume more than" /> -->
   
          <div class="w-auto flex gap-3 items-center h-full">
            <span>Volume more than</span>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_volumeMore_pct"
            />
            <!-- <input
              class="px-3 rounded-[2.5px] hidden border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_volumeMore_pct"
            /> -->
            <span>% above highest volume of the previous</span>
          </div>
          <div class="w-auto flex gap-3 items-center h-full">
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_volumeMore_period"
            />
            <span>day(s)</span>
          </div>
        </div>

        <div>
          <DescriptionInput :description="`Stocks with volume more than ${getSetting('volumeMore','pct')}% above the highest volume of the previous ${getSetting('volumeMore','period')} days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('volumeMore')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('volumeMore','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 8th filter -->

    <div
     :class="getActive('trendLineCrossing','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <!-- <base-check-box
        label="Trend line crossing in the last "
        v-model="trendLineCrossingComputed"
        @click="handleUserChoice"
      /> -->
        <span class="text-[16px] font-[700] leading-[15px]">
          Trend Line Crossover
        </span>
        <div class="w-[80%] gap-3 flex items-center">
          <!-- <base-check-box label="Volume more than" /> -->

          <div class="w-auto flex gap-3 items-center h-full">
            <span>Trend line crossing in the last</span>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_trendLineCrossing_period"
            />
            <span>day(s)</span>
          </div>
        </div>

        <div>
          <DescriptionInput :description="`Stocks with a trend line crossing in the last ${getSetting('trendLineCrossing','period')} days.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('trendLineCrossing')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('trendLineCrossing','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 9th filter -->

    <div
    :class="getActive('gapUp','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between  -items-center w-full h-full"
      >
        <!-- <base-check-box
        label="Find any stock that left a gap up by at least "
        v-model="gapUpComputed"
        @click="handleUserChoice"
      /> -->
        <span class="text-[16px] font-[700] leading-[15px]">Gap Up Detection</span>
        <div class="w-[80%] flex items-center">
          <!-- <base-check-box label="Volume more than" /> -->

          <div class="w-auto flex gap-3 items-center h-full">
            <span>Find any stock that left a gap up by at least</span>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_gapUp_cents"
            />
            <span>cents</span>
          </div>
        </div>

        <div>
          <DescriptionInput :description="`Stocks that have a gap up of at least ${getSetting('gapUp','cents')} cents.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('gapUp')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('gapUp','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 10th filter -->

    <div
    :class="getActive('gapDown','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <!-- <base-check-box
        @click="handleUserChoice"
        label="Find any stock that left a gap down by at least "
        v-model="gapDownComputed"
      /> -->
        <span class="text-[16px] font-[700] leading-[15px]">
          Gap Down Detection
        </span>
        <div class="w-[80%] gap-3 flex items-center">
          <!-- <base-check-box label="Volume more than" /> -->

          <div class="w-auto  flex gap-3 items-center h-full">
            <span>Find any stock that left a gap down by at least</span>
            <input
              class="px-3 rounded-[2.5px] border-2 border-appBlue"
              style="width: 90px; height: 45px; font-size: 16px"
              type="number"
              tabindex="1"
              :style="themeDarkMode ? { 'background-color': '#505050' } : {}"
              v-model="settings_gapDown_cents"
            />
            <span>cents</span>
          </div>
        </div>

        <div>
          <DescriptionInput :description="`Stocks that have a gap down of at least ${getSetting('gapDown','cents')} cents.`" />
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('gapDown')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
             :fill="getActive('gapDown','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- 11th filter -->

    <div
    :class="getActive('matchCombination','isActive')?'bg-[#f7f7f7]':'hover:bg-[#EFEFEF80]'"
      class="-border -border-black hover:bg-[#EFEFEF80] -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
    >
      <div
        class="flex flex-col justify-between -items-center w-full h-full"
      >
        <!-- <base-check-box
        @click="handleUserChoice"
        label="Find any stock that left a gap down by at least "
        v-model="gapDownComputed"
      /> -->
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Event Combination Match
        </h3>
        <div class="w-[80%] gap-3 flex items-center">
          <div class="w-auto flex items-center h-full">
            <!-- <base-check-box label="Match selected events in combination " /> -->
            Match selected events in combination
          </div>
        </div>

        <div class="flex justify-start items-center !mt-2">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-7 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          </span>
          <span>Stocks that match the selected combination of events.</span>
        </div>
      </div>
      <!-- Check icon -->
      <div class="flex justify-center items-center w-auto h-full">
        <span class="cursor-pointer" @click="handleCheck('matchCombination')">
          <svg
            width="28"
            height="29"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('matchCombination','isActive')?'#1C274C':'#E2E2E2'"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DescriptionInput from '../../../component/DescriptionInput.vue'
export default {
  components: { DescriptionInput },
  name: 'Events',
  props: {
    dialogId: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      breakDirectionOptions: [
        {
          text: 'breakup',
          value: 'breakup',
        },
        {
          text: 'breakdown',
          value: 'breakdown',
        },
      ],
      breakingTypeOptions: [
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Close',
          value: 'close',
        },
      ],
      peakTypeOptions: [
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Low',
          value: 'low',
        },
      ],
      tciBreakProps: [
        { label: 'Up Trend', value: 'up' },
        { label: 'Down Trend', value: 'down' },
        { label: 'Horizontal', value: 'neutral' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'filterSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'eventUpdates',
      'getEventsSettings',
      'themeFontColor',
      'userChoice',
      'themeDarkMode',
    ]),
    eventSettingsData() {
      return this.getEventsSettings(this.id)
    },
    
    breakComputed: {
      get() {
        return this.getSettingCheck('break')
      },
      set(value) {
        this.setSettingCheck('break', value)
      },
    },
    prebreakComputed: {
      get() {
        return this.getSettingCheck('prebreak')
      },
      set(value) {
        this.setSettingCheck('prebreak', value)
      },
    },
    volumeIncreaseComputed: {
      get() {
        return this.getSettingCheck('volumeIncrease')
      },
      set(value) {
        this.setSettingCheck('volumeIncrease', value)
      },
    },
    tciBreakComputed: {
      get() {
        return this.getSettingCheck('tciBreak')
      },
      set(value) {
        this.setSettingCheck('tciBreak', value)
      },
    },
    tciTrendComputed: {
      get() {
        return this.getSettingCheck('tciTrend')
      },
      set(value) {
        this.setSettingCheck('tciTrend', value)
      },
    },
    macdComputed: {
      get() {
        return this.getSettingCheck('macd')
      },
      set(value) {
        this.setSettingCheck('macd', value)
      },
    },
    obvComputed: {
      get() {
        return this.getSettingCheck('obv')
      },
      set(value) {
        this.setSettingCheck('obv', value)
      },
    },
    volumeMoreComputed: {
      get() {
        return this.getSettingCheck('volumeMore')
      },
      set(value) {
        this.setSettingCheck('volumeMore', value)
      },
    },
    trendLineCrossingComputed: {
      get() {
        return this.getSettingCheck('trendLineCrossing')
      },
      set(value) {
        this.setSettingCheck('trendLineCrossing', value)
      },
    },
    gapUpComputed: {
      get() {
        return this.getSettingCheck('gapUp')
      },
      set(value) {
        this.setSettingCheck('gapUp', value)
      },
    },
    gapDownComputed: {
      get() {
        return this.getSettingCheck('gapDown')
      },
      set(value) {
        this.setSettingCheck('gapDown', value)
      },
    },
    settings_break_period: {
      get() {
        return this.getSetting('break', 'period')
      },
      set(value) {
        this.setSetting('break', 'period', value)
      },
    },
    settings_break_direction: {
      get() {
        return this.getSetting('break', 'direction')
      },
      set(value) {
        this.setSetting('break', 'direction', value)
      },
    },
   
    settings_break_type: {
      get() {
        return this.getSetting('break', 'type')
      },
      set(value) {
        this.setSetting('break', 'type', value)
      },
    },
   
    settings_prebreak_pct: {
      get() {
        return this.getSetting('prebreak', 'pct')
      },
      set(value) {
        this.setSetting('prebreak', 'pct', Math.max(0, Math.min(100, value)))
      },
    },
    settings_prebreak_period: {
      get() {
        return this.getSetting('prebreak', 'period')
      },
      set(value) {
        this.setSetting('prebreak', 'period', value)
      },
    },
    settings_prebreak_type: {
      get() {
        return this.getSetting('prebreak', 'type')
      },
      set(value) {
        this.setSetting('prebreak', 'type', value)
      },
    },
    settings_volumeIncrease_period: {
      get() {
        return this.getSetting('volumeIncrease', 'period')
      },
      set(value) {
        this.setSetting('volumeIncrease', 'period', value)
      },
    },
    settings_tciBreak_direction: {
      get() {
        return this.getSetting('tciBreak', 'direction')
      },
      set(value) {
        this.setSetting('tciBreak', 'direction', value)
      },
    },
    settings_tciTrend_direction: {
      get() {
        return this.getSetting('tciTrend', 'direction')
      },
      set(value) {
        this.setSetting('tciTrend', 'direction', value)
      },
    },
    settings_tciTrend_period: {
      get() {
        return this.getSetting('tciTrend', 'period')
      },
      set(value) {
        this.setSetting('tciTrend', 'period', value)
      },
    },
    settings_macd_period: {
      get() {
        return this.getSetting('macd', 'period')
      },
      set(value) {
        this.setSetting('macd', 'period', value)
      },
    },
    settings_obv_pctAbove: {
      get() {
        return this.getSetting('obv', 'pctAbove')
      },
      set(value) {
        this.setSetting('obv', 'pctAbove', value)
      },
    },
    settings_obv_period: {
      get() {
        return this.getSetting('obv', 'period')
      },
      set(value) {
        this.setSetting('obv', 'period', value)
      },
    },
    settings_volumeMore_pct: {
      get() {
        return this.getSetting('volumeMore', 'pct')
      },
      set(value) {
        this.setSetting('volumeMore', 'pct', value)
      },
    },
    settings_volumeMore_period: {
      get() {
        return this.getSetting('volumeMore', 'period')
      },
      set(value) {
        this.setSetting('volumeMore', 'period', value)
      },
    },
    settings_trendLineCrossing_period: {
      get() {
        return this.getSetting('trendLineCrossing', 'period')
      },
      set(value) {
        this.setSetting('trendLineCrossing', 'period', value)
      },
    },
    settings_gapUp_cents: {
      get() {
        return this.getSetting('gapUp', 'cents')
      },
      set(value) {
        this.setSetting('gapUp', 'cents', value)
      },
    },
    settings_gapDown_cents: {
      get() {
        return this.getSetting('gapDown', 'cents')
      },
      set(value) {
        this.setSetting('gapDown', 'cents', value)
      },
    },
    settings_matchCombination: {
      get() {
        let settings = this.eventSettingsData
        return settings && settings.matchCombination
          ? settings.matchCombination
          : this.settings.matchCombination
      },
      set(value) {
        let settings = this.eventSettingsData
        settings['matchCombination'] = value
        this.setEventsFilterSettings({
          l: 'settings_single',
          id: this.id,
          settings,
        })
      },
    },
  },
  methods: {
    ...mapActions(['setEventsFilterSettings']),
    setSetting(key1, key2, value) {
      let settings = this.eventSettingsData
      if(key1 == 'matchCombination'){
        settings[key1] = value
      }else{
        settings[key1][key2] = value
      }
      this.setEventsFilterSettings({
        l: 'settings_single',
        id: this.id,
        settings,
      })
    },
    handleCheck(key){
        let val = this.getActive(key,'isActive')
        this.setSetting(key,'isActive',!val)
    },
    getSetting(key1, key2) {
      let settings = this.eventSettingsData
      console.log('patterns=======>', key1, key2)
      console.log('settings===>', key1, key2, settings,settings[key1],settings[key1][key2])
      if (settings && settings[key1]) {
        console.log('if', settings, settings[key1][key2])
        return settings[key1][key2]
      } else {
        console.log("if else",this.settings[key1][key2])
        return this.settings[key1][key2]
      }
      // this.setEventsFilterSettings({l:"settings_single",id:this.id,settings})
    },

    getActive(key1, key2) {
      let settings = this.getEventsSettings(this.id)
      if(key1 == 'matchCombination'){
        return settings[key1]
      }else{
        return settings[key1][key2]
      }
    },
  },
  mounted() {},
  watch: {},
}
</script>

<style></style>

<template>
  <!-- icon dark:bg-[#FFFFFF1A] -->
  <!-- icon dark:text-[#B0B0B0] -->
  <!-- btn-bg dark:hover:bg-[#232323]  -->
  <!-- txt dark:text-[#F6F6F6] -->

  <div
    class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden"
  >
    <div class="w-full h-full text-[#000000] !overflow-x-hidden">
      <!--Header-->

      <div
        class="w-full !h-[50px] px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="` Remove '${getSelectedWatchlist?.name}' from
          watchlists?`"
          @close="closeRemoveModal"
        />
      </div>

      <div
        class="px-8 py-3 border-b border-t border-[#E2E8F0] w-full flex flex-col -justify-center min-h-[130px] -min-h-[158px]"
      >
        <div class="h-full flex flex-col gap-2 justify-center">
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[14px] font-normal"
            >Are you sure you want to remove this watchlist?
          </span>
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[14px] font-normal"
            >Don't worry, you can always create whenever you need.
          </span>
          <span v-if="showError" class="text-errorText font-normal"
            >{{ showError }}
          </span>
        </div>
      </div>

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3 dark:bg-[#1A1A1A]"
      >
        <ModalFooter
          firstTitle="No, Keep this"
          secondTitle="Yes, Remove"
          colorType="color"
          :isLoading="isLoading"
          @close="closeRemoveModal"
          @save="saveRemovedWatchlist"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getSvgByName } from '../assets/Navbarsvgs/SvgUtils'
import Loader from './Loading.vue'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'

export default {
  name: 'RemoveWatchlistModal',

  data() {
    return {
      inputValue: '',
      selectedAlphabet: { key: 'a-d', value: 'A-D' },
      selectedVal: { key: 'all', value: 'All' },
      screenWidth: window.innerWidth,
      tickerInfo: [],
      symbolAdded: false,
      isIcon: 'addicon',
      isLoading: false,
      showError: '',
    }
  },

  props: {
    deleteWatchlistId: String,
  },

  components: {
    Loader,
    ModalHeader,
    ModalFooter,
  },

  computed: {
    ...mapGetters(['getTheme', 'getSelectedWatchlist']),
    filteredContent() {
      return this.tickerInfo?.filter((item) =>
        item?.ticker?.toLowerCase().includes(this.inputValue?.toLowerCase())
      )
    },
  },

  methods: {
    ...mapActions(['deleteWatchList']),

    async saveRemovedWatchlist() {
      this.isLoading = true
      let res = await this.deleteWatchList(this.deleteWatchlistId)
      if (!res?.isError) {
        this.isLoading = false
        this.closeRemoveModal()
      } else {
        this.showError = 'Some error occured while deleting watchlist'
        setTimeout(() => {
          this.showError = ''
        }, 5000)
      }
      this.isLoading = false
    },

    removeSymbol() {},

    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },
    getSvg(name) {
      return getSvgByName(name)
    },

    clearInput() {
      this.inputValue = ''
    },

    closeRemoveModal() {
      this.$emit('closeModal')
    },
    handleData(item) {
      this.selectedVal = item
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },

  watch: {
    inputValue(val) {
      if (val) {
        this.handleSymbolData()
      } else {
        this.tickerInfo = []
      }
    },
  },

  mounted() {
    // Add event listener when the component is mounted
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}

.scroll-container {
  /* Adjust height as needed */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>

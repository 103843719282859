import moment from "moment/moment";

export function getTimeCalculation(rangeObj,endingTime){
    let startingTime
    if (rangeObj.range === '1M') {
        // let calculateThreeM = moment(endingTime).subtract(10, 'days');
        let calculateThreeM = moment(endingTime).subtract(1, 'months');
        startingTime = calculateThreeM.valueOf()
        // console.log("calculateThreeM",calculateThreeM.toISOString())
    }
    else if (rangeObj.range === '3M') {
        // let calculateThreeM = moment(endingTime).subtract(10, 'days');
        let calculateThreeM = moment(endingTime).subtract(3, 'months');
        startingTime = calculateThreeM.valueOf()
        // console.log("calculateThreeM",calculateThreeM.toISOString())
    }
    else if (rangeObj.range === '6M') {
        let calculateSixM = moment(endingTime).subtract(6, 'months');
        startingTime = calculateSixM.valueOf()
        // console.log("calculateSixM",calculateSixM.toISOString())
        // console.log("hello",startingTime)
    }
    else if (rangeObj.range === 'YTD') {
        // console.log('ytd')
        startingTime = moment().startOf('year').valueOf()
        // console.log("hello",startingTime)
    }
    else if (rangeObj.range.includes('Y')) {
        const numOfYears = parseInt(
            rangeObj.range.substring(0, rangeObj.range.length - 1)
        )
        let calculatedStartingTimeForYears = moment(endingTime)
            .subtract(numOfYears, 'year')
            .valueOf()

        // console.log(
        //   'valid date:',
        //   moment(calculatedStartingTimeForYears).format('LLLL')
        // )
        let checkValidDate = moment(calculatedStartingTimeForYears)
        if (checkValidDate.isValid()) {
            startingTime = calculatedStartingTimeForYears;
        }
    }
    return startingTime;
}
<template>
  <div
    ref="symbolArea"
    class="w-full fles -bg-green-400 -border-5 -border-lime-600 h-full relative flex justify-center items-center space-x-2 !overflow-visible"
  >
    <!-- <div class="!w-[7rem] h-[3rem] -border-2 -!border-green-600 text-[12px]">
      <span>
        {{ selectedSymbolType }}
      </span>
    </div> -->
    <div class="border -!border-green-600 flex items-center">
      <div class="-!w-[10rem] h-[2.8rem]">
        <input
          v-model="localValue"
          placeholder="SEARCH"
          @focus="applyFocusStyles"
          @blur="removeFocusStyles"
          @input="updateValue($event.target.value)"
          @keyup.enter="handleEnter"
          @keydown.down.prevent="navigateResults('down')"
          @keydown.up.prevent="navigateResults('up')"
          @keydown.enter="selectResult"
          @mousedown="selectText"
          ref="searchInput"
          :class="
            symbolClick
              ? 'text-black dark:text-white'
              : 'text-black/70 dark:!text-[#6C707A]'
          "
          class="w-full h-full uppercase text-[12px] shadow-lg p-2 -border rounded-[4px] bg-transparent border-none focus:outline-none"
        />
      </div>

      <div
        class="!w-[7rem] h-[2.8rem] -bg-red-500 -border-2 flex items-center justify-center -!border-green-600 text-[12px]"
      >
        <span>
          {{ selectedSymbolType }}
        </span>
      </div>

      <div class="flex items-center">
        <div
          @click="symbolModal = true"
          class="p-2.5 -rounded-[4px] cursor-pointer border-r flex items-center justify-center hover:bg-lightGrayHover bg-appGrayLight dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 text-[#383838] dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div
          @click="addField"
          v-if="showAdd"
          class="p-2.5 -rounded-[4px] cursor-pointer flex items-center justify-center hover:bg-lightGrayHover bg-appGrayLight dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"   class="size-6 text-[#383838] dark:text-white">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>

        
        </div>
      </div>
    </div>

    <!-- search results -->

    <div
      v-if="openSymbolDropdown && value"
      style="z-index: 20000"
      ref="resultList"
      class="w-[25rem] max-h-[20rem] absolute top-[3rem] shadow-lg border-[0.1px] border-[#E7E7E7] dark:!border-[#404248] dark:!bg-[#000000] !bg-[#ffffff] px-3 flex flex-col py-2 overflow-y-auto scroll-container"
    >
      <!-- Loader -->
      <div
        v-if="isLoading"
        class="w-full h-[10rem] flex items-center justify-center"
      >
        <span>
          <Loader customClass="w-10 h-10" />
        </span>
      </div>

      <!-- Search results -->

      <div
        v-else-if="filteredOptions?.length > 0"
        v-for="(item, ind) in filteredOptions"
        :key="ind"
        @click="selectSymbol(item)"
        :class="{ selected: ind === selectedIndex }"
        class="w-full flex items-center cursor-pointer space-x-4 px-2 dark:hover:bg-[#2D2D2D] hover:bg-[#E4E4E460] border-b dark:border-b-[#2D2D2D] py-3 border-b-[#E7E7E7] transition-all"
      >
        <!-- <div
          class="w-10 h-10 flex uppercase items-center -dark:bg-[#1a1a1a] dark:text-[#f6f6f6] justify-center rounded-full bg-[#E2E8F0] dark:bg-[#676868]"
        >
          {{ item?.symbol[0] }}
        </div> -->
        <div
          class="min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] rounded-full text-white text-[18px] -bg-[red] flex justify-center items-center"
        >
          <TickerIcon :ticker="item?.symbol" />
        </div>

        <div
          class="w-full h-full -bg-black leading-5 flex flex-col justify-center"
        >
          <div class="flex gap-2">
            <span
              class="text-[#292929] dark:!text-[#e9ecf8] text-[12px] font-bold"
              >{{ item?.symbol }}</span
            >
            <span
              :title="item?.companyName"
              class="text-[12px] text-[#1e1e1e] dark:!text-[#858a97] font-normal"
              >{{ getTruncateText(item?.companyName) }}</span
            >
            <!-- <div v-html="getSvg('clockicon')" v-if="ind == 0"></div> -->
          </div>
          <div class="flex gap-2 mt-1 justify-between w-full">
            <div class="flex items-center space-x-2">
              <span
                class="text-[#000000] text-[11px] dark:text-[#34c244] font-semibold"
                >${{ item?.close || 0 }}</span
              >
              <span
                :class="item.change < 0 ? 'text-errorText' : ' text-[#037950]'"
                class="text-[9px] dark:!text-[10px] font-semibold"
                >{{ item.change?.toFixed(2) || 0 }} ({{
                  item.changePercent?.toFixed(2) || 0
                }}%)</span
              >
            </div>
            <div>
              <span class="text-[10px] dark:text-[#DEDEDE] text-[#323232]">{{
                item?.exchange
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="w-full h-[10rem] flex items-center justify-center">
        <span class="dark:text-[#ffffff] text-[14px]">No symbol found</span>
      </div>
    </div>

    <div v-if="symbolModal">
      <BaseModal>
        <SymbolSearch
          @closeModal="handleCloseSymbolSearch"
          @handleSymbol="handleSymbolSelect"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { fetchSymbolByName } from '../services/symbol'
import Loader from './Loading.vue'
import { modifyResult } from '../utils/common'
import BaseModal from './BaseModal.vue'
import SymbolSearch from './SymbolSearch.vue'
import TickerIcon from './TickerIcon.vue'
import { thresholdScott } from 'd3'

export default {
  name: 'SymbolSearchBox',
  components: {
    Loader,
    BaseModal,
    TickerIcon,
    SymbolSearch,
  },
  props: {
    symbolNameProp: {
      type: String,
      default: '',
    },
    value: {
      type: String,
    },
    selectedSymbolType: {
      type: String,
      default: 'ASX',
    },
    showAdd:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      filteredOptions: [],
      openSymbolDropdown: false,
      isLoading: false,
      symbolInput: '',
      symbolModal: false,
      selectedIndex: -1,
      symbolClick: false,
    }
  },
  computed:{
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.updateValue(newValue);
      }
    }
  },
  methods: {
    ...mapMutations(['changeSymbol', 'deleteSymbol']),
    updateValue(value) {
      this.$emit('input', value); // Emit event to update parent
    },
    // search functionality start

    navigateResults(direction) {
      if (direction === 'down') {
        if (this.selectedIndex < this.filteredOptions.length - 1) {
          this.selectedIndex++
        } else {
          this.selectedIndex = 0 // Loop back to the first item
        }
      } else if (direction === 'up') {
        if (this.selectedIndex > 0) {
          this.selectedIndex--
        } else {
          this.selectedIndex = this.filteredOptions.length - 1 // Loop back to the last item
        }
      }
      this.scrollToSelected()
    },

    scrollToSelected() {
      const resultList = this.$refs.resultList
      const selectedItem = resultList.children[this.selectedIndex]

      if (selectedItem) {
        const itemTop = selectedItem.offsetTop
        const itemBottom = itemTop + selectedItem.offsetHeight
        const listTop = resultList.scrollTop
        const listBottom = listTop + resultList.clientHeight

        if (itemBottom > listBottom) {
          resultList.scrollTop = itemBottom - resultList.clientHeight
        } else if (itemTop < listTop) {
          resultList.scrollTop = itemTop
        }
      }
    },

    handleEnter() {
      this.searchResultDropDown = false
      if (this.filteredOptions?.length > 0 && this.selectedIndex !== 1) {
        this.selectSymbol(this.filteredOptions[0])
        console.log('filteredOptionsWatch', this.filteredOptions)
      }
      if (this.selectedIndex !== -1) {
        // this.inputValue = this.filteredOptions?.symbol[this.selectedIndex]
        this.selectSymbol(this.filteredOptions[this.selectedIndex])
      }
    },

    selectText(event) {
      // Prevent default to ensure text is selected on mouse down
      event.preventDefault()
      const input = this.$refs.searchInput
      input.select()
    },
    toUppercase() {
      this.searchTerm = this.searchTerm?.toUpperCase()
    },
    applyFocusStyles() {
      // this.inputStyle.borderColor = '#000000'
      // this.inputStyle.boxShadow = '0 0 5px rgba(0 0 0 / 50%)'
    },
    removeFocusStyles() {
      // this.inputStyle.borderColor = '#ccc'
      this.inputStyle.boxShadow = 'none'
    },

    // handleKeyDown(event) {
    //   if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
    //     event.preventDefault()
    //     this.symbolModal = true
    //   }
    // },

    // search functionality end

    

    selectSymbol(item) {
      this.handleSymbolSelect(item)
    },

    selectSymbolModalData(val) {
      let symbolWithExchange = `${val?.symbol}~${val.exchange}`
      this.changeSymbol({
        propName: this.symbolNameProp,
        propValue: symbolWithExchange,
      })
      this.$emit('input', symbolWithExchange)
      this.symbolInput = val.symbol
      this.openSymbolDropdown = false
    },

    handleCloseSymbolSearch() {
      this.symbolModal = false
    },
    handleSymbolSelect(val) {
      this.selectSymbolModalData(val)
      this.handleCloseSymbolSearch()
    },

    async onInputChange(text) {
      console.log('text', text)
      this.isLoading = true
      if (!text) {
        let filtered2 = await fetchSymbolByName(this, '1')
        this.filteredOptions = modifyResult(filtered2)
        this.openSymbolDropdown = true
        this.$store.commit('TOP_LIST_SYMBOL', '1')
        this.$store.commit('TOP_LIST_SYMBOL_NAME', '1')
        this.optionDisplays = 'none'
        return
      } else {
        let filtered2 = await fetchSymbolByName(this, text)
        this.optionDisplays = 'block'
        this.filteredOptions = modifyResult(filtered2)
        this.openSymbolDropdown = true
        console.log('filteredOptions', this.filteredOptions)
        if (this.filteredOptions && this.filteredOptions.length) {
          this.$store.commit(
            'TOP_LIST_SYMBOL',
            JSON.stringify(filtered2[0].data[0].symbol)
          )
          this.$store.commit(
            'TOP_LIST_SYMBOL_NAME',
            JSON.stringify(filtered2[0].name)
          )
        } else {
          this.$store.commit('TOP_LIST_SYMBOL', '')
          this.$store.commit('TOP_LIST_SYMBOL_NAME', '')
          this.optionDisplays = 'none'
        }
      }
      this.isLoading = false
    },
    getTruncateText(val) {
      if (val?.length > 15) {
        return val?.substring(0, 15) + '..'
      } else {
        return val
      }
    },
    clearData() {
      this.$emit('input', '')
    },
    addField(){
      this.$emit('add')
    },
    handleClickOutside(event) {
      if (!this.$refs.symbolArea.contains(event.target)) {
        this.openSymbolDropdown = false
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    // window.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keydown', this.handleKeyDownClose)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    localValue(val) {
      if (val != this.symbolInput) {
        this.onInputChange(val)
        console.log('valwatch', val)
      } else {
        this.filteredOptions = []
      }
    },
  },
}
</script>

<style>
input::placeholder {
  color: #919192;
  letter-spacing: 0.5px;
  font-size: 12px;
}
input::selection {
  background: black !important; /* Background color for the selected text */
  color: white !important; /* Text color when selected */
}
.selected {
  background-color: #f0f0f0;
}
</style>

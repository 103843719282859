<template>
  <div
    @click="$emit('is-active', selectedKey)"
    :class="activeClass ? 'bg-[#f7f7f7]' : 'hover:bg-[#EFEFEF80]'"
    class="-border -border-black focus:outline-none cursor-pointer focus:ring-0 -bg-blue-500 flex justify-between items-center w-full h-full px-2 py-3"
  >
    <div>
      <slot></slot>
    </div>
    <div class="flex justify-center items-center w-auto h-full">
      <span class="cursor-pointer">
        <svg
          width="28"
          height="29"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
            :fill="activeClass ? '#1C274C' : '#E2E2E2'"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrapperDiv',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    selectedKey: {
      type: String,
      default: null,
    },
    activeClass: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    activeClass(val) {
      console.log('activeClass', val)
    },
  },
}
</script>

<style></style>

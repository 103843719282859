<template>
  <div class="flex space-y-2 w-full h-full">
    <div class="w-full h-full -bg-slate-400">
      <PatternWrapper
        :data="data"
        :settingData="patternSettingsData"
        @onPress="handlePress"
      />
    </div>
  </div>
</template>

<script>
import PatternWrapper from '../../PatternWrapper.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChartingPatternComp',
  components: {
    PatternWrapper,
  },
  props: {
    dialogId: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          name: 'Reversal patterns',
          tag: 'Bullish',
          detail:
            'These patterns suggest a trend is changing from a downtrend to an uptrend, indicating a ',
          highlighted: 'potential buying opportunity.',
          group: [
            {
              name: 'Saucer Bottom',
              key: 'saucerBottom',
              icon: 'Saucar-Button',
              desc: "A saucer, also called a rounding bottom, refers to a technical charting pattern that signals a potential reversal in a security's price. It forms when that security's price has reached a low and begins trending upward.",
            },
            {
              name: 'Double Bottom',
              key: 'doubleBottom',
              icon: 'Double-Bottom',
              desc: 'A bullish reversal chart pattern formed by two consecutive troughs at approximately the same price level. Its purpose is to signal the potential end of a downtrend and the beginning of an upward price movement.',
            },
            {
              name: 'Tri-Point Support',
              key: 'triPointSupport',
              icon: 'Tri-Point-Support',
              desc: 'Tri-Point Support is a chart pattern where the price touches the same support level three times without breaking below it. This pattern signals strong buying pressure, indicating a potential upward price movement.',
            },
            {
              name: 'Bearish Key Reversal',
              key: 'bearishKeyReversal',
              icon: 'Bearish-Key-Reversal',
              desc: "When the price makes a new high but then closes below the previous day's low. Its purpose is to signal a potential reversal from an uptrend to a downtrend, indicating a shift in market sentiment from bullish to bearish.",
            },
          ],
        },
        {
          name: ' Reversal Patterns',
          tag: 'Bearish',
          detail:
            'These patterns suggest a trend is changing from an uptrend to a downtrend, indicating a ',
          highlighted: 'potential selling opportunity.',
          group: [
            {
              name: 'Double Top',
              key: 'doubleTop',
              icon: 'Double-Top',
              desc: 'A bearish reversal chart pattern formed by two consecutive peaks at roughly the same price level, separated by a trough. Its purpose is to signal a potential reversal from an uptrend to a downtrend.',
            },
            {
              name: 'Tri-Point Resistance',
              key: 'triPointResistance',
              icon: 'Tri-Point-Resistance',
              desc: 'A technical analysis concept where the price touches the same support level three times, forming a strong base. Its purpose is to indicate a significant level where buying pressure is strong, potentially preventing further price declines.',
            },
            {
              name: 'Bullish Key Reversal',
              key: 'bullishKeyReversal',
              icon: 'Bullish-Key-Reversal',
              desc: "A chart pattern that occurs when the price makes a new low but then closes above the previous day's high. Its purpose is to signal a potential reversal from a downtrend to an uptrend, indicating a shift in market sentiment from bearish to bullish.",
            },
          ],
        },
        {
          name: 'Continuation Patterns',
          tag: 'Bullish',
          detail:
            'These patterns suggest the existing uptrend will resume after a brief pause, signalling a potential opportunity to ',
          highlighted: 'enter or add to long positions.',
          group: [
            {
              name: 'Bullish Pennant',
              key: 'bullishPennant',
              icon: 'Bullish-Pennant',
              desc: 'A continuation pattern formed during an uptrend, characterised by a small, symmetrical triangle following a strong upward move. Its purpose is to signal the continuation of the uptrend after a brief consolidation, indicating that the price is likely to break out higher.',
            },
            {
              name: 'Bullish Flag',
              key: 'bullishFlag',
              icon: 'Bullish-Flag',
              desc: 'A continuation chart pattern that forms during an uptrend, characterized by a brief consolidation in the shape of a downward-sloping rectangle after a strong upward move (flagpole). Its purpose is to signal that the price is likely to resume the uptrend after the consolidation phase.',
            },
            {
              name: 'Ascending Triangle',
              key: 'ascendingTriangle',
              icon: 'Ascending-Triangle',
              desc: 'A bullish continuation pattern formed by a horizontal resistance line and rising support. Its purpose is to signal that the price is likely to break out above the resistance, indicating the continuation of the uptrend.',
            },
          ],
        },
        {
          name: 'Continuation Patterns',
          tag: 'Bearish',
          detail:
            'These patterns suggest the existing downtrend will resume after a brief pause, signalling a potential opportunity to ',
          highlighted: 'enter or add to short',
          group: [
            {
              name: 'Bearish Pennant',
              key: 'bearishPennant',
              icon: 'Bearish-Pennant',
              desc: 'A continuation chart pattern that forms during a downtrend, characterized by a small symmetrical triangle following a sharp downward move (flagpole). Its purpose is to signal the continuation of the downtrend after a brief consolidation.',
            },
            {
              name: 'Bearish Flag',
              key: 'bearishFlag',
              icon: 'Bearish-Flag',
              desc: 'A continuation chart pattern that forms during an uptrend, characterized by a brief consolidation in the shape of a downward-sloping rectangle after a strong upward move (flagpole). Its purpose is to signal that the price is likely to resume the uptrend after the consolidation phase.',
            },
            {
              name: 'Descending Triangle',
              key: 'descendingTriangle',
              icon: 'Descending-Triangle',
              desc: 'A bearish continuation chart pattern characterized by a horizontal support line and a downward-sloping resistance line. Its purpose is to signal that the price is likely to break below the support line, indicating the continuation of the downtrend.',
            },
          ],
        },
      ],
    }
  },

  methods: {
    ...mapActions(['setPatternSettings']),
    handlePress(key) {
      let updatedSetting = this.patternSettingsData
      updatedSetting[key] = !updatedSetting[key]
      let payload = {
        id: this.id,
        settings: updatedSetting,
      }
      this.setPatternSettings(payload)
    },
  },

  computed: {
    ...mapGetters(['getPatternSettings']),
    patternSettingsData() {
      return this.getPatternSettings(this.id)
    },
  },
}
</script>

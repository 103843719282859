<template>
  <div class="p-2">
    <!-- Close above/below -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('crossesAndAboveBelow', 'isActive')"
      :selectedKey="'crossesAndAboveBelow'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Price Break Analysis
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Stocks that experienced their</span>
          <select
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 150px; height: 45px; font-size: 16px"
            type="select"
            v-model="settings.crossesAndAboveBelow.left"
          >
            <option
              v-for="direction of options1"
              :value="direction.value"
              :key="direction.value"
            >
              {{ direction.text }}
            </option>
          </select>
          <input
            v-if="settings.crossesAndAboveBelow.left !== 'close'"
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.crossesAndAboveBelow.leftPeriod"
          />
          <select
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 150px; height: 45px; font-size: 16px"
            type="select"
            v-model="settings.crossesAndAboveBelow.operatorType"
          >
            <option
              v-for="type of options2"
              :value="type.value"
              :key="type.value"
            >
              {{ type.text }}
            </option>
          </select>
          <select
            v-model="settings.crossesAndAboveBelow.right"
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 150px; height: 45px; font-size: 16px"
            type="select"
          >
            <option
              v-for="type of options3"
              :value="type.value"
              :key="type.value"
            >
              {{ type.text }}
            </option>
          </select>
          <input
            v-if="settings.crossesAndAboveBelow.right !== 'close'"
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.crossesAndAboveBelow.rightPeriod"
          />
        </div>

        <div>
          <DescriptionInput
            :description="`Identify stocks with a ${settings.crossesAndAboveBelow.left} ${settings.crossesAndAboveBelow.operatorType}  ${settings.crossesAndAboveBelow.right}`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- ROC -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('ROC', 'isActive')"
      :selectedKey="'ROC'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Rate of Change (ROC) Signal
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Stocks with ROC indicating an overbought condition.</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.ROC.period"
          />
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              style="width: 20px; height: 20px"
              id="rsiMode~overbought"
              name="rocMode"
              value="overbought"
              v-model="settings.ROC.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Overbought</label
            >
          </div>
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              id="rsiMode~oversold"
              name="rocMode"
              value="oversold"
              style="width: 20px; height: 20px"
              v-model="settings.ROC.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Oversold</label
            >
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Select stocks where ROC shows ${settings.ROC.period} ${settings.ROC.mode} condition.`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- RSI -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('RSI', 'isActive')"
      :selectedKey="'RSI'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Relative Strength Index (RSI) Condition
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Relative Strength Index (RSI) Condition</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.RSI.period"
          />
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              style="width: 20px; height: 20px"
              id="rsiMode~overbought"
              name="rsiMode"
              value="overbought"
              v-model="settings.RSI.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Overbought</label
            >
          </div>
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              id="rsiMode~oversold"
              name="rsiMode"
              value="oversold"
              style="width: 20px; height: 20px"
              v-model="settings.RSI.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Oversold</label
            >
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Select stocks where ROC shows ${settings.RSI.mode} ${settings.RSI.mode} condition.`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- STOC -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('STOC', 'isActive')"
      :selectedKey="'STOC'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Stochastic Oscillator Condition
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Period</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.STOC.period"
          />
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.STOC.kPercent"
          />
          <span>%K</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.STOC.dPercent"
          />
          <span>%D</span>
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              style="width: 20px; height: 20px"
              id="rsiMode~overbought"
              name="stocMode"
              value="overbought"
              v-model="settings.STOC.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Overbought</label
            >
          </div>
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              id="rsiMode~oversold"
              name="stocMode"
              value="oversold"
              style="width: 20px; height: 20px"
              v-model="settings.STOC.mode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Oversold</label
            >
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Select stocks where the Stochastic Oscillator (%K: ${settings.STOC.kPercent}, %D: ${settings.STOC.dPercent}) over the ${settings.STOC.period} period is in an ${settings.STOC.mode} condition.`"
          />
        </div>
      </div>
    </Wrapper>
    <!-- Bands -->
    <Wrapper
      @is-active="isActiveHandler"
      :activeClass="getActive('BANDS', 'isActive')"
      :selectedKey="'BANDS'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Bollinger Band Break
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Period</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 15%; height: 45px; font-size: 16px"
            type="number"
            tabindex="3"
            v-model="settings.BANDS.period"
          />
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              id="bbMode~top"
              name="bbMode"
              value="top"
              style="width: 20px; height: 20px"
              v-model="settings.BANDS.breakMode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Top Break</label
            >
          </div>
          <div class="w-auto flex gap-2 items-center h-full">
            <input
              type="radio"
              id="bbMode~bottom"
              name="bbMode"
              value="bottom"
              style="width: 20px; height: 20px"
              v-model="settings.BANDS.breakMode"
            />
            <label
              for="rsiMode~overbought"
              :style="{  cursor: 'pointer' }"
              >Bottom Break</label
            >
          </div>
        </div>

        <div>
          <DescriptionInput
            :description="`Select stocks that have experienced a ${settings.BANDS.breakMode} Bollinger Band break over the last ${settings.BANDS.period} period.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DescriptionInput from './DescriptionInput.vue'
import Wrapper from './Wrapper.vue'
const DEFAULT_SETTINGS = {
  crossesAndAboveBelow: {
    isActive: false,
    left: 'close',
    leftPeriod: 14,
    operatorType: 'above',
    right: 'ma',
    rightPeriod: 14,
  },
  ROC: {
    isActive: false,
    period: 10,
    mode: 'overbought',
  },
  RSI: {
    isActive: false,
    period: 18,
    mode: 'overbought',
  },
  STOC: {
    isActive: false,
    period: 15,
    kPercent: 3,
    dPercent: 3,
    mode: 'overbought',
  },
  BANDS: {
    isActive: false,
    period: 21,
    breakMode: 'top',
  },
}
export default {
  components: { Wrapper, DescriptionInput },
  name: 'IndicatorsComponents',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      options1: [
        { text: 'Close', value: 'close' },
        { text: 'MA', value: 'ma' },
        { text: 'WMA', value: 'wma' },
        { text: 'XMA', value: 'xma' },
      ],
      options2: [
        { text: 'is Above', value: 'above' },
        { text: 'is Below', value: 'below' },
        { text: 'Crossed Above', value: 'crossAbove' },
        { text: 'Crossed Below', value: 'crossBelow' },
      ],
      options3: [
        { text: 'Close', value: 'close' },
        { text: 'MA', value: 'ma' },
        { text: 'WMA', value: 'wma' },
        { text: 'XMA', value: 'xma' },
      ],
    }
  },
  methods: {
    ...mapActions(['setIndicatorsScanSettings']),
    isActiveHandler(key) {
      const val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
    },
    setSetting(key1, key2, val) {
      const filters = this.settings
      if (key1 == 'isExcludeIntraDayData') {
        filters[key1] = val
      } else if (key1 == 'isIgnoreResultsWithNoRangeForLastDay') {
        filters[key1] = val
      } else {
        filters[key1][key2] = val
      }
      // const payload = {
      //   l: 'settings_single',
      //   id: this.id,
      //   filters,
      // }
      this.setIndicatorsScanSettings(filters)
    },
    getActive(key1, key2) {
      const filters = this.getIndicatorSetting
      if (key1 === 'isIgnoreResultsWithNoRangeForLastDay') {
        console.log('isIgnore===>', key1)
        return filters[key1]
      } else if (key1 == 'isExcludeIntraDayData') {
        return filters[key1]
      } else {
        return filters[key1][key2]
      }
    },
  },
  computed: {
    ...mapGetters(['getIndicatorSetting']),
  },
  watch: {
    settings: {
      handler: function (newSettings) {
        this.setIndicatorsScanSettings(newSettings)
      },
      deep: true,
    },
  },
}
</script>

<style>
</style>
<template>
  <div class="w-full h-full">
    <FormLayout>
      <div v-if="linkSent">
        <div>
          <span class="largeText" style="font-size: 44px"
            >Reset password link has been sent to your
            <span style="color: #f20e17">Email</span></span
          >
        </div>
      </div>
      <div v-else class="w-full h-full md:space-y-3">
        <div
          class="formFreeTrial mt-[1.5rem]"
          style="
            height: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 10px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <span class="headerTitle text-appGrayDark"> Forgot Password </span>
          <span
            v-if="showResponseError"
            class="errorText"
            style="
              color: #f20e17;
              font-size: 15px;
              margin-top: 4%;
              font-weight: 300;
            "
            >{{ showResponseError }}</span
          >
        </div>
        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            type="text"
            placeholder="Email"
            @enter-pressed="handleForget"
            :isError="isEmailError"
            :iconImg="
              require(`@/assets/${isEmailError ? 'redEmail.svg' : 'email.svg'}`)
            "
            v-model="email"
          />
        </div>
        <div style="margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            emailErrorMsg
          }}</span>
        </div>

        <div style="height: 8%" class="btnStyle inputHeight !mt-10">
          <button
            :class="
              email
                ? 'bg-appBlue hover:bg-blueHover'
                : 'bg-[#E0E0E0] hover:bg-lightGrayHover'
            "
            @click="handleForget"
            :style="{
              color: email ? 'white' : '#828282',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
            }"
            style="
              height: 100%;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
          >
            <span v-if="isLoading" style="margin-right: 10px">
              <Loader customClass="w-8 h-8" />
            </span>
            <span>
              {{ isLoading ? 'Sending link' : 'Send link' }}
            </span>
            <div v-if="email" style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
        <div class="w-full flex  justify-end">
          <router-link :to="{ name: 'SignIn' }">
            <div
              class="w-fit hover:underline text-[#F20E17] text-appBlue cursor-pointer"
            >
              Back to Sign In
            </div>
          </router-link>
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import FormLayout from '../component/FormLayout.vue'
import DynamicInput from '../component/DynamicInput.vue'
import Loader from '../component/Loading.vue'
import { forgetPassword } from '../services/user'

export default {
  name: 'ForgetPassword',
  components: {
    FormLayout,
    DynamicInput,
    Loader,
  },
  data() {
    return {
      isUnderstood: false,
      email: '',
      emailErrorMsg: '',
      isEmailError: false,
      showResponseError: '',
      isLoading: false,
      emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      linkSent: false,
    }
  },
  methods: {
    async handleForget() {
      if (!this.email) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        return
      }

      if (this.email) {
        if (!this.emailRegix.test(this.email)) {
          this.isEmailError = true
          this.emailErrorMsg = 'Invalid Email'
          return
        }
      }

      this.isLoading = true
      const payload = {
        email: this.email,
      }

      console.log("payload",payload)
      const res = await forgetPassword(payload)
      console.log("forget password",res)
      if (!res?.data?.isError) {
        this.linkSent = true
      } else {
        this.showResponseError = res?.data?.message
        setTimeout(() => {
          this.showResponseError = ''
        }, 5000)
      }
      this.isLoading = false
    },
  },
  watch: {
    email() {
      this.isEmailError = false
      this.emailErrorMsg = ''
    },
  },
}
</script>

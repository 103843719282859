<template>
  <div class="space-y-2 w-full h-full -bg-slate-400">
    <!-- first filter -->
    <!-- 
       -->
    <Wrapper
      @ok="onScanClick"
      @stop="onScanStop"
      @onClose="$emit('change', !open)"
      :valid="isValid"
      @is-active="isActiveHandler"
      :activeClass="true"
      :selectedKey="'capitulation'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Volume Spike Comparison
        </h3>
        <div>
          <!-- <span>Identify extreme market movements by comparing recent trading volumes to historical averages, signaling potential market capitulation.</span> -->
        </div>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Any volume of the last two days is at least</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="0.1"
            step="0.01"
            v-model="volumeMultiplier"
          />
          <span>times greater than the average volume of the</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="1"
            step="1"
            v-model="lastVolumeAvgPeriod"
          />
          <span>days before them.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Set the criteria to detect a significant spike in trading volume, which may indicate a capitulation event in the market.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'CapitulationScanComp',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isToggle: false,
    }
  },
  components: { DescriptionInput, Wrapper },
  computed: {
    ...mapGetters(['capitulationSettings']),
    /** @returns {Boolean} */
    isValid() {
      return this.volumeMultiplier > 0 && this.lastVolumeAvgPeriod > 0
    },
    /** @returns {Number} */
    volumeMultiplier: {
      get() {
        return this.capitulationSettings.volumeMultiplier
      },
      set(value) {
        console.log('computed', value)
        this.setCapitulationSettings({ volumeMultiplier: value })
        console.log('computed', this.capitulationSettings)
      },
    },
    /** @returns {Number} */
    lastVolumeAvgPeriod: {
      get() {
        return this.capitulationSettings.lastVolumeAvgPeriod
      },
      set(value) {
        this.setCapitulationSettings({ lastVolumeAvgPeriod: Math.round(value) })
      },
    },
  },

  methods: {
    ...mapActions([
      'sendCapitulationRequest',
      'setCapitulationSettings',
      'cancelGuruScans',
    ]),
    async onScanClick() {
      this.isLoading = true
      console.log('isToggle===>request')
      await this.sendCapitulationRequest()
      this.isLoading = false
      // result?.data?.results?.length
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    getActive(key1, key2) {
      console.log('key is finding===>', key1, key2)
      return this.settings.mode == key1
    },

    isActiveHandler(key) {
      this.isToggle = !this.isToggle
      console.log('key is finding===>', key)
      const val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
    },
  },

  mounted() {
    // console.log('capitulation', this.capitulationSettings)
    // let set = this.getCapitulationSettings('capitulation')
    // this.settings = set
  },
}
</script>

<style></style>

<template>
  <div
    class="w-full h-[95vh] flex flex-col dark:bg-[#1A1A1A] items-center shrink-0 -bg-black !zIndex"
  >
    <div class="w-full flex justify-center -border">
      <div
        class="w-full h-auto flex flex-col mt-[0.3rem] justify-around items-center"
      >
        <!-- v-for="(svgs, i) in svgsSec1Arr" -->

        <!-- RightBarUpdates Btns  -->

        <div
          @click="sideBarToggle(svgs?.name)"
          v-for="(svgs, i) in icons"
          :key="i"
          :class="
           [ selectedRight == svgs?.name
              ? '!bg-appBtnActive dark:!bg-[#2D2D2D] transition-all text-appBtnActiveTxt'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover text-appBtnTxt',getDisable()?'pointer-events-none opacity-50':'']
          "
          class="md:w-[43.2px] md:h-[43.2px] bg-[white] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer -#001A2C items-center justify-center"
        >
          <div v-html="getSvg(svgs?.name)"></div>
          <!-- {{ svgs?.name }} -->

          <!-- <svg
            width="24"
            :class="
              selectedRight == 'trainingMode'
                ? 'text-blue-500'
                : 'text-[#4C4C4C]'
            "
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
            />
          </svg> -->
        </div>

        <!-- Scanner Results Btn -->

        <!-- <div
          @click="sideBarToggle('watchlist')"
          :class="
            selectedRight == 'watchlist'
              ? '!bg-[#BBD9FB] hover:!bg-[#F6F6F6] dark:!bg-[#2D2D2D] transition-all'
              : ''
          "
          class="w-[3.8rem] h-[3.8rem] bg-white dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer rounded-[4px] items-center dark:hover:!bg-[#2D2D2D] hover:!bg-[#F6F6F6] justify-center"
        >
          <svg
            :class="selectedRight == 'watchlist' ? 'text-blue-500' : ''"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            width="44"
            height="44"
          >
            <path
              fill="currentColor"
              d="M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z"
            ></path>
          </svg>
        </div> -->

        <!-- Training Mode Btn -->

        <!-- <div
          @click="sideBarToggle('scanner')"
          :class="
            selectedRight == 'scanner'
              ? '!bg-[#BBD9FB] hover:!bg-[#F6F6F6] dark:!bg-[#2D2D2D] transition-all'
              : ''
          "
          class="w-[3.8rem] h-[3.8rem] bg-white dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer rounded-[4px] items-center dark:hover:!bg-[#2D2D2D] hover:!bg-[#F6F6F6] justify-center"
        >
          <svg
            :class="selectedRight == 'scanner' ? 'text-blue-500' : ''"
            data-v-7b48fc5b=""
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-7b48fc5b=""
              d="M14.8337 0.5H3.16699C1.98848 0.5 1.39923 0.5 1.03311 0.8435C0.666992 1.187 0.666992 1.73985 0.666992 2.84555V3.4204C0.666992 4.28527 0.666992 4.7177 0.883325 5.07618C1.09966 5.43467 1.49488 5.65715 2.28534 6.10213L4.71286 7.46867C5.24321 7.76717 5.50838 7.9165 5.69825 8.08133C6.09365 8.42458 6.33706 8.82792 6.44736 9.32267C6.50032 9.56017 6.50032 9.83817 6.50032 10.3941V12.6187C6.50032 13.3767 6.50032 13.7556 6.71026 14.0511C6.9202 14.3465 7.29306 14.4922 8.03882 14.7838C9.60432 15.3958 10.3871 15.7018 10.9437 15.3537C11.5003 15.0055 11.5003 14.2099 11.5003 12.6187V10.3941C11.5003 9.83817 11.5003 9.56017 11.5533 9.32267C11.6636 8.82792 11.907 8.42458 12.3024 8.08133C12.4922 7.9165 12.7574 7.76717 13.2878 7.46867L15.7153 6.10213C16.5057 5.65715 16.901 5.43467 17.1173 5.07618C17.3337 4.7177 17.3337 4.28527 17.3337 3.4204V2.84555C17.3337 1.73985 17.3337 1.187 16.9676 0.8435C16.6014 0.5 16.0122 0.5 14.8337 0.5Z"
              stroke="currentColor"
            ></path>
          </svg>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { getSvgByName } from '../assets/rightsidebarsvgs/utils/SvgUtils'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'RightBarUpdate',
  data() {
    return {
      icons: [
        {
          id: 1,
          name: 'watchlist',
        },
        {
          id: 2,
          name: 'scannerResults',
        },
        {
          id: 3,
          name: 'trainingMode',
        },
        {
          id: 4,
          name: 'comparingSymbols',
        },
      ],
      svgsSec1Arr: [
        'icon1',
        'icon2',
        'icon3',
        'icon4',
        'icon5',
        'icon6',
        'icon7',
        'icon8',
      ],
      svgsSec2Arr: ['icon9', 'icon10'],
      svgsSec3Arr: ['icon11', 'icon12', 'icon13', 'icon14'],
      svgsSec4Arr: ['icon15'],
    }
  },
  props: {
    selectedRight: String,
  },
  components: {},
  methods: {
    ...mapActions(['updateTogglePlay']),
    getSvg(name) {
      return getSvgByName(name)
    },
    getDisable(){
      if(this.isTrainingMode){
        return true
      }else{
        return false
      }
    },
    sideBarToggle(name) {
      if (
        name != 'comparingSymbols' &&
        this.isTrainingMode &&
        this.getTogglePlay
      ) {
        this.updateTogglePlay()
      }
      this.$emit('sideBarToggle', name)
    },
  },
  computed: {
    ...mapGetters([
      'getScannerRightbarOpen',
      'isTrainingMode',
      'getTogglePlay',
    ]),
  },
  mounted() {},
  watch: {
    getScannerRightbarOpen(val) {
      console.log('val', val, this.selectedRight)
      if (val) {
        if (this.selectedRight != 'scannerResults') {
          console.log('val inside')
          setTimeout(() => {
            this.sideBarToggle('scannerResults')
          }, 200)
        }
      }
    },
  },
}
</script>
<style scoped>
.zIndex {
  z-index: 100 !important;
}
</style>

<template>
  <div class="w-full h-full">
    <div v-if="isTrainingMode" class="py-2">
      <div class="w-full space-y-1">
        <div
          v-for="symData of data"
          :key="symData.left"
          class="w-full flex items-center border-b px-4 py-2 justify-between"
        >
          <div class="text-[14px] text-appTextColor font-[400]">
            {{ symData.left }}
          </div>
          <div class="text-[#001A2C] text-[13px] font-[600]">
            ${{ symData.right || '0' }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-full relative h-full space-y-2 px-4 py-3">
      <div>Starting Date</div>
      <div class="absolute w-11/12 h-auto left-6" >
      <div class="w-full">
        <DatePicker
          v-model="startingDate"
          :placeholder="placeholder"
          type="date"
        ></DatePicker>
      </div>
      <div class="w-full flex justify-end mt-3">
        <div class="w-fit">
          <ButtonComp title="Search" colorType="color" @press="handleSearch" />
        </div>
      </div>
      </div>

      <!-- <div class="w-full bg-red-400">
        <DatePicker
          v-model="startingDate"
          :placeholder="placeholder"
          type="date"
        ></DatePicker>
      </div> -->
   
    </div>
  </div>
</template>

<script>
import ButtonComp from '../../ButtonComp'
import { mapGetters, mapActions } from 'vuex'
import { FormatUnixDate } from '../../../utils/candle'
import moment from 'moment/moment'

export default {
  name: 'TrainingModeData',
  components: {
    ButtonComp,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      startingDate: '2023-07-21',
      placeholder: '2023-07-21',
    }
  },
  methods: {
    ...mapActions(['updateTrainingModeState', 'setNewChartType']),
    async handleSearch() {
      const date = moment(this.startingDate)
      const formattedDate = date?.format('YYYY-MM-DD')
      const startingTime = new Date(formattedDate).getTime()
      const symbolDataLastIndex = this.getSymbolData.length - 1
      const symbolDatumElement = this.getSymbolData[symbolDataLastIndex][0]
      if (startingTime > symbolDatumElement) return

      let index = 0
      for (const dt of this.getSymbolData) {
        if (dt[0] > startingTime) break
        index++
      }

      const end = index + 1
      const trainingOffset = this.trainingOffset
      const trainingModeData = this.formattedSymbolData.slice(
        index - trainingOffset,
        end
      )

      const trainingModeDataRemaining = this.getSymbolData.slice(
        index + 1,
        this.getSymbolData.length
      )

      const trainingModeDataRaw = trainingModeData.map((candle) => [
        moment(candle.date).valueOf(),
        candle.open,
        candle.high,
        candle.low,
        candle.close,
        candle.volume,
        null,
        candle.value,
      ])
      const trainingModeDataRaw2 = trainingModeDataRemaining.map((candle) => [
        candle[0],
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ])
      const finalRawDataSet = [...trainingModeDataRaw, ...trainingModeDataRaw2]
      console.log(
        'last dataSlice',
        FormatUnixDate(finalRawDataSet[finalRawDataSet.length - 1][0]),
        finalRawDataSet.length,
        FormatUnixDate(this.getSymbolData[symbolDataLastIndex][0]),
        this.getSymbolData.length
      )

      console.log('training-mode', {
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })

      if (this.getChartType != 'Candles') {
        this.setNewChartType({ type: 'Candles', location: 'FromTrainingMode' })
      }

      this.updateTrainingModeState({
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })
      await this.$nextTick()
    },
  },
  computed: {
    ...mapGetters([
      'isTrainingMode',
      'dc',
      'getSymbolData',
      'formattedSymbolData',
      'trainingOffset',
      'getChartType',
    ]),
    today() {
      return Date.now()
    },
  },
}
</script>

<style scoped>

</style>

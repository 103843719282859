<template>
  <div class="p-4">
    <div
      class="h-[40rem] flex justify-center items-center"
      v-if="selectedButtonTypeName == 'ProDivergence Watch'"
    >
      <h1>Pro DivergenceWatch Setting still not Yet.</h1>
    </div>
    <div v-if="selectedButtonTypeName == 'Events'">
      <EventsFilter :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
  </div>
</template>
  
  <script>
import EventsFilter from './EventsFilter.vue'
import Filters from './Filters.vue'
export default {
  name: 'ProDivergenceWatch',
  data() {
    return {
      id: 'proDivergenceWatch',
    }
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  components: { Filters, EventsFilter },
  methods: {},
  computed: {},
  mounted() {},
}
</script>
  
  <style>
</style>
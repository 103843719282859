<template>
  <div
    class="w-full h-[100svh] flex flex-col justify-between space-y-1 bg-[#E0E3EB] relative !overflow-hidden"
  >
    <!-- Top Bar -->
    <div
      class="w-full h-[6vh] md:h-[43.2px] xxs:h-[60px] flex flex-row bg-white"
    >
      <Navbar
        @getTimeStamp="onHandleTimeStamp"
        @getSymbolData="onHandleSymbolData"
        @timeFrameEmit="onHandleTimeFrame"
        @sideBarToggle="sideBarToggle"
        @sidebarType="sidebarType"
        @addIndicator="addIndicator"
        @onMagnetPress="magToggle"
        @showNavDropdown="showNavDropdown"
        @openInterval="showNavDropdown"
        @openRangeList="showNavDropdown"
        @handleSelectChartType="ShowNavDropDownClose"
        @handleOpenComparingSymbols="openComparingSymbols"
        @onPressResistance="handleResistanceLine"
        @removeAllIndicators="handleRemoveAllIndicators"
        :showNavDropdownInDesktop="showNavDropdownInDesktop"
        :isMagnet="magnet"
        :isResistance="showResistanceLine"
        :variants="variants"
        :isRightBar="isRightBar"
        :selectedIntervalN="selectedInterval"
        :isOpenNvDropdown="isOpenNvDropdown"
        :IndicatorModalStateMulti="IndicatorModalStateMulti"
        :ScannerModalStateMulti="ScannerModalStateMulti"
        :isSettingOpenMulti="isSettingOpenMulti"
        :isLogout="isLogout"
        :isPatternBaseModel="isPatternBaseModel"
      ></Navbar>
    </div>
    <!-- Chart Container -->

    <!-- Loader is here  -->

    <div
      v-if="isloading"
      class="!absolute backdrop-blur-sm w-[100vw] h-[100vh] flex items-center justify-center"
      style="z-index: 9999"
    >
      <Loader></Loader>
    </div>

    <div
      :class="width >= 880 ? 'h-[95%]' : 'h-[95%]'"
      class="w-full flex flex-row -bg-green-400 justify-between relative xxs:space-x-0 md:space-x-1 !overflow-hidden"
    >
      <!-- Left Bar -->
      <div
        :class="width <= 880 ? 'hidden' : 'block'"
        class="w-[58px] h-full bg-orange-400"
      >
        <div
          v-if="showNavRight"
          :class="getTheme ? 'bg-[#1A1A1A]' : 'bg-white'"
          class="xxs:hidden md:flex w-[58px] h-full shrink-0"
        >
          <Leftbar :isMagnet="magnet"></Leftbar>
        </div>
      </div>

      <!-- Main Chart -->

      <div
        :class="width <= 880 ? 'w-full' : 'w-[84%] qm:w-[90%] md:w-[95%]'"
        class="h-full flex !justify-between space-x-1 -bg-slate-400"
      >
        <!-- TradingVue -->
        <div
          :width="cbox_width"
          :height="cbox_height"
          :class="isRightBar && width >= 880 ? '!w-[83%]' : '!w-[100%]'"
          class="flex flex-col gap-[4px] justify-between xxs:h-[100%] md:h-[100%] items-center xxs:self-start -bg-green-200"
        >
          <div
            v-if="errorMessage"
            class="w-full h-full dark:text-white -bg-white text-[16px] font-medium flex xxs:flex-col xxs:gap-4 md:flex-row md:gap-2 !items-center !justify-center"
          >
            <span> Failed to fetch chart data. </span>
            <span
              @click="updateSymbol(symbolName)"
              class="hover:underline cursor-pointer text-green-600"
            >
              Please try again later.</span
            >
          </div>

          <div
            v-else
            style="position: relative"
            ref="tradingHw"
            class="w-full shrink-1 -bg-green-800/60"
            :class="
              this.getChartCursor.icon == 'default'
                ? 'cursor-default'
                : 'cursor-crosshair'
            "
          >
            <trading-vue
              v-if="!isloading"
              id="newData"
              ref="tvjs"
              @ChartLoad="ChartLoad"
              :applyShaders="true"
              :enableZoom="true"
              :timezone="0"
              :index-based="true"
              :data="chart2"
              :titleTxt="stockTitle"
              :overlays="overlays"
              :width="cbox_width"
              :height="cbox_height"
              :color-back="colors.colorBack"
              :color-grid="colors.colorGrid"
              @grid-mousedown="onmousedown"
              :color-text="colors.colorText"
              :colorVolUp="candleVolumeUpD"
              :colorVolDw="candleVolumeDwD"
              :colorCandleUp="candleColorUpD"
              :colorCandleDw="candleColorDwD"
              :gridLines="true"
              :colorTitle="titleColor"
              :enableArrow="false"
              :enableCrosshair="enableCrosshair"
              :waterMarkText="waterMarkText"
              :magnet="magnet"
              @darkMode="darkMode"
              :colorGrid="
                isDark
                  ? '#292929'
                  : `${variants?.sixthVariant ? '#686868' : '#efefef'}`
              "
              :showGridYLine="true"
              :isDark="isDark"
              :colorCross="crossColor"
              :candleBorder="candleBorder"
              :firstVariant="variants.firstVariant"
              :secondVariant="variants.secondVariant"
              :thirdVariant="variants.thirdVariant"
              :fourthVariant="variants.fourthVariant"
              :fifthVariant="variants.fifthVariant"
              :extensions="extensions"
              :sixthVariant="variants.sixthVariant"
              :colorPanel="hoverYBox"
              :colorTextHL="hoverYText"
              @showMagnetOnChart="toggleMagnet"
              @sideBarToggle="sideBarToggle"
              :isRightBar="isRightBar"
              :isRightBarToggle="isRightBarToggle"
              :isArrowToggle="isArrowToggle"
              :colors="colorObjectProps"
              @resetChart="resetChart"
            >
            </trading-vue>
          </div>
        </div>

        <!-- Right Side Bar -->

        <div
          v-if="isRightBar"
          :class="[
            isRightBar && width > 880
              ? 'animate-[slide-in-left_0.8s_ease-in] transition'
              : 'animate-[slide-in-right_0.8s_ease-in] transition',
            width < 880 ? 'hidden' : 'flex',
          ]"
          class="h-full -4xl:!w-[424px] 2xl:!w-[400px] -!w-[35rem] !shrink-0 dark:bg-[#1A1A1A] !transition-all z-[900]"
        >
          <RightSideBarVue
            @openAddSymbolModal="handleOpenWatchlist"
            @openEditWatchlist="handleOpenEditWatchlist"
            @openRemoveWatchlist="handleOpenRemoveWatchlist"
            @openDownloadSymbol="handleOpenDownloadSymbol"
            @openRemoveSymbolModal="handleOpenRemoveSymbol"
            @updateChart="onHandleSymbolData"
            @handleAddWatchlist="openAddWatchlistModal"
            @openWatchlistDetail="openWatchlistModalDetail"
            @openComparingSymbols="openComparingSymbols"
            :selectedRight="selectedRight"
            :wHeight="height"
          />
        </div>
      </div>

      <!-- Right Bar Update-->
      <div
        :class="width <= 880 ? 'hidden' : 'block'"
        class="w-[58px] h-full -bg-orange-400"
      >
        <div
          class="xxs:hidden md:flex w-[58px] h-full dark:!bg-[#1A1A1A] bg-white justify-center !shrink-0 !transition-all"
        >
          <RightBarUpdate
            @sideBarToggle="sideBarToggle"
            :selectedRight="selectedRight"
            @openScannerModal="showScannerToggle"
          />
        </div>
      </div>

      <!-- Right side bar show when width < 880 -->

      <!-- <div
        v-if="isRightBar"
        :class="[
          isRightBar && width > 880
            ? 'animate-[slide-in-left_0.8s_ease-in] transition'
            : 'animate-[slide-in-right_0.8s_ease-in] transition',
          width > 880 ? 'hidden' : 'flex',
          width < 880 ? '!right-[-1px]' : 'right-[60px]',
        ]"
        class="xxs:w-[260px] xs:w-[375px] sm:w-[424px] -!w-[35rem] sm:right-[55px] md:border-x-[2px] xxs:!border-l-[2px] -border-green-400 absolute !shrink-0 xxs:h-[100%] overflow-hidden dark:bg-[#1A1A1A] !transition-all z-[900]"
      >
        <RightSideBarVue
          @openAddSymbolModal="handleOpenWatchlist"
          @openEditWatchlist="handleOpenEditWatchlist"
          @openRemoveWatchlist="handleOpenRemoveWatchlist"
          @openDownloadSymbol="handleOpenDownloadSymbol"
          @openRemoveSymbolModal="handleOpenRemoveSymbol"
          @updateChart="onHandleSymbolData"
          @handleAddWatchlist="openAddWatchlistModal"
          @openWatchlistDetail="openWatchlistModalDetail"
          :selectedRight="selectedRight"
        />
      </div> -->
    </div>

    <!-- Mobile Footer -->
    <div
      :class="width <= 880 ? 'flex' : 'hidden'"
      class="w-full h-[4.5rem] flex-row -bg-red-400"
    >
      <div
        class="flex w-[100%] -sticky -absolute h-[4.5rem] bg-white -bg-slate-400"
      >
        <div
          class="w-[85%] -shadow-[offset -10px 0px 15px -5px rgba(0,0,0,0.3), 10px 0px 15px -5px rgba(0,0,0,0.3)] -px-4 flex overflow-y-hidden overflow-x-scroll no-scrollbar"
        >
          <span
            class="bg-slate-100 sticky left-0 flex justify-center items-center w-auto h-full z-[100]"
          >
            <svg
              fill="#000000"
              width="20px"
              height="20px"
              viewBox="0 0 256 256"
              id="Flat"
              xmlns="http://www.w3.org/2000/svg"
              transform="matrix(1, 0, 0, -1, 0, 0)"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M160,212a3.98805,3.98805,0,0,1-2.82861-1.17163l-80-80a4,4,0,0,1,0-5.65674l80-80a4.00009,4.00009,0,0,1,5.65722,5.65674L85.65674,128l77.17187,77.17163A4,4,0,0,1,160,212Z"
                ></path>
              </g>
            </svg>
          </span>

          <!-- Leftbar is using as bottom bar -->

          <div
            v-if="showNavRight"
            :class="getTheme ? 'bg-[#1A1A1A]' : 'bg-[#FFFFFF]'"
            class="-!min-w-[600px] min-h-[5rem] max-h-[5rem] -bg-green-500 flex justify-center items-center !shrink-0"
          >
            <Leftbar :isMagnet="magnet"></Leftbar>
          </div>
          <span
            class="-bg-black sticky right-0 flex justify-center items-center w-auto h-full bg-slate-100 z-[100]"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="matrix(-1, 0, 0, -1, 0, 0)"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M14.071 5L7.70708 11.364C7.31656 11.7545 7.31656 12.3877 7.70708 12.7782L14.071 19.1421"
                  stroke="#000000"
                  stroke-linecap="round"
                ></path>
              </g>
            </svg>
          </span>
        </div>

        <!-- Menu Btn -->
        <div
          class="min-w-[130px] max-w-[130px] min-h-full !shrink-0 flex justify-center items-center"
        >
          <div
            v-if="isRightBar == false"
            @click="showNavDropdown('navmenu')"
            class="!min-w-[90%] min-h-[95%] sticky bg-btnActiveLite hover:!bg-appBtnHover gap-2 cursor-pointer flex justify-center rounded-[4px] items-center"
          >
            <span
              class="min-w-[2.1rem] text-BtnActiveTxtLite max-w[2.1rem] -h-[3rem] flex justify-center items-center"
            >
              <svg
                class="text-appBtnTxt"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="text-[16px] text-BtnActiveTxtLite tracking-wider">
              MENU
            </span>
          </div>
          <div
            v-if="isRightBar == true"
            @click="hideRightbar()"
            class="!min-w-[90%] min-h-[95%] sticky bg-btnActiveLite hover:!bg-appBtnHover gap-2 cursor-pointer flex justify-center rounded-[4px] items-center"
          >
            <span
              class="min-w-[2.1rem] max-w[2.1rem] !text-BtnActiveTxtLite -h-[3rem] flex justify-center items-center"
            >
              <svg
                data-slot="icon"
                fill="none"
                stroke-width="2"
                stroke="black"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
            <span class="text-[16px] text-BtnActiveTxtLite tracking-wider">
              MENU
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- right bar showing then screenwidth is lessthen 880px -->

    <div
      v-if="isRightBar"
      :class="[width > 880 ? 'hidden' : 'flex']"
      class="w-full h-[90vh] md:border-x-[2px] xxs:!border-l-[2px] -border-green-400 absolute !shrink-0 xxs:h-[100%] !overflow-hidden dark:bg-[#1A1A1A] !transition-all top-[-1px] z-[900]"
    >
      <RightSideBarVue
        @openAddSymbolModal="handleOpenWatchlist"
        @openEditWatchlist="handleOpenEditWatchlist"
        @openRemoveWatchlist="handleOpenRemoveWatchlist"
        @openDownloadSymbol="handleOpenDownloadSymbol"
        @openRemoveSymbolModal="handleOpenRemoveSymbol"
        @updateChart="onHandleSymbolData"
        @handleAddWatchlist="openAddWatchlistModal"
        @openWatchlistDetail="openWatchlistModalDetail"
        :selectedRight="selectedRight"
      />
    </div>

    <!-- Mobile Nav Dropdowns -->
    <div
      v-if="showNavDropdownInMobile && width < 600"
      class="w-full !h-full top-0 absolute z-[1000] bg-white"
    >
      <ShowNavDropDown
        @ShowNavDropDownClose="ShowNavDropDownClose"
        @handleSelectInterval="onHandleTimeFrame"
        @openRightBarFromMob="openRightBarFromMob"
        :btnName="btnName"
        @closeRange="ShowNavDropDownClose"
      />
    </div>

    <!-- Setting Modal -->
    <div
      v-if="openIndicatorSetting"
      class="absolute flex items-center justify-center -bg-red-200 w-[100vw] h-[100vh]"
      style="z-index: 999"
    >
      <IndicatorSettingModal
        @close="handleSettingModalClose"
        @settingChanged="handleSettingChanged"
      />
    </div>

    

    <!--NewSettings Modal-->
    <div v-if="isSettingOpen" >
      <BaseModal>
        <NewSettingModal @closeModal="isSettingOpen = false" />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import TradingVue, { DataCube } from './@trading-vue-js/src/index'
// import data from './data_aapl.json'
import moment from 'moment'
import Navbar from './component/Navbar.vue'
import ChartEventNew, { GetTimeRange } from './utils/chartEvent.js'
import IndicatorSettingModal from './component/IndicatorSettingModal.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from './component/Loading.vue'
import AddWatchlistSymbolModal from './component/AddWatchlistSymbolModal.vue'
import EditWatchlistModal from './component/EditWatchlistModal.vue'
import RemoveWatchlistModal from './component/RemoveWatchlistModal.vue'
import RemoveSymbolModal from './component/RemoveSymbolModal.vue'
import DownloadSymbolModal from './component/DownloadSymbolModal.vue'
import BaseModal from './component/BaseModal.vue'
import Leftbar from './component/Leftbar.vue'
import RightBarUpdate from './component/RightBarUpdate.vue'
import Overlays from './overlays'
import Minimize from './component/Minimize.vue'
import { namespaces } from 'd3'
import RightSideBarVue from './component/RightSideBar.vue'
import WatchlistModalTv from './component/WatchlistModalTv.vue'
import { variantColors } from './utils/chartSetting'
import { getUserData } from './services/user'
import { IndicatorSettingsDefaults } from './component/dialogBoxes/helper/GeneralSettingsDefaults'
import { chartEvents } from './chartEvents'
import ScannerUi from './component/ScannerUi.vue'
import Extensions from './extensions'
import { getTimeCalculation } from './utils/timeCalculation'
import { GetIterationData } from './settings/TimeRangeConfig'
import { getDrawingDataV1 } from './services/drawing'
import symbol from './services/symbol'
import ChartAdding from './component/ChartAdding.js'
import ShowNavDropDown from './component/navDropDowns/ShowNavDropDown.vue'
import NewSettingModal from './component/NewSettingModal.vue'
import ComparingSymbols from './component/dialogBoxes/ComparingSymbols.vue'
// signal r client
import * as signalR from '@microsoft/signalr'
import Config from './config'
// import store from '@/store'
// import NewSettingModal from './component/NewSettingModal.vue'

export function parseToChartCandleData(data) {
  return data
}

export function parseToChartCandleDataF(data) {
  const timeFrameMap = []
  const dataMap = data.map((candle) => {
    let timeStamp = moment(candle.date).valueOf()
    timeFrameMap.push([timeStamp])
    return [
      timeStamp,
      candle.open,
      candle.high,
      candle.low,
      candle.close,
      candle.volume,
    ]
  })
  return [dataMap, timeFrameMap]
}

export default {
  name: 'SMEChart',
  mixins: [ChartAdding],
  description: 'All charts must work correctly and independently',
  components: {
    TradingVue,
    Navbar,
    Loader,
    Leftbar,
    IndicatorSettingModal,
    RightSideBarVue,
    Minimize,
    BaseModal,
    AddWatchlistSymbolModal,
    EditWatchlistModal,
    RemoveWatchlistModal,
    DownloadSymbolModal,
    RemoveSymbolModal,
    RightBarUpdate,
    WatchlistModalTv,
    ScannerUi,
    ShowNavDropDown,
    NewSettingModal,
  },
  data() {
    return {
      isShowScanner: true,
      isVariantChange: false,
      isSettingOpen: false,
      selectedRight: 'watchlist',
      isAddModal: false,
      selectedWatchlistId: null,
      extensions: Extensions,
      hoverYBox: '#0D66CE',
      hoverYText: '#ffffff',
      openAddWatchlist: false,
      openRemoveWatchlist: false,
      openDeleteWatchlistSymbolModal: false,
      openDownloadModal: false,
      deleteWatchlistId: null,
      deleteWatchlistName: null,
      deleteWatchlistSymbol: {},
      openEditWatchlist: false,
      showResistanceLine: false,
      showFirstTimeResistanceLine: false,
      isWatchlistModalDetail: false,
      crossColor: '#6C757D',
      customIndicator: [],

      isResizing: false,
      leftWidth: 77, // Initial percentage width of the left pane
      rightWidth: 23, // Initial percentage width of the right pane
      containerWidth: 0,

      chartSetting: [],
      errorMessage: false,
      showNavRight: true,
      firstVariant: false,
      secondVariant: false,
      thirdVariant: false,
      fourthVariant: false,
      variants: {
        firstVariant: false,
        secondVariant: false,
        thirdVariant: false,
        fourthVariant: true,
        fifthVariant: false,
        sixthVariant: false,
      },
      selectedVariantName: 'standardBR',
      waterMarkText: 'incometradercharts.com',
      enableCrosshair: true,
      candleBorder: true,
      magnet: true,
      isDark: false,
      volume: null,
      addOffChart: '',
      offChartList: [],
      onChartList: [],
      tempResponse: null,
      stockTitle: 'New Chart',
      search: '',
      isOld: false,
      flagsData: '',
      excode: 'NSD',
      excodeList: [],
      date: '2023-09-07',
      charts: [],
      flagList: [],
      supportLines: [],
      overlays: Object.values(Overlays),
      width: window.innerWidth,
      height: window.innerHeight,

      colorsVolume: {
        colorUpVol: '#16B195',
        colorUpDow: '#F23645',
      },
      timeFrame: 'D',
      isloading: true,
      colorTitle: {
        title: 'green',
      },
      myWidth: null,
      dropdownOpen: false,
      supportData: [],
      resistanceData: [],
      srVal: true,

      selectedDate: '1',
      selectRangeVal: '1Y',
      rangeDate: null,
      currDate: moment().format('YYYY-MM-DD'),
      candleData: [],
      symbolName: 'AAPL',
      candleColorUpD: '#0D66CE',
      candleColorDwD: '#F23645',
      candleVolumeUpD: '#0D66CE',
      candleVolumeDwD: '#F23645',
      openIndicatorSetting: false,
      isPageReload: false,
      currentSetting: {},
      isRightBar: true,
      isArrowToggle: false,
      isRightBarToggle: false,
      showNavDropdownInMobile: false,
      showNavDropdownInDesktop: false,
      btnName: 'range',
      selectedInterval: 'D',
      selectedRangeVal: '1Y',
      isOpenNvDropdown: false,
      IndicatorModalStateMulti: false,
      ScannerModalStateMulti: false,
      isSettingOpenMulti: false,
      isLogout: false,
      isPatternBaseModel: false,
    }
  },

  methods: {
    ...mapActions([
      'updateOhlcData',
      'updateSymbolName',
      'toggleIndicator',
      'updateIndicatorList',
      'updateRemoveIndicatorName',
      'getSymbolList',
      'updateSymbolDetail',
      'updateTheme',
      'updateProfileData',
      'getUserWatches',
      'getUserScanList',
      'deleteAllDrawing',
      'deleteDrawing',
      'updateSymbolPrice',
      'setClientToken',
      'setConnection',
      'updateIndicator',
    ]),

    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    async initializeSignalR() {
      console.log("initialSignalR==>")
      try {
        const clientToken = this.getUserId // Replace with the actual token
        this.setClientToken(clientToken)
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${Config.SocketHub}?clientToken=${clientToken}`)
          .configureLogging(signalR.LogLevel.Trace)
          .build()
        this.setConnection(connection)
        connection.on('ReceiveProcessStatus', (status) => {
          // console.log("status response==>",status?.response)
          console.log(
            'Process status:',
            status,
            status?.processId,
            status?.processSymbolCount,
            status?.symbolCount,
            status?.response
          )
        })
        await connection.start()
      } catch (e) {
        console.log('Error in socket', e)
      }
    },
    // openRightBarFromMob(name) {
    //   this.showNavDropdownInMobile = false
    //   if (name === 'comparingSymbols') {
    //     this.isRightBar = true
    //     this.selectedRight = 'comparingSymbols'
    //   } else if (name === 'watchlist') {
    //     this.isRightBar = true
    //     this.selectedRight = 'watchlist'
    //   } else if (name === 'scannerResults') {
    //     this.isRightBar = true
    //     this.selectedRight = 'scannerResults'
    //   } else if (name === 'trainingMode') {
    //     this.isRightBar = true
    //     this.selectedRight = 'trainingMode'
    //   } else if (name === 'indicator') {
    //     this.IndicatorModalStateMulti = true
    //   } else if (name === 'scanner') {
    //     this.ScannerModalStateMulti = true
    //     console.log('ScannerModalStateMulti', this.ScannerModalStateMulti, name)
    //   } else if (name === 'settings') {
    //     this.isSettingOpenMulti = true
    //   } else if (name === 'logout') {
    //     this.isLogout = true
    //   }
    //   console.log('openRightBarFromMobMul', name)
    // },

    openComparingSymbols(name) {
      this.$emit('openComparingSymbols', name)
      this.isRightBar = true
      this.selectedRight = 'comparingSymbols'
    },

    isWindowWidth() {
      if (this.width < 880) {
        this.isRightBar = false
        console.log('isRightBarC', this.isRightBar)
      }
    },

    // ShowNavDropDownClose() {
    //   this.showNavDropdownInMobile = false
    //   this.showNavDropdownInDesktop = false
    //   console.log('showNavDropdownInMobile', this.showNavDropdownInMobile)
    // },
    hideRightbar() {
      this.isRightBar = false
    },
    newSettings(val) {
      console.log('newSettingsVal', val)
      this.showNavDropdownInMobile = false
      if (val == 'settings') {
        this.isSettingOpen = true
      } else if (val === 'comparingSymbols') {
        this.isRightBar = true
        this.selectedRight = 'comparingSymbols'
      } else if (val === 'watchlist') {
        this.isRightBar = true
        this.selectedRight = 'watchlist'
      } else if (val === 'scannerResults') {
        this.isRightBar = true
        this.selectedRight = 'scannerResults'
      } else if (val === 'trainingMode') {
        this.isRightBar = true
        this.selectedRight = 'trainingMode'
      } else if (val === 'indicator') {
        this.IndicatorModalStateMulti = true
      } else if (val === 'scanner') {
        this.ScannerModalStateMulti = true
        console.log('ScannerModalStateMulti', this.ScannerModalStateMulti, name)
      } else if (val === 'settings') {
        this.isSettingOpenMulti = true
      } else if (val === 'logout') {
        this.isLogout = true
      }
      console.log('openRightBarFromMobMul', name)
    },
    openDropdownMenu(btnName) {
      this.btnName = btn
      console.log('btnNamenavmenu', btnName)
      this.isOpenNvDropdown = !this.isOpenNvDropdown
      console.log('openDropdown', this.isOpenNvDropdown)
    },
    // showNavDropdown(payload) {
    //   console.log('payload===>', payload)
    //   if (this.width <= 600) {
    //     console.log('mobile payload', payload)
    //     this.showNavDropdownInDesktop = false
    //     this.showNavDropdownInMobile = !this.showNavDropdownInMobile
    //     this.btnName = payload
    //     console.log('showNavDropdown1', this.showNavDropdownInMobile, payload)
    //   } else {
    //     console.log('desktop payload', payload)
    //     this.showNavDropdownInMobile = false
    //     this.showNavDropdownInDesktop = !this.showNavDropdownInDesktop
    //     console.log('showNavDropdown2', this.showNavDropdownInDesktop)
    //   }
    // },

    ShowNavDropDownClose() {
      this.showNavDropdownInMobile = false
      this.showNavDropdownInDesktop = false
      console.log('showNavDropdownInMobile', this.showNavDropdownInMobile)
    },
    showNavDropdown(payload) {
      if (this.width <= 600) {
        this.showNavDropdownInMobile = !this.showNavDropdownInMobile
        this.btnName = payload
        console.log('showNavDropdown1', this.showNavDropdownInMobile, payload)
      } else {
        console.log('desktop payload', payload)
        this.showNavDropdownInMobile = false
        this.showNavDropdownInDesktop = !this.showNavDropdownInDesktop
        console.log('showNavDropdown2', this.showNavDropdownInDesktop)
      }
    },
    onmousedown() {
      if (!this.isCtrlPressed) return

      let cursorDecide = this.$refs?.tvjs?.getCursor()
      if (cursorDecide?.t) {
        let time = cursorDecide.t
        let index = cursorDecide.i

        const SET_CANDLE_INDEX_UPD = 'SET_CANDLE_INDEX_UPD'
        const SET_CANDLE_INDEX_UPD_TRAINING = 'SET_CANDLE_INDEX_UPD_TRAINING'
        const trainingModeDataLength = this.trainingModeDataRaw?.length
        const actualDataLength = this.formattedSymbolData?.length
        let checkIfEmitChartChange = this.isTrainingMode
          ? index >= 0 && index <= trainingModeDataLength
          : index >= 0 && index <= actualDataLength
        if (checkIfEmitChartChange)
          this.$store.commit(
            this.isTrainingMode
              ? SET_CANDLE_INDEX_UPD_TRAINING
              : SET_CANDLE_INDEX_UPD,
            { time, index, chartHit: true }
          )
      }
    },
    resetChart() {
      this.$refs.tvjs.resetChart()
    },
    showScannerToggle() {
      this.isShowScanner = !this.isShowScanner
    },
    openWatchlistModalDetail() {
      this.isWatchlistModalDetail = true
    },
    closeWatchlistModalDetail() {
      this.isWatchlistModalDetail = false
    },

    startResize() {
      this.isResizing = true
      window.addEventListener('mousemove', this.resize)
      window.addEventListener('mouseup', this.stopResize)
      this.containerWidth = this.$refs.outerContainer.offsetWidth
    },
    resize(event) {
      if (!this.isResizing) return

      const mouseX = event.clientX - this.$refs.outerContainer.offsetLeft
      const newLeftWidth = (mouseX / this.containerWidth) * 100

      // Enforce the constraints
      if (newLeftWidth >= 70 && newLeftWidth <= 80) {
        this.leftWidth = newLeftWidth
        this.rightWidth = 100 - newLeftWidth
      }
    },
    stopResize() {
      this.isResizing = false
      window.removeEventListener('mousemove', this.resize)
      window.removeEventListener('mouseup', this.stopResize)
    },

    handleCloseWatchlistModal() {
      this.openAddWatchlist = false
    },
    ChartLoad() {
      this.$store.commit('SET_DATA_CUBE', { dc: this.$refs.tvjs.data })
      this.$store.commit('SET_TRADING_CHART_REFERENCE', {
        tradingChartReference: this.$refs.tvjs,
      })
    },
    handleCloseEditWatchlistModal() {
      this.isAddModal = false
      this.openEditWatchlist = false
    },
    handleCloseRemoveWatchlistModal() {
      this.openRemoveWatchlist = false
    },
    handleCloseSymbolModal() {
      this.openDeleteWatchlistSymbolModal = false
    },
    handleCloseDownloadSymbolModal() {
      this.openDownloadModal = false
    },
    handleKeyDownClose(event) {
      if (event.key === 'Escape') {
        this.handleCloseDownloadSymbolModal()
        this.handleCloseSymbolModal()
        this.handleCloseRemoveWatchlistModal()
        this.handleCloseEditWatchlistModal()
        this.handleCloseWatchlistModal()
      }
    },
    handleOpenWatchlist(id) {
      this.selectedWatchlistId = id
      this.openAddWatchlist = true
    },
    handleOpenRemoveSymbol(item) {
      this.deleteWatchlistSymbol = item
      this.openDeleteWatchlistSymbolModal = true
    },
    openAddWatchlistModal() {
      this.isAddModal = true
      this.openEditWatchlist = true
    },
    handleOpenEditWatchlist(item) {
      this.editData = item
      this.openEditWatchlist = true
    },
    handleOpenRemoveWatchlist(item) {
      this.deleteWatchlistId = item.id
      this.deleteWatchlistName = item.watchlistName
      this.openRemoveWatchlist = true
    },
    handleOpenDownloadSymbol(item) {
      this.openDownloadModal = true
      this.symbolData = item
    },

    sideBarToggle(val) {
      if (window.innerWidth > 880) {
        if (this.selectedRight == val) {
          this.isRightBar = !this.isRightBar
        } else {
          this.selectedRight = val
        }
      } else {
        this.isRightBar = !this.isRightBar
      }
    },

    sidebarType(type) {
      if (window.innerWidth < 880) {
        this.selectedRight = type
        this.isRightBar = true
        console.log(
          'isRightBarSidebarType',
          this.isRightBar,
          this.selectedRight
        )
      }
    },
    onHandleTimeFrame(t) {
      console.log('onHandleTimeFrame', t)
      this.selectedInterval = t
      this.timeFrame = t
      this.updateSymbol(this.symbolName)
    },

    getValue(name) {
      if (name !== 'Variant') {
        const setting = this.chartSetting?.find(
          (item) => item?.name === name && item?.name !== 'Variant'
        )
        return setting ? setting.defaultValue : null
      } else {
        const setting = this.chartSetting?.find((item) => item?.name === name)
        return setting.variantList || null
      }
    },
    onHandleSymbolData(payload) {
      if (payload) {
        localStorage.setItem('symbolName', payload)
        this.symbolName = payload
        this.updateSymbolName(payload)
        this.updateSymbol(payload)
      }
    },
    onHandleRefresh() {
      this.$refs.tvjs.resetChart()
    },
    onHandleTimeStamp(payload) {
      // chartEvents.$emit('setChartRange', undefined, 'ScannedSymbolSelect')
      this.setChartRange(undefined, 'ScannedSymbolSelect')

      // console.log('onHandleTimeStamp', payload)
      // this.selectedRangeVal = payload
      // let [startingTime, endingTime] = GetTimeRange(
      //   this.candleData,
      //   payload,
      //   this.timeFrame
      // )
      // this.$refs.tvjs?.setRange(startingTime, endingTime)

      // // Number of milliseconds in one day
      // const oneDay = 24 * 60 * 60 * 1000;

      // // Adding 3 days to the given timestamp
      // const newTime = endingTime + (3 * oneDay);

      // setTimeout(() => {
      // this.$refs.tvjs?.setRange(startingTime, newTime)

      //   this.onHandleRefresh()
      // }, 2000);
    },
    getFullDate(month) {
      const date = moment()
      const selectedMonth = parseInt(month)
      const getDate = date.subtract(selectedMonth, 'months')
      const result = getDate.format('YYYY-MM-DD')
      return result
    },
    getRangeData() {
      this.rangeDate = this.getFullDate(this.selectedDate)
      this.updateSymbol(this.symbolName)
    },

    oldDataToggle(event) {
      this.isOld = event.target.checked
      this.updateSymbol(this.symbolName)
    },
    dropFunc() {
      const opt = document.querySelector('.dropdown').value
    },
    magToggle() {
      this.magnet = !this.magnet
    },
    srToggle(event) {
      this.srVal = event.target.checked
      this.updateSymbol(this.symbolName)
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    toggleVariant(variant) {
      this.firstVariant = false
      this.secondVariant = false
      this.thirdVariant = false
      this.fourthVariant = false
    },
    darkMode() {
      return this.isDark
    },
    searchData() {
      this.updateSymbol(this.search)
    },
    toggleMagnet() {
      this.magnet = !this.magnet
    },
    // macdIndicator() {
    //   const data = this.tempResponse?.data
    //   const macd = data?.macd

    //   let signalLine = macd && macdData?.signalLine
    //   let histogram = macd?.histogram
    //   let macdLine = macd?.macdLine
    //   let crossOverMACD = macd?.crossOverMACD

    //   let finalOutput = []
    //   for (let i = 0; i < histogram?.length; i++) {
    //     const timeStamp = histogram[i][0]
    //     const flag = crossOverMACD?.find((d) => d[0] === timeStamp)
    //     const flagValue = flag ? flag[1] : 0

    //     finalOutput.push([
    //       timeStamp,
    //       histogram[i][1],
    //       macdLine[i][1],
    //       signalLine[i][1],
    //       flagValue,
    //     ])
    //   }

    //   return { finalOutput, crossOverMACD }
    // },
    // covalIndicator() {
    //   let data = this.tempResponse?.data
    //   const covalData = data?.covalData
    //   const crossOverFill = data?.crossOverFill

    //   const covalDataMain =
    //     covalData && covalData.length > 0
    //       ? parseToChartCandleData(covalData[0])
    //       : []
    //   const crossOverFillMain = parseToChartCandleData(crossOverFill)

    //   let covalDataArray = []

    //   for (let i = 0; i < covalDataMain.length; i++) {
    //     const timeStamp = covalDataMain[i][0]
    //     const flag = crossOverFillMain.find((d) => d[0] === timeStamp)
    //     const flagValue = flag ? flag[1] : 0

    //     covalDataArray.push([...covalDataMain[i], flagValue])
    //   }

    //   return { covalDataMain, covalDataArray }
    // },

    addWhenSymbolChange(indicator) {
      let type = indicator?.name
      let proto = this.overlays?.find((x) => x.name === type)
      if (proto && proto?.methods?.meta_info) {
        let meta = proto?.methods?.meta_info()
        let side = meta.preset?.side || 'onchart'
        const query = `${side}.${type}`
        let chartData = this.charts?.get(query)

        for (const data of chartData) {
          this.charts.del(data?.name)
        }
        let oveylay_tv = {
          type,
          name: meta?.preset?.name,
          desc: meta?.desc,
          settings: meta.preset.settings,
          tf: '1D',
          settingsLabelMap: meta?.preset?.settingsLabelMap
            ? meta.preset.settingsLabelMap
            : {},
          side,
          data: [],
        }
        setTimeout(() => {
          let vol
          if (side == 'offchart') {
            vol = this.charts.data.offchart.find(
              (item) => item?.name == 'Volume'
            )
            this.charts.data.offchart = this.charts.data.offchart.filter(
              (item) => item?.name != 'Volume'
            )
          }

          let id = this.charts.add(side, oveylay_tv)
          if (side == 'offchart') {
            let res = this.charts?.add(side, vol)
          }
        })
      }
    },

    handleRemoveAllIndicators() {
      console.log('remove all indicators', this.getSelectedIndicators)
      this.getSelectedIndicators.forEach((indicator) => {
        this.handleRemoveIndicator(indicator?.name)
      })
      this.updateIndicatorList([])
    },

    addIndicator(indicator) {
      let type = indicator?.name
      let proto = this.overlays?.find((x) => x.name === type)
      if (proto && proto?.methods?.meta_info) {
        let meta = proto?.methods?.meta_info()
        let side =
          indicator?.name === 'CovalData'
            ? 'offchart'
            : meta.preset?.side || 'onchart'
        const query = `${side}.${type}`
        let chartData = this.charts?.get(query)

        for (const data of chartData) {
          this.charts.del(data?.name)
          return
        }

        let oveylay_tv = {
          type,
          name: meta?.preset?.name,
          desc: meta?.desc,
          settings: meta.preset.settings,
          tf: '1D',
          settingsLabelMap: meta?.preset?.settingsLabelMap
            ? meta.preset.settingsLabelMap
            : {},
          side,
          data: [],
        }

        let vol
        if (side == 'offchart') {
          vol = this.charts.data.offchart.find((item) => item?.name == 'Volume')
          this.charts.data.offchart = this.charts.data.offchart.filter(
            (item) => item?.name != 'Volume'
          )
        }

        let id = this.charts.add(side, oveylay_tv)
        if (side == 'offchart') {
          let res = this.charts?.add(side, vol)
        }
        this.addingChartDrawings({ side, type }, this)
      }
    },

    handleResistanceLine() {
      this.showResistanceLine = !this.showResistanceLine
      if (this.showResistanceLine) {
        this.addResistanceLine()
      } else {
        this.removeResistanceLine()
      }
    },
    getSupportLines(data, type = 'Tradeline', settings = {}) {
      return {
        name: 'support',
        type: type,
        indexBased: false,
        data: data,
        settings: settings,
      }
    },
    addResistanceLine() {
      let data = this.tempResponse?.data
      this.supportData = data?.supportData
      this.resistanceData = data?.resistanceData
      let supportLineSettings = {
        'z-index': 5,
        legend: false,
      }
      if (this.supportData) {
        let overlay = this.getSupportLines(this.supportData, 'Tradeline', {
          ...supportLineSettings,
          color: 'red',
        })
        this.charts.add('onchart', overlay)
      }

      if (this.resistanceData) {
        let overlay = this.getSupportLines(this.resistanceData, 'Tradeline', {
          ...supportLineSettings,
          color: 'green',
        })
        this.charts.add('onchart', overlay)
      }
    },
    removeResistanceLine() {
      this.charts.del('support')
    },
    onResize(event) {
      const Height_widht = window.location.search
      const new_Height_widht = new URLSearchParams(Height_widht)
      const my_width = new_Height_widht.get('width')
      const my_height = new_Height_widht.get('height')
      this.width = my_width ? my_width : window.innerWidth
      this.height = my_height ? my_height : window.innerHeight - 42
      this.width < 900
        ? (this.isArrowToggle = true)
        : (this.isArrowToggle = false)
    },

    getChartResponse: function (data) {
      let candleParse = data
      this.updateOhlcData(candleParse)
      this.candleData = candleParse

      let charts = {
        ohlcv: candleParse,
        type: this.getChartType,
        tf: this.timeFrame,
      }

      this.ohlcv = candleParse

      this.offChartList = this.offChartList.filter((i) => i.name !== 'Volume')

      this.offChartList.push({
        name: 'Volume',
        type: 'VolumePrimary',
        data: data,
        settings: {
          legend: true,
          grid: {
            borderColor: '#111',
            strokeDashArray: 7,
          },
        },
      })

      charts['onchart'] = this.onChartList
      charts['offchart'] = this.offChartList
      return charts
    },

    deleteAllOnchartList() {
      let oncharts = this.charts?.get('onchart')
      let updatedArr = oncharts?.filter(
        (item) => item.name != 'Arrow' && item.name != 'support'
      )
      updatedArr?.map((item) => this.charts.del(item))
    },

    async onDeleteButtonClick() {
      const selectedTool = this.charts.tv.data.data.selected
      if (!selectedTool) {
        await this.deleteAllTools()
      } else {
        let res = await this.deleteDrawing({ t: selectedTool, e: 'ChartUpd' })
        if (res) {
          this.tv.custom_event({
            event: 'remove-tool',
            args: [0, 0, selectedTool],
          })
          this.charts.del(selectedTool)
        }
      }
    },
    async deleteAllTools() {
      if (await this.deleteAllDrawing()) {
        this.deleteAllChartTools()
      }
    },

    setChartRange(range, event, callback) {
      console.log('range', range)
      console.log('set range call')
      const $store = this.$store
      const state = $store.state
      let rangeObj = {
        range: range ? range : state.chartVisibleRange,
        start: null,
        end: null,
      }

      let isRangeChange = rangeObj.range !== state.chartVisibleRange
      if (isRangeChange) {
        let eventStr = JSON.stringify(rangeObj)
        localStorage.setItem('TimeRange', eventStr)
      }
      let hasStoreRange = localStorage.getItem('TimeRange')
      if (hasStoreRange) {
        rangeObj = JSON.parse(hasStoreRange)
      }
      const candleData = this.charts.get_one('chart.data')

      if (candleData.length) {
        $store.commit('CHART_VISIBLE_RANGE', rangeObj.range)
        if (
          (isRangeChange && state.chartPredineRangeUpdates) ||
          (rangeObj && rangeObj.range && rangeObj.range.length)
        ) {
          if (state.chartPredineRangeUpdates)
            $store.commit('CHART_PREDEFINE_RANGE_UPDATES')
          const endingTime = candleData[candleData.length - 1][0]
          let startingTime = candleData[0][0]
          startingTime = getTimeCalculation(rangeObj, endingTime)
          console.log('startingTime', startingTime)
          const { iterations, type } = GetIterationData(state.timeFrame)
          let startingTimeObj = moment(startingTime)
          let firstCandleTimeObj = moment(candleData[0][0])
          let lastCandleTimeObj = moment(candleData[0][candleData.length - 1])
          let diffInDaysFromFirstCandle = startingTimeObj.diff(
            firstCandleTimeObj,
            type
          )
          let startIndex = this.charts.tv.getTimeIndex(candleData[0][0])
          if (startIndex < 0) {
            startIndex = startingTime
          } else {
            startIndex = Math.floor(startIndex)
          }
          $store.dispatch('updateChartStartingTime', startingTime)
          $store.dispatch('updateChartEndingTime', endingTime)
          console.log('range==>', startingTime, candleData.length, iterations)
          this.charts.tv.setRange(
            diffInDaysFromFirstCandle < 0 ? 0 : startingTime,
            candleData.length + iterations
          )
          console.log('applied')
          if (!this.charts?.tv?.$refs?.chart?.auto_y_axis) {
            this.charts.tv.toggleSideBarYAxis()
          }
        } else {
          state.chartVisibleRange = rangeObj.range
          if (rangeObj.range) {
          } else {
            let startingTime = rangeObj.start
            let firstCandleTimeObj = moment(candleData[0][0])
            let startingTimeObj = moment(rangeObj.start)
            let lastCandleTimeObj = moment(candleData[candleData.length - 1][0])
            let timestamp2Obj = moment(rangeObj.end)
            let diffInDaysFromFirstCandle = startingTimeObj.diff(
              firstCandleTimeObj,
              'day'
            )
            let endingTimeDiff = timestamp2Obj.diff(lastCandleTimeObj, 'day')
            let startingDaysInControls = diffInDaysFromFirstCandle < 0
            const { iterations } = GetIterationData(state.timeFrame)
            this.charts.tv.setRange(
              startingDaysInControls ? 0 : startingTime,
              endingTimeDiff > 0 ? candleData.length + iterations : rangeObj.end
            )
          }
        }
        if (callback) {
          callback(this.charts)
        }
      }
    },

    deleteAllChartTools() {
      this.charts.del('Tool')
      this.charts.del('Fibonacci')
      this.charts.del('Trend')
      this.charts.del('TrendLine')
      // this.charts.del('DarvasBox')
      this.charts.tv.custom_event({
        event: 'remove-all-tools-event',
        args: [],
      })
    },
    updateStoreIndicatorFromStorage() {
      let x = localStorage.getItem('indicatorsList')
      let parseData = JSON.parse(x)
      if (parseData?.length > 0) {
        console.log('updateIndicatorList', parseData)
        this.updateIndicatorList(parseData)
      }
    },

    async updateSymbol(name) {
      let timeFrameStorage = localStorage.getItem('SET_TIME_FRAME') || 'D'
      this.isloading = true
      this.errorMessage = false
      let res = await symbol.getSymbolData(name, timeFrameStorage)
      if (res?.data?.length > 0) {
        this.updateSymbolDetail(res)
        let [parsedData, ohlcvMap] = parseToChartCandleDataF(res?.data)
        let newData = this.getChartResponse(parsedData)
        console.log('newData', newData)
        let updatedObj = {
          ...newData,
          onchart: [
            {
              name: 'CurrentCandle',
              type: 'Splitters',
              data: [[newData?.ohlcv[newData?.ohlcv?.length - 1][0]]],
              settings: {
                legend: false,
                lineColor: '#79999e42',
                lineWidth: 2,
              },
            },
          ],
        }
        let dataCube = new DataCube({ ...updatedObj })

        this.charts = dataCube

        this.volume = parsedData[parsedData?.length - 1]

        setTimeout(() => {
          if (parsedData.length > 0) {
            this.onHandleTimeStamp(this.selectedRangeVal)
          }
        })

        setTimeout(() => {
          if (
            this.charts &&
            this.getSelectedIndicators &&
            this.getSelectedIndicators.length > 0
          ) {
            this.getSelectedIndicators.map((item) => {
              this.addWhenSymbolChange(item)
            })
          }
        })

        this.updateSymbolPrice(name)
      } else {
        this.errorMessage = true
      }
      this.isloading = false
    },
    handleRemoveIndicator(name) {
      let obj = { name: name }
      this.charts.del(name)
      this.toggleIndicator(obj)

      this.updateRemoveIndicatorName(null)
    },
    handleSettingModalClose() {
      this.openIndicatorSetting = false
    },
    handleSettingChanged(val) {
      const name = this.getCurrentChartSetting?.id?.replace('_0', '')
      const side = this.getSelectedIndicators?.find(
        (item) => item.name === name
      )
      if (this.getSelectedIndicators.length > 0 && side) {
        let query = `${side.chartType}.${name}.settings`
        let key1 = val.x
        let value = val.y
        const setObj = { key1: value }
        this.charts.merge(query, setObj)
      }
    },
    updateSetting(set) {
      this.openIndicatorSetting = true
    },
    updateChartTheme(data = []) {
      let getVal = data?.find((item) => item?.defaultValue == true)
      if (getVal?.key != this.selectedVariantName) {
        // this.isVariantChange = true
        this.selectedVariantName = getVal?.key
        let colors = variantColors[getVal?.variant]
        this.candleColorUpD = colors?.colorCandleUp
        this.candleColorDwD = colors?.colorCandleDw
        this.candleVolumeUpD = colors?.colorVolUp
        this.candleVolumeDwD = colors?.colorVolDw
        this.variants = getVal?.variantList
      }
    },
    updateChartSetting(data) {
      let magnet_crosshair = data?.chartSetting?.find(
        (item) => item.key == 'crossHair'
      )
      let magnet_value = magnet_crosshair?.settings?.find(
        (item) => item.key == 'magnet'
      )
      let crossLineColor = magnet_crosshair?.settings?.find(
        (item) => item.key == 'crossLineColor'
      )

      let yAxis = data?.chartSetting?.find((item) => item.key == 'yAxis')
      let yAxisBoxColor = yAxis?.settings[0]?.isGroup?.find(
        (item) => item.key == 'boxColor'
      )
      let yAxisBoxTextColor = yAxis?.settings[0]?.isGroup?.find(
        (item) => item.key == 'boxTextColor'
      )

      let upDownBarChart = data?.chartSetting?.find(
        (item) => item.key == 'upDownBarChart'
      )
      let isToggleOffBarColor = upDownBarChart?.settings?.find(
        (item) => item.key == 'barChartColor'
      )
      let isToggleOnBarUpColor = upDownBarChart?.settings[1]?.isGroup?.find(
        (item) => item.key == 'barUpColor'
      )
      let isToggleOnBarDownColor = upDownBarChart?.settings[1]?.isGroup?.find(
        (item) => item.key == 'barDownColor'
      )

      let upDownVolume = data?.chartSetting?.find(
        (item) => item.key == 'upDownVolume'
      )
      let isToggleOffVolume = upDownVolume?.settings?.find(
        (item) => item.key == 'volumeColor'
      )
      let isToggleOnVolumeUp = upDownVolume?.settings[1]?.isGroup?.find(
        (item) => item.key == 'upVolumeColor'
      )
      let isToggleOnVolumeDown = upDownVolume?.settings[1]?.isGroup?.find(
        (item) => item.key == 'downVolumeColor'
      )
      let onBalanceVolume = upDownVolume?.settings?.find(
        (item) => item.key == 'onBalanceVolume'
      )

      if (this.magnet != magnet_value?.defaultValue) {
        this.magnet = magnet_value?.defaultValue
      }
      if (this.crossColor != crossLineColor?.defaultValue) {
        this.crossColor = crossLineColor?.defaultValue
      }
      if (this.hoverYBox != yAxisBoxColor?.defaultValue) {
        this.hoverYBox = yAxisBoxColor?.defaultValue
      }
      if (this.hoverYText != yAxisBoxTextColor?.defaultValue) {
        this.hoverYText = yAxisBoxTextColor?.defaultValue
      }

      // if(!this.isVariantChange){

      //candles
      if (upDownBarChart?.isToggle) {
        if (this.candleColorUpD != isToggleOnBarUpColor?.defaultValue) {
          this.candleColorUpD = isToggleOnBarUpColor?.defaultValue
        }

        if (this.candleColorDwD != isToggleOnBarDownColor?.defaultValue) {
          this.candleColorDwD = isToggleOnBarDownColor?.defaultValue
        }
      } else {
        if (this.candleColorUpD != isToggleOffBarColor?.defaultValue) {
          this.candleColorUpD = isToggleOffBarColor?.defaultValue
        }
        if (this.candleColorDwD != isToggleOffBarColor?.defaultValue) {
          this.candleColorDwD = isToggleOffBarColor?.defaultValue
        }
      }

      //volume
      if (upDownVolume?.isToggle) {
        if (this.candleVolumeUpD != isToggleOnVolumeUp?.defaultValue) {
          this.candleVolumeUpD = isToggleOnVolumeUp?.defaultValue
        }
        if (this.candleVolumeDwD != isToggleOnVolumeDown?.defaultValue) {
          this.candleVolumeDwD = isToggleOnVolumeDown?.defaultValue
        }
      } else {
        if (this.candleVolumeUpD != isToggleOffVolume?.defaultValue) {
          this.candleVolumeUpD = isToggleOffVolume?.defaultValue
        }
        if (this.candleVolumeDwD != isToggleOffVolume?.defaultValue) {
          this.candleVolumeDwD = isToggleOffVolume?.defaultValue
        }
      }

      // }
    },

    symbolChangeVerifyScanDetails() {
      const scans = this.getScans()

      if (
        scans &&
        scans.length > 0 &&
        this.symbolName === this.selectedSymbol?.symbol
      ) {
        const { scanType, settings } = scans[0]
        const symbolData = this.selectedSymbol

        this.addDrawingForScannedSymbol({ symbolData, scanType, settings })
      }
    },
    async loadDrawingData() {
      setTimeout(async () => {
        let symbolDrawingData = null
        let symbolDrawingDataV1 = await getDrawingDataV1(
          this.symbolName,
          this.$store.state.userId
        )

        if (symbolDrawingDataV1 && symbolDrawingDataV1.drawing) {
          symbolDrawingData = symbolDrawingDataV1.drawing
        }
        if (
          symbolDrawingData &&
          symbolDrawingData.symbolName &&
          symbolDrawingData.data &&
          Object.values(symbolDrawingData.data).length
        ) {
          this.$store.commit(
            'UPDATE_USER_DRAWING_TOOLS',
            symbolDrawingData.data
          )
        } else {
          this.$store.commit('UPDATE_USER_DRAWING_TOOLS', {})
        }
      })
    },
  },
  computed: {
    ...mapGetters([
      'getTheme',
      'getChartType',
      'getSelectedIndicators',
      'getRemoveIndicatorName',
      'getSelectedVariant',
      'getCurrentChartSetting',
      'getAppSetting',
      'getSymbolName',
      'scanResults',
      'getScannerRightbarOpen',
      'trainingModeDataRaw',
      'formattedSymbolData',
      'isTrainingMode',
      'ohlcv',
      'getChartCursor',
      'getUserId',
    ]),

    getCursorIcon() {
      return `cursor-${this.getChartCursor.icon}`
    },
    isCtrlPressed: {
      get() {
        return this.$store.state.chartControlPressed
      },
      set(val) {
        this.$store.state.chartControlPressed = val
      },
    },
    colorObjectProps() {
      return { scale: '#E5E7EB', panel: '#020617', colorWickUp: '#181818' }
      // return {scale: "#000"}
    },
    titleColor() {
      return this.isDark ? '#fff' : '#000'
    },

    colors() {
      if (!this.isDark) {
        return {
          colorBack: '#fff',
          colorGrid: '#e0dcdc',
          colorText: '#333',
          colorWickUp: '#000000',
          colorWickDw: '#000000',
          colorCross: '#8091a0',
        }
      } else {
        return {
          colorBack: '#1A1A1A',
          colorGrid: '#3B3B3B',
          colorText: '#6B6B6B',
          colorWickUp: '#188874',
          colorWickDw: '#B21523',
          colorCross: '#6B6B6B',
        }
      }
    },
    computedOffChartList() {
      return this.addOffChart
    },

    chart2() {
      let chartType = this.getChartType
      if (
        this.charts?.data?.chart?.type &&
        this.charts?.data?.chart?.type !== chartType
      ) {
        this.charts.data.chart.type = chartType
      }

      if (chartType != 'PnF') {
        if (this.charts.data.chart?.data) {
          if (this.isTrainingMode) {
            console.log("trainingModeDataRaw",this.trainingModeDataRaw,this.charts.data.chart,this.charts.data)
            this.charts.data.chart.data = this.trainingModeDataRaw
          } else {
            console.log("ohlcv",this.ohlcv)
            this.charts.data.chart.data = this.ohlcv
          }
        }
      }

      return this.charts
    },
    iframStyle() {
      return { width: this.cbox_width }
    },

    cbox_width() {
      if (this.isRightBar && this.width >= 1440) {
        return Math.floor(this.width - 526)
      } else if (this.isRightBar && this.width >= 880) {
        return Math.floor(this.width - 540)
      } else {
        if (this.width < 880) {
          return Math.floor(this.width - 0)
        } else if (this.width >= 880) {
          return Math.floor(this.width - 120)
        }
      }
    },

    cbox_height() {
      if (this.showNavRight) {
        if (this.width >= 880) {
          return Math.floor(this.height - 0)
        } else if (this.height >= 605) {
          console.log('height pro', this.height - 500)
          return Math.floor(this.height - 74)
        } else if (this.height > 700) {
          return Math.floor(this.height - 0)
        } else {
          return Math.floor(this.height + 5)
        }
      } else {
        return Math.floor(this.height + 40)
      }
    },
  },
  created() {
    this.rangeDate = this.getFullDate(this.selectedDate)
  },

  async mounted() {
    if (window.innerWidth < 880) {
      this.isRightBar = false
    } else {
      this.isRightBar = true
    }

    window.addEventListener('resize', this.updateScreenWidth)
    const generateCustomEvent = (event, args) => {
      console.log("drawing==>",event,args)
      const customEvent = {
        event,
        args,
      }

      if (this.charts?.tv?.custom_event) {
        this.charts?.tv?.custom_event(customEvent)
      }
    }

    const loadSym = (val) => {
      this.onHandleSymbolData(val)
    }

    const onDeleteButtonClick = () => {
      this.onDeleteButtonClick()
    }
    const data = await getUserData()
    if (data && data.userData) {
      let newDataSet = JSON.parse(JSON.stringify(data.userData))
      if (!('additional' in newDataSet?.indicatorSettings)) {
        newDataSet.indicatorSettings['additional'] =
          IndicatorSettingsDefaults?.indicatorSettings?.additional
      }
      await this.updateProfileData(newDataSet)
    }
    if (data && data.id) {
      console.log('data==>', data)
      this.$store.commit('SET_USER_EMAIL', data.email)
      this.$store.commit('SET_USER_ID', data.id)
      this.getUserWatches()
      this.getUserScanList()
    }

    if (data && data.userSettings && data.userSettings.chartSettings) {
      this.$store.commit('SET_CHART_SETTINGS', data.userSettings.chartSettings)

      this.$store.commit(
        'SET_CHART_DEFAULT_SETTINGS',
        data.userSettings.chartSettings
      )
      this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
    }

    let theme = localStorage.getItem('theme')
    if (theme == 'true') {
      this.isDark = true
      this.updateTheme(true)
    } else {
      this.isDark = false
      this.updateTheme(false)
    }

    if (data && data?.indicatorData) {
      console.log('indicators data:  ', data?.indicatorData)
      let result = JSON.parse(data?.indicatorData)
      if (result?.length > 0) {
        this.updateIndicatorList(result)
      } else {
        this.updateIndicatorList([])
      }
    } else {
      this.updateIndicatorList([])
    }

    // else{
    //   this.updateStoreIndicatorFromStorage()
    // }
    let symbolStorage = localStorage.getItem('symbolName')
    if (symbolStorage) {
      this.onHandleSymbolData(symbolStorage)
    } else {
      this.onHandleSymbolData('AAPL')
    }

    window.addEventListener('resize', this.onResize)
    this.$store.commit('SET_TRADING_CHART_REFERENCE', {
      tradingChartReference: this.$refs.tvjs,
    })
    this.onResize()
    this.isPageReload = true

    const params = new URLSearchParams(window.location.search)
    const v1Param = params.get('v1')
    const v2Param = params.get('v2')
    const v3Param = params.get('v3')
    const v4Param = params.get('v4')
    const navParam = params.get('showTool')

    if (navParam) {
      if (navParam == 'true') {
        this.showNavRight = true
      } else {
        this.showNavRight = false
      }
    }
    this.getSymbolList()

    const removeOverlayScan = (type, forceDel = false) => {
      let removeSettings = [
        ...this.charts.get(`onchart`),
        ...this.charts.get(`offchart`),
      ]
      for (const removeSetting of removeSettings) {
        if (removeSetting && removeSetting.scanResults && removeSetting.id) {
          if (forceDel) {
            this.charts.del(removeSetting.id)
          } else {
            if (type !== removeSetting.type) {
              this.charts.del(removeSetting.id)
            }
          }
        }
      }
    }

    chartEvents.$on('generateCustomEvent', generateCustomEvent)
    chartEvents.$on('closeRightBar', this.hideRightbar)
    chartEvents.$on('onDeleteButtonClick', onDeleteButtonClick)
    chartEvents.$on('remove_scan_overlay_new', removeOverlayScan)
    chartEvents.$on('load-symbol', loadSym)
    chartEvents.$on('deleteAllTools', this.deleteAllTools)
    chartEvents.$on('setChartRange', this.setChartRange)
    chartEvents.$on('newSettings', this.newSettings)
    chartEvents.$on(
      'symbolChangeVerifyScanDetails',
      this.symbolChangeVerifyScanDetails
    )
    chartEvents.$on('loadDrawingData', this.loadDrawingData)

    document.addEventListener(
      'keydown',
      (event) => {
        let name = event.key
        let code = event.code
        // Alert the key name and key code on keydown
        if (name === 'Control') {
          if (!this.isCtrlPressed) {
            this.isCtrlPressed = true
          }
        } else {
          this.isCtrlPressed = false
        }
      },
      false
    )
    document.addEventListener(
      'keyup',
      (event) => {
        let name = event.key
        let code = event.code
        // Alert the key name and key code on keydown
        if (name === 'Control') {
          if (this.isCtrlPressed) {
            this.isCtrlPressed = false
          }
        }
      },
      false
    )
    this.initializeSignalR()
  },
  beforeDestroy() {
    chartEvents.$off('generateCustomEvent')
    chartEvents.$off('onDeleteButtonClick')
    chartEvents.$off('remove_scan_overlay_new')
    chartEvents.$off('deleteAllTools')
    chartEvents.$off('setChartRange')
    chartEvents.$off('load-symbol')
    chartEvents.$off('symbolChangeVerifyScanDetails')
    chartEvents.$off('newSettings')
    chartEvents.$off('loadDrawingData')
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('keydown', this.handleKeyDownClose)

    window.addEventListener('resize', this.updateScreenWidth)
  },
  watch: {
    getSymbolName(val) {
      if (this.symbolName != val) {
        this.onHandleSymbolData(val)
      }
    },

    charts(val){
      console.log("change==>",val)
    },

    isloading(val) {
      if (!val) {
        this.loadDrawingData()
      }
    },

    isRightBar(val) {},

    width(val) {
      if (val < 880) {
        this.isRightBar = false
      }
    },

    isTrainingMode(val) {
      if (val) {
        this.customIndicator?.map((item) => {
          this.addIndicator(item)
        })
      }
    },

    getScannerRightbarOpen(val) {
      if (val) {
        if (!this.isRightBar) {
          this.isRightBar = true
        }
      }
    },

    getCurrentChartSetting(val) {
      if (val?.id) {
        this.updateSetting(val)
      }
    },
    variants() {
      if (this.variants?.sixthVariant) {
        let dataRes = this.charts?.data?.offchart?.filter(
          (item) => item?.name != 'Volume'
        )
        for (const data of dataRes) {
          this.charts.del(data?.name)
        }

        let updatedArr = this.getSelectedIndicators?.filter(
          (item) => item.chartType == 'onchart'
        )
        let res = [...updatedArr, ...this.customIndicator]

        this.customIndicator?.map((item) => {
          this.addIndicator(item)
        })
        this.updateIndicatorList(res)
      }
    },
    selectedDate() {
      this.getRangeData()
    },
    getTheme(val) {
      this.isDark = val
      localStorage.setItem('theme', val)
    },
    getRemoveIndicatorName(val) {
      if (val) {
        this.handleRemoveIndicator(val)
      }
    },

    getSelectedIndicators(indicators) {
      console.log('indicators', indicators)
      // localStorage.setItem('indicatorsList', JSON.stringify(indicators))
      this.updateIndicator(indicators)
    },
    getAppSetting(val) {
      if (val) {
        this.updateChartTheme(val?.themeSetting[0]?.settings[0]?.isGroup)
        this.updateChartSetting(val)
      }
    },
  },
}
</script>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css');

.outer-container {
  width: 94%;
  height: 95vh;
  display: flex;
  /* border: 3px solid #e0e3eb; */
  background-color: transparent;
  position: relative;
  /* max-width: 94%; */
}

.left-pane {
  /* background-color: aqua; */
  overflow: hidden;
  /* width: 80%; */
}

.right-pane {
  /* background-color: blue; */
  overflow: hidden;
  /* width: 20%; */
}

.resize-handle {
  width: 3px;
  background-color: yellow;
  cursor: ew-resize;
  position: relative;
}

/* End of CSS */

.offBtn:hover {
  border: none;
  background-color: black;
  color: white;
  transition: all ease 0.5s;
}

.multi-chart {
  display: grid;
  width: 100%;
  grid-template-columns: 100% 100%;
}

.inner-container {
  border: 1px solid black;
  padding: 20px;
  text-align: center;
}

.abs {
  position: absolute;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.cla {
  z-index: 50;
  position: absolute;
  height: 120px;
  width: 120px;
  background-color: red;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div>
    <CusrtomizePatternWrapper
      :headingName="'Capitalisation'"
      :tag="'Market  '"
      :detail="'Define criteria related to the size and market '"
      :highlighted="'value of companies.'"
    >
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Top Market Cap Stocks
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Limit results to the top __ stocks by market
                capitalization.</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 20%; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.capitalisation.topSymbols.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]"
                    >stocks by the market cap</span
                  >
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Filter stocks based on their earnings yield relative to their market price.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('capitalisation', 'topSymbols')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('capitalisation', 'topSymbols', 'isActive')
                  ? '#1C274C'
                  : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Shares Issued</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set the threshold for shares issued:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <select
                    type="select"
                    style="width: 35%; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.capitalisation.sharesIssued.type"
                  >
                    <option
                      v-for="ratio in ratioOption"
                      :key="ratio.value"
                      :value="ratio.value"
                    >
                      {{ ratio.text }}
                    </option>
                  </select>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 20%; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.capitalisation.sharesIssued.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]"
                    >million</span
                  >
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="` Select companies with a price-to-earnings ratio within your specified range.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('capitalisation', 'sharesIssued')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('capitalisation', 'sharesIssued', 'isActive')
                  ? '#1C274C'
                  : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>
      <!-- Third one input... -->
      <div
        :class="false ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Market Cap</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set the market capitalization threshold:</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <div class="flex items-center space-x-3 w-full">
                  <select
                    type="select"
                    style="width: 35%; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.capitalisation.marketCap.type"
                  >
                    <option
                      v-for="ratio in dividendYieldOption"
                      :key="ratio.value"
                      :value="ratio.value"
                    >
                      {{ ratio.text }}
                    </option>
                  </select>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 20%; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.capitalisation.marketCap.value"
                  />
                  <span class="text-[#5C5C5C] font-[400] text-[16px]">%</span>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="` Select companies with a price-to-earnings ratio within your specified range.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('capitalisation', 'marketCap')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                getActive('capitalisation', 'marketCap', 'isActive')
                  ? '#1C274C'
                  : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>
      <!-- Fourth One Input -->
    </CusrtomizePatternWrapper>
  </div>
</template>
  
<script>
const DEFAULT_SETTINGS = {
  earningYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  peRatio: {
    isActive: false,
    type: 'more',
    value: 20,
  },
  dividendYield: {
    isActive: false,
    type: 'more',
    value: 5,
  },
  frankedPct: {
    isActive: false,
    type: '100',
  },
  capitalisation: {
    topSymbols: {
      isActive: false,
      value: 20,
    },
    sharesIssued: {
      isActive: false,
      type: 'more',
      value: 5,
    },
    marketCap: {
      isActive: false,
      type: 'more',
      value: 5,
    },
  },
  returns: {
    isActive: false,
    type: 'more',
    value: 100,
    month: 'return12m',
  },
}
import CusrtomizePatternWrapper from '../../CusrtomizePatternWrapper.vue'
import DescriptionInput from '../../DescriptionInput.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { DescriptionInput, CusrtomizePatternWrapper },
  name: 'Capitalizations',
  data() {
    return {
      // settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      id: 'fundementals',
      dividendYieldOption: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
      earnigYeilds: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
      ratioOption: [
        {
          text: 'More Than',
          value: 'more',
        },
        {
          text: 'Less Than',
          value: 'less',
        },
      ],
    }
  },
  props: {},
  mounted() {},
  methods: {
    ...mapActions(['setFundementalsScanSettings']),
    isActiveHandler(key, key2) {
      const val = this.getActive(key, key2, 'isActive')
      this.setSetting(key, key2, 'isActive', !val)
    },
    setSetting(key1, key2, activeKey, val) {
      const filters = this.getFundementalSetting
      if (key1 == '') {
        filters[key1] = val
      } else if (key1 == '') {
        filters[key1] = val
      } else {
        filters[key1][key2][activeKey] = val
      }
      this.setFundementalsScanSettings(filters)
    },
    getActive(key1, key2, key3) {
      const filters = this.getFundementalSetting
      if (key1 == 'capitalisation') {
        return filters[key1][key2][key3]
      } else if (key1) {
        return filters[key1][key2]
      }
    },
  },
  computed: {
    ...mapGetters(['getFundementalSetting']),
    settings: {
      get() {
        return this.getFundementalSetting
      },
      set(value) {
        this.setFundementalsScanSettings(value)
      },
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    // settings: {
    //   handler: function (newSettings) {
    //     this.setFundementalsScanSettings(newSettings)
    //   },
    //   deep: true,
    // },
  },
}
</script>
  
  <style>
</style>  
<template>
  <div class="space-y-8">
     <!-- first box in group -->
    <CusrtomizePatternWrapper
      :headingName="'Settings '"
      :tag="'General'"
      :detail="'Set up the basic parameters of the scan, including the type of scan and the'"
      :highlighted="'key periods for analysis.'"
      :backgroundTrue="true"
    >
      <div
        :class="topFourValue.scanType ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Scan Type</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Select the scan direction</span
              >
              <select
                type="select"
                style="width: 10%; height: 45px; font-size: 16px"
                class="px-3 rounded-[2.5px] border-2 border-appBlue"
                v-model="settings.scanType"
              >
                <option
                  v-for="scanType in scanTypes"
                  :key="scanType.value"
                  :value="scanType.value"
                >
                  {{ scanType.text }}
                </option>
              </select>
            </div>
            <div>
              <DescriptionInput
                :description="`Choose whether the scan will target bullish (Long) or bearish (Short) ${settings.scanType} market conditions. `"
              />
            </div>
          </div>
        </div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="topFourValue.scanType ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="topFourValue.scanPeriod ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Scan Period</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Specify the duration of the scan</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 40%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.scanPeriod"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Set the total time period over ${settings.scanPeriod} which the scan will evaluate market data.`"
              />
            </div>
          </div>
        </div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="topFourValue.scanPeriod ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="
          topFourValue.topConfirmationPeriod
            ? 'bg-[#f7f7f7]'
            : 'hover:bg-[#f7f7f7]'
        "
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Top Confirmation Period
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Define the top confirmation period</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 40%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.topConfirmationPeriod"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Indicate the number of days ${settings.topConfirmationPeriod} required to confirm a market top.`"
              />
            </div>
          </div>
        </div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="topFourValue.topConfirmationPeriod ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="
          topFourValue.bottomConfirmationPeriod
            ? 'bg-[#f7f7f7]'
            : 'hover:bg-[#f7f7f7]'
        "
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Bottom Confirmation Period
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Define the bottom confirmation period</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 30%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.bottomConfirmationPeriod"
                />
                <span class="text-[#5C5C5C] font-[400] text-[16px]"
                  >of trading data.</span
                >
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Indicate the number of days ${settings.bottomConfirmationPeriod} required to confirm a market bottom.`"
              />
            </div>
          </div>
        </div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="
                topFourValue.bottomConfirmationPeriod ? '#1C274C' : '#E2E2E2'
              "
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
     <!-- second box in group -->
    <CusrtomizePatternWrapper
      :headingName="'MANAGEMENT'"
      :tag="'RISK'"
      :detail="'Configure settings to manage and control risk '"
      :highlighted="'during the scan.'"
    >
      <div
        :class="topFourValue.scanType ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Percentage Risk Buffer
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set the risk buffer percentage</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 40%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.percentageRiskBuffer"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Establish a buffer to help mitigate potential risks. ${settings.percentageRiskBuffer}`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('percentageRiskBufferToggle')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="percentageRiskBufferToggle ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="topFourValue.scanPeriod ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Limit Total Risk</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Set a maximum limit on total risk</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 40%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.limitTotalRisk.valuePct"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Use this option to cap overall risk ${settings.limitTotalRisk.valuePct} exposure at a specific percentage.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('limitTotalRisk')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('limitTotalRisk','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
     <!-- third box in group -->
    <CusrtomizePatternWrapper
      :headingName="'Conditions'"
      :tag="'Market'"
      :detail="'Specify conditions related to market activity, including '"
      :highlighted="'volume changes and price positions.'"
    >
      <div
        :class="topFourValue.scanType ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Volume Increase</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]">Require volume to increase by at least __% over the previous</span>
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 20%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.volumeIncreasedOver.percent"
                />
                <span class="text-[#5C5C5C] font-[400] text-[16px]">Require</span>
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 20%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.volumeIncreasedOver.previousBars"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Ensure the scan identifies significant volume ${settings.volumeIncreasedOver.percent} changes over a specified period ${settings.volumeIncreasedOver.previousBars}.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('volumeIncreasedOver')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('volumeIncreasedOver','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="topFourValue.scanPeriod ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Box Position</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Stock must be in a box</span
              >
              <div class="w-[80%] gap-6 flex items-center">
                <!-- <base-check-box label="TCI Break " /> -->
                <div class="w-auto flex gap-2 items-center h-full">
                  <input
                    type="radio"
                    style="width: 20px; height: 20px"
                    :id="'tciBreakDirection~up' + dialogId"
                    name="tciBreakDirection"
                    value="anyBox"
                    v-model="settings.inBox.type"
                  />
                  <label
                    :for="'tciBreakDirection~up' + dialogId"
                    :style="{  cursor: 'pointer' }"
                    >Any box</label
                  >
                </div>

                <div class="w-auto flex gap-2 items-center h-full">
                  <input
                    type="radio"
                    :id="'tciBreakDirection~down' + dialogId"
                    style="width: 20px; height: 20px"
                    name="tciBreakDirection"
                    value="1stBox"
                    :style="{ cursor: 'pointer' }"
                    v-model="settings.inBox.type"
                  />
                  <label
                    :for="'tciBreakDirection~down' + dialogId"
                    :style="{  cursor: 'pointer' }"
                    >1st box</label
                  >
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Define whether the stock should be within ${settings.inBox.type} any Darvas box or specifically the first one.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('inBox')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('inBox','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="topFourValue.scanPeriod ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Price Relation to Top
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Price must be within</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 15%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.within.pricePoints"
                />
                <div class="flex items-center space-x-3 w-full">
                  <span class="text-[#5C5C5C] font-[400] text-[16px]"
                    >price points below the box top, using either</span
                  >
                  <select
                    type="select"
                    style="width: 20%; height: 45px; font-size: 16px"
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    v-model="settings.within.type"
                  >
                    <option
                      v-for="scanType in highAndLow"
                      :key="scanType.value"
                      :value="scanType.value"
                    >
                      {{ scanType.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Define whether the stock ${settings.within.pricePoints} should be within any Darvas box or specifically ${settings.within.type} the first one.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('within')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('within','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
    <!-- fourth box in group -->
    <CusrtomizePatternWrapper
      :headingName="'Parameters'"
      :tag="'Box'"
      :detail="'Control the formation and characteristics of Darvas boxes '"
      :highlighted="'during the scan.'"
    >
      <div
        :class="topFourValue.scanType ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">
              Max Box Sequences
            </div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Limit the scan to a maximum of</span
              >
              <div class="w-auto flex gap-2 items-center h-full">
                <input
                  class="px-3 rounded-[2.5px] border-2 border-appBlue"
                  style="width: 40%; height: 45px; font-size: 16px"
                  type="number"
                  v-model="settings.restrictBoxToMaxBox.maxBoxes"
                />
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Restrict the scan to include ${settings.restrictBoxToMaxBox.maxBoxes} only a specified number of box sequences.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('restrictBoxToMaxBox')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('restrictBoxToMaxBox','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
      <div
        :class="topFourValue.scanPeriod ? 'bg-[#f7f7f7]' : 'hover:bg-[#f7f7f7]'"
        class="flex cursor-pointer py-3 px-2 items-center space-x-2 justify-between w-full"
      >
        <div class="flex items-center space-x-4 w-full">
          <div class="space-y-4 w-full">
            <div class="text-bold font-[700] text-[15px]">Box Width Range</div>
            <div class="flex items-center space-x-3 w-full">
              <span class="text-[#5C5C5C] font-[400] text-[16px]"
                >Restrict the box width to between</span
              >
              <div class="w-[80%] gap-6 flex items-center">
                <!-- <base-check-box label="TCI Break " /> -->
                <div class="w-auto flex gap-2 items-center h-full">
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 20%; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.restrictCurrentBoxWidth.start"
                  />
                  <span>and</span>
                  <input
                    class="px-3 rounded-[2.5px] border-2 border-appBlue"
                    style="width: 20%; height: 45px; font-size: 16px"
                    type="number"
                    v-model="settings.restrictCurrentBoxWidth.end"
                  />
                </div>
              </div>
            </div>
            <div>
              <DescriptionInput
                :description="`Define whether the stock should  be ${settings.restrictCurrentBoxWidth.start} within any Darvas box or ${settings.restrictCurrentBoxWidth.end} specifically the first one.`"
              />
            </div>
          </div>
        </div>
        <div @click="isActiveHandler('restrictCurrentBoxWidth')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.5303L9.0303 13.5303C8.7374 13.8232 8.2626 13.8232 7.96967 13.5303L5.96967 11.5303C5.67678 11.2374 5.67678 10.7626 5.96967 10.4697C6.26256 10.1768 6.73744 10.1768 7.03033 10.4697L8.5 11.9393L10.7348 9.7045L12.9697 7.46967C13.2626 7.17678 13.7374 7.17678 14.0303 7.46967Z"
              :fill="getActive('restrictCurrentBoxWidth','isActive') ? '#1C274C' : '#E2E2E2'"
            />
          </svg>
        </div>
      </div>
    </CusrtomizePatternWrapper>
  </div>
</template>
  
  <script>
import { mapActions,mapGetters } from 'vuex'
const DEFAULT_SETTINGS = {
  scanType: 'long',
  scanPeriod: 55,
  topConfirmationPeriod: 3,
  bottomConfirmationPeriod: 3,
  percentageRiskBuffer: 2.0,
  limitTotalRisk: {
    isActive: false,
    valuePct: 20.0,
  },
  volumeIncreasedOver: {
    isActive: false,
    percent: 300,
    previousBars: 1,
  },
  inBox: {
    isActive: false,
    type: 'anyBox', // 'anyBox' | '1stBox'
  },
  within: {
    isActive: false,
    type: 'close', // 'close' | 'high'
    pricePoints: 0,
  },
  restrictBoxToMaxBox: {
    isActive: false,
    maxBoxes: 0,
  },
  restrictCurrentBoxWidth: {
    isActive: false,
    start: 0,
    end: 10,
  },
}
import CusrtomizePatternWrapper from '../../CusrtomizePatternWrapper.vue'
import DescriptionInput from '../../DescriptionInput.vue'
import Wrapper from '../../Wrapper.vue'
export default {
  name: 'DarvasBoxes',
  data() {
    return {
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
      percentageRiskBufferToggle: false,
      dialogId: "chart patterns",
      scanTypes: [
        {
          text: 'Long',
          value: 'long',
        },
        {
          text: 'Short',
          value: 'short',
        },
      ],
      highAndLow: [
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Close',
          value: 'close',
        },
      ],
      topFourValue: {
        scanType: true,
        scanPeriod: true,
        topConfirmationPeriod: true,
        bottomConfirmationPeriod: true,
      },
    }
  },
  components: { DescriptionInput, Wrapper, CusrtomizePatternWrapper },
  computed:{
    ...mapGetters(['getDarvasBoxSetting'])
  },
  methods: {
    ...mapActions(['setDarvasScanSettings','percentageRiskBufferChange']),
    isActiveHandler(key) {
      if(key == 'percentageRiskBufferToggle') {
        this.percentageRiskBufferToggle = !this.percentageRiskBufferToggle
        this.percentageRiskBufferChange()
      }
      const val = this.getActive(key, 'isActive')
      this.setSetting(key, 'isActive', !val)
    },
    setSetting(key1, key2, val) {
      const filters = this.settings
      if (key1 == '') {
        filters[key1] = val
      } else if (key1 == '') {
        filters[key1] = val
      } else {
        filters[key1][key2] = val
      }
      this.setDarvasScanSettings(filters)
    },
    getActive(key1, key2) {
      const filters = this.getDarvasBoxSetting
      if (key1 === '') {
        return filters[key1]
      } else if (key1 == '') {
        return filters[key1]
      } else {
        return filters[key1][key2]
      }
    },
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    settings: {
      handler: function (newValue) {
        this.setDarvasScanSettings(newValue)
      },
      deep: true,
    },
  }
}
</script>
<style>
</style>
<template>
  <div
    class="relative flex flex-col justify-between xxs:w-[750px] lg:w-[55vw] 2lg:w-[80vw] h-[90vh] !overflow-hidden"
  >
    <div
      v-if="isLoading"
      class="absolute backdrop-blur-sm flex-col w-full h-[85vh] flex items-center justify-between"
      style="z-index: 9999"
    >
      <!-- <loader></loader> -->
    </div>
    <!-- Header For Pattern Scanners -->
    <div
      :class="[isLoading ? 'pointer-events-none' : '']"
      class="w-full h-[12%]"
    >
      <div
        class="w-full !h-[50%] -bg-slate-500 px-4 flex flex-row justify-between items-center space-x-4"
      >
        <div class="w-auto flex flex-row space-x-3">
          <div class="text-[#6D6D6D] text-[18px]">Scanners</div>
        </div>

        <span
          @click="closeHandler"
          class="cursor-pointer p-2 rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div
        class="w-full h-[50%] border-t border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-4 items-center flex space-x-4 dark:border-[#232323]"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              stroke="#a3a6af"
              d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
            ></path>
          </svg>
        </span>
        <span class="w-full">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search lists"
            class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
          />
        </span>
      </div>
    </div>
    <!-- Main Content For Scanner Data -->
    <div
      :class="[isLoading ? 'pointer-events-none' : '']"
      class="w-full min-h-[30rem] h-[83%] flex flex-row -bg-slate-400"
    >
      <!-- Left Side -->
      <div
        class="xxs:w-[150px] md:w-[20%] -bg-slate-300 !h-full mt-1 flex flex-col -rounded-[10px] border-r border-gray dark:!border-[#2D2D2D]"
      >
        <div class="flex-1 py-3 overflow-y-auto no-scrollbar">
          <div class="mb-4 px-4 bold font-bold">Scan Types</div>
          <div
            v-for="(sidebar, index) in filteredScanners"
            :key="index"
            @click="handlerSelectedScanner(sidebar)"
            :class="
              sidebar.name === selectedScanner.name
                ? '!bg-[#001a2c] text-white -!border-appBlue'
                : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
            "
            class="flex cursor-pointer flex-row justify-start items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-10 py-[12px] xxs:space-x-1 md:space-x-3"
          >
            <div
              v-html="
                getChartSvgs(
                  sidebar?.image,
                  sidebar.name == selectedScanner.name
                )
              "
            ></div>
            <div>{{ sidebar.name }}</div>
          </div>
        </div>
      </div>
      <!-- Right Side -->
      <div class="flex flex-col w-[80%] h-full mt-2">
        <!-- Right side Header -->
        <div class="w-full h-[16%]">
          <div class="w-full h-full">
            <RightSideHeader
              :id="idForScanner"
              :type="typeOfScan"
              :scanTypeMarket="scanTypeMarket"
              @selectedBtn="selectedButtonNameType"
              :selectedButtonTypeName="selectedButtonTypeName"
              :scannerNameList="selectedScanner"
            />
          </div>
        </div>
        <!-- Right side Body -->
        <div
          v-if="selectedScanner?.name == 'Charting Patterns'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar"
        >
          <ChartPatternsIndicator
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Activity'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar"
        >
          <Activity :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Candlestick'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar"
        >
          <Candle :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Technical Indicators'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar"
        >
          <Indicators :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Capitulation Scan'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <CapitulationScan :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Bollinger Squeeze'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <BolingerSqueeze :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Big Candle'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <BigCandle :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Heiken Ashi'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <HeikenAshi :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Higher High, Higher Low'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <HigherHighHigherLow
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'Fundamental'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar"
        >
          <Fundamental :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Darvas Box'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <DarvasBoxes :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Divergense Scan'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <DivergenceScan :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'Pocket Pivot'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <PocketPivot :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProReversal Signal'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProReversalSignal :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProDowntrend Monitor'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProDowntrendMonitor
            :selectedButtonTypeName="selectedButtonTypeName"
          />
        </div>
        <div
          v-if="selectedScanner.name == 'ProBreakout Alert'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProBreakoutAlert :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProDivergence Watch'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProDivergence :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProSwing Boost'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProSwingBoostVue :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
        <div
          v-if="selectedScanner.name == 'ProSwing Drop'"
          class="w-full h-[95%] overflow-y-scroll no-scrollbar -bg-slate-400"
        >
          <ProSwingDrop :selectedButtonTypeName="selectedButtonTypeName" />
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div class="w-full border-t h-[4rem] py-2 px-4 bg-white">
      <ModalFooter
        firstTitle="Close"
        secondTitle="Scan"
        thirdTitle="Stop Scan"
        infoBtnTitle="Add to favourite"
        colorType="color"
        :isLoading="isLoading"
        @close="closeHandler"
        @save="selectedBtnFunc"
        @stopScan="stopScan"
        @addToFav="handleAddToFavourtire"
      />
    </div>
  </div>
</template>

<script>
import { scannerList } from '../../../utils/newScanner.js'
import ChartPatternsIndicator from './ChartPatternsIndicator.vue'
import RightSideHeader from '../../../component/RightSideHeader.vue'
import Activity from './Activity.vue'
import ModalFooter from '../../ModalFooter.vue'
import { indicatorSvgsWithName } from './chartSvgs'
import { mapActions } from 'vuex'
import Candle from './Candle.vue'
import Indicators from './Indicators.vue'
import CapitulationScan from './CapitulationScan.vue'
import BolingerSqueeze from './BolingerSqueeze.vue'
import BigCandle from './BigCandle.vue'
import HeikenAshi from './HeikenAshi.vue'
import HigherHighHigherLow from './HigherHighHigherLow.vue'
import DarvasBoxes from './DarvasBoxes.vue'
import Fundamental from './Fundamental.vue'
import DivergenceScan from './DivergenceScan.vue'
import PocketPivot from './PocketPivot.vue'
import ProReversalSignal from './ProReversalSignal.vue'
import ProDowntrendMonitor from './ProDowntrendMonitor.vue'
import ProBreakoutAlert from './proBreakoutAlert.vue'
import ProDivergence from './ProDivergence.vue'
import ProSwingBoostVue from './ProSwingBoost.vue'
import ProSwingDrop from './ProSwingDrop.vue'

export default {
  name: 'PatternBaseModal',
  components: {
    ChartPatternsIndicator,
    CapitulationScan,
    RightSideHeader,
    ModalFooter,
    Activity,
    Candle,
    Indicators,
    BolingerSqueeze,
    BigCandle,
    HigherHighHigherLow,
    HeikenAshi,
    DarvasBoxes,
    Fundamental,
    DivergenceScan,
    PocketPivot,
    ProReversalSignal,
    ProDowntrendMonitor,
    ProBreakoutAlert,
    ProDivergence,
    ProSwingBoostVue,
    ProSwingDrop,
  },
  data() {
    return {
      // scannerNameList: JSON.parse(JSON.stringify(scannerList)),
      scanTypeMarket: 'scanner',
      searchQuery: '',
      scannerNameList: [
        {
          id: 1,
          name: 'Charting Patterns',
          scanType: 'scanner',
          tabs: [
            { name: 'Charting Patterns' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onScanClick,
          image: 'charting-patterns',
          value: 'patterns',
          type: 'Handheld',
          status: 'Active',
          lastChecked: '2024-08-01 14:23:45',
        },
        {
          id: 2,
          name: 'Activity',
          onClickHandler: this.onActivityClick,
          scanType: 'scanner',
          tabs: [{ name: 'Activity' }, { name: 'Filters' }],
          image: 'activity',
          value: 'activity',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 3,
          name: 'Candlestick',
          scanType: 'scanner',
          tabs: [{ name: 'Candlestick' }, { name: 'Filters' }],
          onClickHandler: this.onCandlestickClick,
          image: 'candlestick',
          value: 'candlesticks',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 4,
          name: 'Technical Indicators',
          scanType: 'scanner',
          tabs: [
            { name: 'Technical Indicators' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onIndicatorClick,
          image: 'technical-indicators',
          value: 'indicators',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 5,
          name: 'Darvas Box',
          scanType: 'scanner',
          tabs: [{ name: 'Darvas Box' }, { name: 'Filters' }],
          onClickHandler: this.onDarvasClick,
          image: 'darvas-box',
          value: 'darvasScan',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 6,
          name: 'Fundamental',
          scanType: 'scanner',
          tabs: [
            { name: 'Measures' },
            { name: 'Capitalization' },
            { name: 'Returns' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onFundamentalClick,
          image: 'fundamental',
          value: 'fundementals',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },

        {
          id: 7,
          name: 'Capitulation Scan',
          scanType: 'guru',
          tabs: [{ name: 'Capitulation Scan' }],
          onClickHandler: this.onCapitulationClick,
          image: 'capitulation-days',
          value: 'capitulation',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 8,
          name: 'Higher High, Higher Low',
          scanType: 'guru',
          tabs: [{ name: 'Higher High, Higher Low' }],
          onClickHandler: this.onHigherClick,
          image: 'hhll',
          value: 'hhhl',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 9,
          name: 'Bollinger Squeeze',
          scanType: 'guru',
          tabs: [{ name: 'Bollinger Squeeze' }],
          onClickHandler: this.onBollingerClick,
          image: 'bollinger-squeeze',
          type: 'Fixed',
          value: 'bollingerSqueeze',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 10,
          name: 'Divergense Scan',
          scanType: 'guru',
          tabs: [{ name: 'Divergense Scan' }],
          onClickHandler: this.onDivergenceClick,
          image: 'divergence-scan',
          value: 'divergenceScan',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 11,
          name: 'Big Candle',
          scanType: 'guru',
          tabs: [{ name: 'Big Candle' }],
          onClickHandler: this.onBigCandleClick,
          image: 'big-candle',
          value: 'bigCandle',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 12,
          name: 'Heiken Ashi',
          scanType: 'guru',
          tabs: [{ name: 'Heiken Ashi' }],
          onClickHandler: this.onHeikenAshiClick,
          image: 'heiken-ashi',
          value: 'heikenAshi',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 13,
          name: 'Pocket Pivot',
          scanType: 'guru',
          tabs: [{ name: 'Pocket Pivot' }],
          onClickHandler: this.onPocketPivotClick,
          image: 'pockket-pivot',
          value: 'pocketPivot',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 14,
          name: 'ProReversal Signal',
          scanType: 'scanner',
          tabs: [{ name: 'ProReversal Signal' }, { name: 'Filters' }],
          onClickHandler: this.onProreversalSignalClick,
          image: 'proReversal-signal',
          value: 'proReversalSignal',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 15,
          name: 'ProDowntrend Monitor',
          scanType: 'scanner',
          tabs: [
            { name: 'ProDowntrend Monitor' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProDowntrendMonitorClick,
          image: 'proDowntrend-Monitor',
          value: 'proDowntrendMonitor',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 16,
          name: 'ProBreakout Alert',
          scanType: 'scanner',
          tabs: [{ name: 'ProBreakout Alert' }, { name: 'Filters' }],
          onClickHandler: this.onProbreakoutAlertClick,
          image: 'proBreakout-alert',
          value: 'proBreakoutAlert',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 17,
          name: 'ProDivergence Watch',
          scanType: 'scanner',
          tabs: [
            { name: 'ProDivergence Watch' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProDivergenceWatchClick,
          image: 'proDivergence-watch',
          value: 'proDivergenceWatch',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 18,
          name: 'ProSwing Boost',
          scanType: 'scanner',
          tabs: [
            { name: 'ProSwing Boost' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProSwingBoostClick,
          image: 'proSwing-Boost',
          value: 'proSwingBoost',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
        {
          id: 19,
          name: 'ProSwing Drop',
          scanType: 'scanner',
          tabs: [
            { name: 'ProSwing Drop' },
            { name: 'Events' },
            { name: 'Filters' },
          ],
          onClickHandler: this.onProSwingDropClick,
          image: 'proSwing-drop',
          value: 'proSwingDrop',
          type: 'Fixed',
          status: 'Inactive',
          lastChecked: '2024-08-02 09:15:32',
        },
      ],

      selectedScanner: JSON.parse(JSON.stringify(scannerList[0])),
      scannerId: {
        id: 1,
        name: 'Charting Patterns',
        image: 'charting-patterns',
        scanType: 'scanner',
        value: 'patterns',
        type: 'Handheld',
        status: 'Active',
        lastChecked: '2024-08-01 14:23:45',
      },
      selectedButtonTypeName: 'Charting Patterns',
      selectedBtnFunc: this.onScanClick, // selectedButtonTypeName: 'null',
      isLoading: false,
    }
  },
  computed: {
    filteredScanners() {
      // Return full array if searchQuery is empty
      if (this.searchQuery == '') {
        return this.scannerNameList
      }
      // Otherwise, filter the array based on searchQuery
      return this.scannerNameList.filter((scanner) =>
        scanner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },

    idForScanner() {
      if (this.selectedScanner.name == this.scannerId.name) {
        return this.selectedScanner.value
      }
    },
    typeOfScan() {
      if (this.selectedScanner.scanType == 'guru') {
        return 'guru'
      } else {
        return 'scanner'
      }
    },
    scanBtn() {
      return this.isLoading ? 'Stop' : 'Scan'
    },
  },
  methods: {
    ...mapActions([
      'sendPatternsScanRequest',
      'cancelScans',
      'sendCandlestickScanRequest',
      'sendIndicatorsScanRequest',
      'sendActivityScanRequest',
      'sendCapitulationRequest',
      'sendBigCandleScanRequest',
      'sendHeikenAshiScanRequest',
      'sendBollingerSqueezePatternRequest',
      'sendHigherHighHigherLowPatternRequest',
      'sendDarvasScanRequest',
      'sendFundementalScanRequest',
      'sendDivergenceScanRequest',
      'sendPocketPivotScanRequest',
      'sendProReversalScanRequest',
      'sendProDowntrendScanRequest',
      'sendProBraakoutRequest',
      'sendDivergenveRequest',
      'sendProSwingScanRequest'
    ]),
    stopScan() {
      this.cancelScans()
    },
    async onPocketPivotClick() {
      this.isLoading = true
      await this.sendPocketPivotScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onProSwingDropClick() {
      console.log('save the click.')
    },
    async onProSwingBoostClick() {
      this.isLoading = true
      await this.sendProSwingScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onProDivergenceWatchClick() {
      this.isLoading = true
      await this.sendDivergenveRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onProbreakoutAlertClick() {
      this.isLoading = true
      await this.sendProBraakoutRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onProDowntrendMonitorClick() {
      this.isLoading = true
      await this.sendProDowntrendScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onProreversalSignalClick() {
      this.isLoading = true
      await this.sendProReversalScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },

    async onFundamentalClick() {
      this.isLoading = true
      await this.sendFundementalScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onDarvasClick() {
      this.isLoading = true
      await this.sendDarvasScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onIndicatorClick() {
      this.isLoading = true
      await this.sendIndicatorsScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onActivityClick() {
      console.log('activity scanner=====>')
      console.log('activity scanner=====>')
      this.isLoading = true
      await this.sendActivityScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },

    async onCapitulationClick() {
      console.log('capitulation scanner=====>')
      this.isLoading = true
      const response = await this.sendCapitulationRequest()
      console.log('response====>', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onBollingerClick() {
      console.log('capitulation scanner=====>')
      this.isLoading = true
      const response = await this.sendBollingerSqueezePatternRequest()
      console.log('response====>', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },

    async onHigherClick() {
      console.log('capitulation scanner=====>')
      this.isLoading = true
      const response = await this.sendHigherHighHigherLowPatternRequest()
      console.log('response====>', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onBigCandleClick() {
      console.log('BigCandle scanner=====>')
      this.isLoading = true
      const response = await this.sendBigCandleScanRequest()
      console.log('response====>BC', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onDivergenceClick() {
      this.isLoading = true
      const respone = await this.sendDivergenceScanRequest()
      console.log('response====>DG', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onHeikenAshiClick() {
      console.log('Heiken Ashi scanner=====>')
      this.isLoading = true
      const response = await this.sendHeikenAshiScanRequest()
      console.log('response====>BC', response)
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onCandlestickClick() {
      this.isLoading = true
      await this.sendCandlestickScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendPatternsScanRequest()
      this.isLoading = false
      if (!this.isLoading) this.closeHandler()
    },
    selectedButtonNameType(payload) {
      console.log('payload pattern base modal==>', payload)
      this.selectedButtonTypeName = payload
    },
    handleAddToFavourtire() {
      console.log('add to favourite')
    },
    handlerSelectedScanner(scanner) {
      if (scanner.name == 'Fundamental') {
        this.selectedButtonTypeName = 'Measures'
      } else {
        this.selectedButtonTypeName = scanner.name
      }
      this.selectedBtnFunc = scanner.onClickHandler
      this.scannerId = scanner
      this.selectedScanner = scanner
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.closeHandler()
      }
    },
    closeHandler() {
      this.$emit('handleCloseEmitPattern')
    },
    getChartSvgs(name, boolean) {
      return indicatorSvgsWithName(name, boolean)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscapeKey)
    console.log('this.selectedScanner', this.scannerId)
  },
  beforeDestroy() {
    document.addEventListener('keydown', this.handleEscapeKey)
  },
}
</script>

<style scoped></style>

<template>
  <div
    class="xxs:w-[88vw] lg:!w-[70vw] xl:!w-[48vw] h-[55rem] rounded-[8px] -bg-slate-400 !overflow-hidden"
  >
    <div class="w-full h-full flex flex-col justify-between text-[#000000]">
      <!-- Header -->
      <div
        class="w-full !h-[60px] border-b border-[#E2E8F0] -!bg-slate-500 px-3 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="`Add symbol(s) to '${getSelectedWatchlist?.name}'`"
          @close="closeModal"
        />
      </div>

      <!-- Search Bar -->
      <div
        class="px-3 w-full flex flex-col justify-around -bg-slate-600 min-h-[44rem] max-h-[44rem] -lg:h-[69vh"
        :class="isLoading ? 'pointer-events-none' : ''"
      >
        <div class="xxs:h-auto md:!h-[3vh] md:py-1 -bg-green-500">
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] xxs:!leading-[10px] xxs:text-[12px] md:text-[14px] font-normal"
            >Search and add symbols to your watchlist by clicking the plus icons
            in the search suggestions. You can add multiple stocks at
            once.</span
          >
        </div>

        <!-- icon dark:bg-[#FFFFFF1A] -->
        <!-- icon dark:text-[#B0B0B0] -->
        <!-- btn-bg dark:hover:bg-[#232323]  -->
        <!-- btn-bg-light dark:hover:bg-[#F6F6F6]  -->
        <!-- txt dark:text-[#F6F6F6] -->
        <!-- dark:!border-[#2D2D2D] -->

        <div
          class="flex items-center xxs:h-auto md:!h-[4vh] shrink-0 w-full space-x-1 -bg-red-600 dark:hover:bg-[#23232370] dark:!border-[#2D2D2D] qm:mt-0 xxs:mt-4 px-2 py-1.5 text-sm hover:bg-appBtnHover dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
          :class="
            getTheme ? '!border-[#1a1a1a] border' : 'border border-[#D1D1D1]'
          "
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
              stroke="#6D6D6D"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5 17.5L13.875 13.875"
              stroke="#6D6D6D"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <input
            v-model="inputValue"
          
            class="bg-transparent text-[#181818] uppercase dark:text-[#ffffff80] placeholder:text-[#6D6D6D] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
            placeholder="Symbol, eg: AAPL"
          />
          <div class="flex items-center space-x-3">
            <div @click="clearInput" class="text-[#7C7C7C] text-[14px]">
              Clear
            </div>
            <svg
              width="2"
              class="text-[#E7E7E7] dark:text-[#7C7C7C]"
              height="28"
              viewBox="0 0 2 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2" height="28" fill="currentColor" />
            </svg>

            <svg
              @click="closeModal"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#7C7C7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#7C7C7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <!-- Slected Symbol -->

        <div
          class="bg-[#FFFFFF] xxs:h-[70%] md:h-[80%] xxs:mt-7 md:mt-3 dark:bg-[#121212] rounded-[12px] -shadow-lg px-3 py-2"
        >

      
        <div class="w-full flex items-center mb-2 space-x-3 overflow-x-scroll scroll-container">
          <div
              v-for="(metadata, i) in markets"
              :key="metadata.id"
              @click="handleMarket(metadata?.code)"
              className="flex items-center"
            >
              <div
                class="flex p-1.5 w-fit px-4 rounded-3xl text-[14px] suggestionText whitespace-nowrap font-[400]"
                :class="
                 selectedMarket == metadata?.code
                    ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                    : 'bg-[#F6F6F6] hover:bg-[#e2e0e0] cursor-pointer hover:bg- dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'
                "
              >
                {{ metadata.desc }}
              </div>
            </div>
        </div>


          <div
            v-if="selectectedSymbol?.length > 0"
            class="w-full flex h-[10%] items-center mb-2 space-x-3 overflow-x-scroll scroll-container"
          >
            <div
              v-for="(item, i) in selectectedSymbol"
              :key="i"
              className="flex items-center"
            >
              <div
                class="flex gap-3 py-1.5 w-fit hover:bg-appBtnHover dark:bg-[#1a1a1a] dark:text-[#ffffff60] bg-[#F6F6F6] px-4 rounded-3xl text-[14px] suggestionText whitespace-nowrap font-[400]"
              >
                <span
                  class="text-[#292929] dark:text-[#ffffff] cursor-default"
                  >{{ item?.symbol }}</span
                >
                <span
                  @click="removeSymbol(item)"
                  class="cursor-pointer mr-[-6px]"
                  v-html="getSvg('croseicon')"
                ></span>
              </div>
            </div>
          </div>

          <!-- the place where symbol data will be displayed -->
          <div
          :class="selectectedSymbol?.length > 0 ?'h-[80%]':'h-[90%]'"
            class="w-full  overflow-y-auto no-scrollbar oveflow-x-hidden"
          >
            <div
              class="w-full h-full flex items-center justify-center text-[16px] text-[#292929] dark:text-[#F6F6F6] font-[500]"
              v-if="isSymbolLoading"
            >
              <Loader></Loader>
            </div>
            <div
              class="w-full h-full sm:text-[16px] text-[12px]"
              v-else-if="tickerInfo?.length > 0"
            >
              <!-- @click="selectedSymbol(items)" -->

              <div
                v-for="(items, key) in tickerInfo"
                :key="key"
                class="flex items-center justify-between cursor-pointer px-2 dark:hover:!bg-[#2D2D2D30] hover:bg-appBtnHover border-b dark:border-b-[#2D2D2D] py-4 border-b-[#E7E7E7]"
              >
                <div
                  class="flex items-start sm:space-x-4 space-x-1 -bg-green-300"
                >
                  <div class="flex items-center gap-2">
                    <div>
                      <div
                        class="w-12 h-12 flex uppercase items-center dark:bg-[#676868] dark:text-[#f6f6f6] justify-center rounded-full bg-[#E2E8F0]"
                      >
                        <TickerIcon :ticker="items.symbol" />
                      </div>
                    </div>
                    <div>
                      <div class="dark:text-[#F6F6F6] -bg-green-600">
                        <p
                          class="font-[300] text-[1.3rem]"
                          :title="items?.companyName"
                        >
                          <span
                            class="font-[600] mr-2"
                            :class="screenWidth < 361 ? 'text-[12px]' : ''"
                            >{{ items?.symbol }}</span
                          ><span class="!font-normal dark:text-[#ffffff90]">{{
                            getTruncateText(items?.companyName)
                          }}</span>
                        </p>
                      </div>
                      <div
                        class="flex sm:items-center sm:space-x-5 -bg-green-400"
                      >
                        <div class="flex sm:items-center sm:space-x-3">
                          <!-- <p class="font-[600] dark:text-white priceText">
                            {{
                              item?.eodHistoryData?.close
                                ? `$ ${item?.eodHistoryData?.close}`
                                : '$0'
                            }}
                            <span
                              class="text-[12px] changeText ml-1 font-[500] text-[#037950]"
                              >{{ item?.eodHistoryData?.change || '0' }}
                              {{
                                item?.eodHistoryData?.changepercent
                                  ? `${item?.eodHistoryData?.changepercent}%`
                                  : '(0.0%)'
                              }}
                            </span>
                          </p> -->
                          <span
                            class="text-[13px] text-[#000000] dark:text-[green]"
                            >${{ items?.close || 0 }}</span
                          >
                          <span
                            :class="
                              items?.change < 0
                                ? 'text-errorText'
                                : ' text-[#037950]'
                            "
                            class="text-[11px]"
                            >{{ items?.change?.toFixed(2) || 0 }} ({{
                              items.changePercent?.toFixed(2) || 0
                            }}%)</span
                          >
                          <div>
                            <span
                              class="text-[11px] dark:text-[#DEDEDE] text-[#323232]"
                              >{{ items?.exchange }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="cursor-pointer text-[20px]"
                  @click="addSymbol(items)"
                >
                  <svg
                    width="36"
                    height="36"
                    :class="
                      getColor(items) ? 'text-[#41901C]' : 'text-[#BCBCBC]'
                    "
                    viewBox="0 0 36 36"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 33C10.9289 33 7.39339 33 5.1967 30.8032C3 28.6066 3 25.071 3 18C3 10.9289 3 7.39339 5.1967 5.1967C7.39339 3 10.9289 3 18 3C25.071 3 28.6066 3 30.8032 5.1967C33 7.39339 33 10.9289 33 18C33 25.071 33 28.6066 30.8032 30.8032C28.6066 33 25.071 33 18 33ZM18 12.375C18.6213 12.375 19.125 12.8787 19.125 13.5V16.875H22.5C23.1213 16.875 23.625 17.3787 23.625 18C23.625 18.6213 23.1213 19.125 22.5 19.125H19.125V22.5C19.125 23.1213 18.6213 23.625 18 23.625C17.3787 23.625 16.875 23.1213 16.875 22.5V19.125H13.5C12.8787 19.125 12.375 18.6213 12.375 18C12.375 17.3787 12.8787 16.875 13.5 16.875H16.875V13.5C16.875 12.8787 17.3787 12.375 18 12.375Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="w-full h-full flex items-center justify-center text-[16px] text-appBlue dark:text-[#F6F6F6] font-[500]"
              v-else
            >
              <p>No Symbol Found</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full h-[80px] flex flex-col jus items-center -bg-slate-400 -border-t mt-[-36px] -px-3"
      >
        <div class="text-errorText h-[20px] mr-2">
          {{ showError }}
        </div>

        <!-- Footer -->
        <div
          class="w-full !h-[60px] border-t border-[#E2E8F0] -!bg-slate-500 px-3 flex flex-row justify-between items-center space-x-3"
        >
          <ModalFooter
            firstTitle="Close"
            secondTitle="Save"
            colorType="color"
            :isLoading="isLoading"
            @close="closeModal"
            @save="saveSymbol"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import postRequest from '../api/postRequest'
import ModalHeader from './ModalHeader.vue'
import { serverUrl } from '../api/serverUrl'
import Loader from '../component/Loading.vue'
import { modifyResult } from '../utils/common'
import { getSvgByName } from '../assets/Navbarsvgs/SvgUtils'
import ModalFooter from './ModalFooter.vue'
import moment from 'moment'
import { fetchSymbolByName } from '../services/symbol'
import TickerIcon from './TickerIcon'
import { marketsData } from '../data/markets'


const localMarket = marketsData.map((m) => {
  return { ...m, show: true }
})

const getLocalMarketClone = () => {
  return JSON.parse(JSON.stringify(localMarket))
}

export default {
  name: 'addWatchlistSymbolModal',

  props: {
    // selectedWatchlistId: String,
  },

  data() {
    return {
      markets: getLocalMarketClone(),
      selectedMarket:'ASX',
      inputValue: '',
      showError: '',
      isLoading: false,
      isSymbolLoading: false,
      currentWatchlistName: '',
      header: [
        { id: 1, name: 'All', value: 'all' },
        { id: 2, name: 'Recently Searched', value: 'recentlySearched' },
        { id: 3, name: 'Frequently Searched', value: 'frequentlySearched' },
        { id: 4, name: 'Good Stocks', value: 'goodStocks' },
        { id: 5, name: '{Scanner}', value: 'scanner' },
        { id: 6, name: '{Scanner}', value: 'scanner1' },
        { id: 7, name: '{Scanner}', value: 'scanner2' },
        { id: 8, name: '{Scanner}', value: 'scanner3' },
      ],

      selectectedSymbol: [],
      selectedAlphabet: { key: 'a-d', value: 'A-D' },
      selectedVal: { key: 'all', value: 'All' },
      screenWidth: window.innerWidth,
      tickerInfo: [],
      symbolAdded: false,
      isIcon: 'addicon',
    }
  },

  components: {
    Loader,
    ModalHeader,
    ModalFooter,
    TickerIcon,
  },

  computed: {
    ...mapGetters(['getTheme', 'getAllWatchlist', 'getSelectedWatchlist']),
    filteredContent() {
      return this.tickerInfo?.filter((item) =>
        item?.ticker?.toLowerCase().includes(this.inputValue?.toLowerCase())
      )
    },
  },
  methods: {
    ...mapActions(['addToWatchlist', 'addNewSymbolToList']),

    handleMarket(marketCode){
      this.selectedMarket = marketCode
      if(this.inputValue){
        this.onInputChange(this.inputValue)
      }else{
        this.onInputChange('A')
      }
    },

    updateData() {
      let updatedArr = this.getSelectedWatchlist?.symbolList?.map((item) => {
        return {
          symbol: item.name,
          exchange: item?.id,
        }
      })
      this.selectectedSymbol = updatedArr

      // let watchFind = this.getAllWatchlist?.find(
      //   (item) => item?.id == this.selectedWatchlistId
      // )
      // this.currentWatchlistName = watchFind?.watchlistName
      // if (watchFind?.symbols?.length > 0) {
      //   this.selectectedSymbol = watchFind?.symbols
      // }
    },

    async onInputChange(text) {
      this.isSymbolLoading = true
      if (!text) {
        let filtered2 = await fetchSymbolByName(this, '1')
        let result = modifyResult(filtered2)
        this.tickerInfo = result?.filter(item => item.exchange == this.selectedMarket)
        this.openSymbolDropdown = true
        this.$store.commit('TOP_LIST_SYMBOL', '1')
        this.$store.commit('TOP_LIST_SYMBOL_NAME', '1')
        this.optionDisplays = 'none'
        return
      } else {
        let filtered2 = await fetchSymbolByName(this, text)
        this.optionDisplays = 'block'
        let result = modifyResult(filtered2)
        this.tickerInfo = result?.filter(item => item.exchange == this.selectedMarket)
        console.log("result",this.tickerInfo)
        this.openSymbolDropdown = true
        if (this.filteredOptions && this.filteredOptions.length) {
          this.$store.commit(
            'TOP_LIST_SYMBOL',
            JSON.stringify(filtered2[0].data[0].symbol)
          )
          this.$store.commit(
            'TOP_LIST_SYMBOL_NAME',
            JSON.stringify(filtered2[0].name)
          )
        } else {
          this.$store.commit('TOP_LIST_SYMBOL', '')
          this.$store.commit('TOP_LIST_SYMBOL_NAME', '')
          this.optionDisplays = 'none'
        }
      }
      this.isSymbolLoading = false
    },
    addSymbol(sym) {
      if (this.selectectedSymbol?.length == 20) {
        alert('You cannot add more than 20 symbols')
      } else {
        let isPresent = this.selectectedSymbol?.some(
          (val) => val?.symbol == sym?.symbol
        )
        if (isPresent) {
          return
        } else {
          this.selectectedSymbol = [...this.selectectedSymbol, sym]
          console.log('selectectedSymbol', this.selectectedSymbol)
        }
      }
    },
    async saveSymbol() {
      let updatedArr = this.selectectedSymbol?.map((item) => {
        return {
          name: item.symbol,
          id: item?.exchange,
        }
      })
      this.isLoading = true
      let res = await this.addNewSymbolToList(updatedArr)
      if (res) {
        this.isLoading = false
        this.closeModal()
      } else {
        this.showError = 'Some error occured while adding symbol to watchlist'
        setTimeout(() => {
          this.showError = ''
        }, 5000)
      }
      this.isLoading = false
      // if (this.selectedWatchlistId) {
      //   let payload = {
      //     id: this.selectedWatchlistId,
      //     symbols: this.selectectedSymbol,
      //   }
      //   this.updateWatchlistSymbolList(payload)
      //   this.closeModal()
      // } else {
      //   let watchList = {
      //     id: '',
      //     watchlistName: 'My watchlist',
      //     updatedTime: '',
      //     symbols: [],
      //   }
      //   if (this.selectectedSymbol.length == 0) {
      //     alert('Please select atleast one symbol othervise close the modal')
      //   } else {
      //     // Format with custom date and time
      //     const currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss a')
      //     // console.log(`Current date and time: ${currentDateTime}`)

      //     watchList.id = Math.random().toString(36).substring(2, 15)

      //     watchList.updatedTime = currentDateTime
      //     watchList.symbols = this.selectectedSymbol
      //     this.addToWatchlist(watchList)
      //     this.closeModal()
      //   }
      // }
    },
    getColor(item) {
      let isPresent = this.selectectedSymbol?.some(
        (val) => val?.symbol == item?.symbol
      )
      if (isPresent) {
        return true
      } else {
        return false
      }
    },
    removeSymbol(sym) {
      let updatedArr = this.selectectedSymbol.filter(
        (item) => item?.symbol !== sym?.symbol
      )
      this.selectectedSymbol = updatedArr
    },

    customClass(item) {
      // const checkid = this.selectectedSymbol.find((it) => it.id === item?.id)
      const added = this.selectectedSymbol[0].key?.i
      if (added === item?.key) {
        this.symbolAdded = true
        console.log('checkid added', added, this.symbolAdded)
        return `${(this.isIcon = 'addedicon')}`
      } else {
        this.symbolAdded = false
        console.log('checkid added', added, this.symbolAdded)

        return `${(this.isIcon = 'addicon')}`
      }
    },
    // console.log('item', item)

    // if (item?.key?.symbol === this.inputValue?.toUpperCase()) {
    //   this.inputValue = ''
    // }

    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },
    getSvg(name) {
      return getSvgByName(name)
    },

    clearInput() {
      this.inputValue = ''
    },
    getTruncateText(val) {
      let maxLength = 15
      if (this.screenWidth > 500) {
        if (val?.length > maxLength && this.screenWidth < 1151) {
          return val?.substring(0, maxLength) + '..'
        } else {
          if (val?.length > 30) {
            return val?.substring(0, 30) + '..'
          } else {
            return val
          }
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
    handleData(item) {
      this.selectedVal = item
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },

  watch: {
    inputValue(val) {
      if (val) {
        // this.handleSymbolData()
        this.onInputChange(val)
      } else {
        this.tickerInfo = []
      }
    },
  },

  mounted() {
    this.updateData()
      this.onInputChange('A')
    // Add event listener when the component is mounted
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}

.scroll-container {
  /* Adjust height as needed */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>

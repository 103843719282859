<template>
  <div
    class="outer-container bg-white dark:!bg-[#1A1A1A] dark:text-[#F6F6F6]"
    ref="outerContainer"
  >
    <div class="left-resize-handle"></div>
    <div class="w-full !h-[81%] flex flex-col -bg-slate-300">
      <!-- RightBarHead Component -->

      <!-- @handleOpenEditWatchlistModal="handleOpenEditWatchlistModal"
      @handleDeleteWatchlist="handleDeleteWatchlist" -->

      <div v-if="selectedRight === 'watchlist'" class="w-full h-full">
        <WatchList
          @handleOpenWatchlistDetailModal="handleOpenWatchlistDetailModal"
          @handleOpenDownloadSymbolModal="handleOpenDownloadSymbolModal"
          @openAddSymbolModal="openAddSymbolModal"
          :selectedRight="selectedRight"
        />
      </div>

      <div
        v-if="selectedRight === 'scannerResults'"
        class="w-full h-full -bg-slate-500"
      >
        <ScannerResult :selectedRight="selectedRight" />
      </div>

      <div v-if="selectedRight === 'trainingMode'" class="w-full h-full">
        <TrainingMode :selectedRight="selectedRight" />
      </div>

      <div v-if="selectedRight === 'comparingSymbols'" class="w-full h-full">
        <ComparingSymbolsRight :selectedRight="selectedRight" />
      </div>

      <!-- Training Mode -->

      <!-- <div
        v-if="selectedRight === 'trainingMode'"
        class="bg-white dark:!bg-[#1A1A1A] w-full !h-[83vh]"
      >
        <div
          v-for="(item, index) in trainingData"
          :key="index"
          class="bg-white dark:!bg-[#1A1A1A] w-full !h-[4rem] flex justify-between items-center px-4"
        >
          <div>
            <span>{{ item.name }}</span>
          </div>
          <div>
            <span>{{ item.price }}</span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div
      v-if="selectedRight == 'watchlist'"
      class="bg-white dark:!bg-[#1A1A1A] w-full !h-[83vh]"
    >
      <div
        class="flex items-center justify-between px-2 h-[4rem] w-full border-b dark:border-[#2D2D2D]"
      >
        <div
          @click="handleOpenWatchlistDetailModal"
          class="flex items-center gap-2 h-[2.8rem] dark:hover:!bg-[#2D2D2D] hover:bg-[#F0F3FA] rounded-sm py-3 px-2 -w-[20%] cursor-pointer -border"
        >
          <span
            class="text-[#131729] dark:text-[#F6F6F6] text-[14px] font-[600]"
            >{{ getSelectedWatchlist?.name || 'watchlist' }}</span
          >
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>

        <div class="flex items-center gap-2 mr-2 h-[3rem]">
          <span
            title="Add symbol"
            @click="handleOpenSymbolModal"
            :class="
              getSelectedWatchlist?.name ? '' : 'pointer-events-none opacity-50'
            "
            class="flex justify-center items-center cursor-pointer h-[2.8rem] w-[2.8rem] dark:hover:!bg-[#2D2D2D] hover:bg-[#F0F3FA] rounded-sm !z-20"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </span>

          <span
            @click="openAdvancedViewModal"
            class="flex justify-center items-center cursor-pointer h-[2.8rem] w-[2.8rem] dark:hover:!bg-[#2D2D2D] hover:bg-[#F0F3FA] rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
              fill="none"
            >
              <path d="M0 0h28v28H0z"></path>
              <path
                stroke="currentColor"
                d="M13.5 23.5a9 9 0 0 0 9-9h-9v-9a9 9 0 0 0 0 18zM22 6l-6.5 6.5m4-8l-4 4m8 0l-4 4"
              ></path>
              <path
                stroke="currentColor"
                d="M24.5 12.5a9 9 0 0 0-9-9v9h9z"
              ></path>
            </svg>
          </span>

          <span
            @click="openSettingsModal"
            class="flex justify-center items-center cursor-pointer h-[2.8rem] w-[2.8rem] dark:hover:!bg-[#2D2D2D] hover:bg-[#F0F3FA] rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="currentColor"
                d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"
              ></path>
            </svg>
          </span>
        </div>
      </div>

      <div class="w-full h-[calc(96vh-12rem)] dark:bg-[#1A1A1A]">
        <SymbolTable
          @open-Remove-Symbol-Modal="openRemoveSymbolModal"
          @getSymbolData="handleChartUpdate"
          :filteredSymbols="getSelectedWatchlist?.symbolList"
          :isDisplay="isdisplay"
        />
      </div>
    </div> -->

    <!-- <div
      v-if="selectedRight == 'scanner'"
      class="bg-white dark:!bg-[#1A1A1A] w-full !h-[83vh]"
    >
      <ScannerUi @symbolPress="handleChartUpdate" />
    </div> -->

    <!-- <div
      v-if="selectedRight == 'trainingMode'"
      class="bg-white dark:!bg-[#1A1A1A] w-full !h-[83vh]"
    >
      <TrainingModeComp />
    </div> -->

    <div
      class="!w-full resizable-box overflow-x-hidden -dark:!bg-[#1A1A1A] -bg-white scroll-container"
      :class="getSymbolPrice ? '' : '!overflow-y-hidden'"
      :style="{
        width: boxWidth + 'px',
        left: boxLeft + 'px',
        height: boxHeight + 'px',
        top: boxTop + 'px',
        zIndex: 100,
      }"
      ref="box"
    >
      <div
        class="resize-handle !bg-[#E0E3EB] -!bg-[#c6d1d8] z-[100] flex justify-center items-center !sticky top-0 dark:!bg-[#7a8290]"
        @mousedown="startResize"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23 23"
          fill="currentColor"
          class="size-10"
        >
          <path
            fill-rule="evenodd"
            d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        class="content w-full h-full oevrflow-x-hidden -bg-black z-[100] dark:!bg-[#1A1A1A] !text-[#000] dark:!text-[#F6F6F6]"
      >
        <TickerInfo />
      </div>
    </div>

    <!-- 1 AddWatchlistSymbolModal -->

    <div v-if="isOpenAddSymbolModal">
      <BaseModal>
        <AddWatchlistSymbolModal
          @closeModal="handleCloseWatchlistSymbolModal"
        />
      </BaseModal>
    </div>

    <!-- 2 DownloadSymbolModal -->

    <div v-if="isOpenDownloadSymbolModal" style="z-index: 12005">
      <BaseModal>
        <DownloadSymbolModal
          @close-Download-Modal="handleCloseDownloadSymbolModal"
        />
      </BaseModal>
    </div>

    <!-- 3 WatchlistDetailModal -->

    <div class="absolute w-full" v-if="isOpenWatchlistDetailModal">
      <BaseModal>
        <WatchlistModalTv
          @closeModal="handleCloseWatchlistDetailModal"
          @handleOpenDownloadSymbolModal="handleOpenDownloadSymbolModal"
          @handleOpenEditWatchlistModal="handleOpenEditWatchlistModal"
          @handleOpenRemoveWatchlistModal="handleOpenRemoveWatchlistModal"
          @handleOpenAddWatchlistModal="handleOpenAddWatchlistModal"
          :watchlistData="getSelectedWatchlist"
        />
      </BaseModal>
    </div>

    <!-- 4 EditWatchlistModal -->
    <div v-if="isOpenEditWatchlistModal" style="z-index: 12005">
      <BaseModal>
        <EditWatchlistModal
          @close-Edit-Modal="handleCloseEditWatchlistModal"
          :isAdd="isAddModal"
        />
      </BaseModal>

      <!-- 5 DeleteWatchlistModal -->
    </div>
    <div v-if="isOpenDeleteWatchlistModal" style="z-index: 12005">
      <BaseModal>
        <RemoveWatchlistModal
          @closeModal="handleCloseRemoveWatchlistModal"
          :deleteWatchlistId="deleteWatchId"
        />
      </BaseModal>
    </div>

    <!-- 6 RemoveSymbolModal -->

    <div v-if="isOpenRemoveSymbolModal">
      <BaseModal>
        <RemoveSymbolModal
          @closeRemoveModal="handleCloseRemoveSymbolModal"
          :data="removeSymbol"
        />
      </BaseModal>
    </div>
  </div>
</template>
<script>
import StackInfoVue from './StackInfo.vue'
import ScanResultVue from './ScanResult.vue'
import MyWatchListVue from './MyWatchList.vue'
import WatchlistModalTv from './WatchlistModalTv.vue'
import RemoveWatchlistModal from './RemoveWatchlistModal.vue'
import RemoveSymbolModal from './RemoveSymbolModal.vue'
import EditWatchlistModal from './EditWatchlistModal.vue'
import AddWatchlistSymbolModal from './AddWatchlistSymbolModal.vue'
import DownloadSymbolModal from './DownloadSymbolModal.vue'
import TickerInfo from './TickerInfo.vue'
import { mapActions, mapGetters } from 'vuex'
import Minimize from './Minimize.vue'
import SymbolTable from './SymbolTable.vue'
import BaseModal from './BaseModal.vue'
import ScannerUi from './ScannerUi.vue'
import TrainingModeComp from './TrainingModeComp'
import RightBarComp from './RightBarComp.vue'
import RightBarHead from './RightBarHead.vue'
import WatchList from './RightSideComponent/Watchlist/WatchList.vue'
import ScannerResult from './RightSideComponent/ScannerResult/ScannerResult.vue'
import TrainingMode from './RightSideComponent/TrainingMode/TrainingMode.vue'
import ComparingSymbolsRight from './RightSideComponent/ComparingSymbols/ComparingSymbolsRight.vue'
import { chartEvents } from '../chartEvents'

export default {
  name: 'App',
  data() {
    return {
      isMinimizeStock: true,
      isMinimizeScan: true,
      isAddModal: false,
      isMinimizeWatch: true,
      isOpenEditWatchlistModal: false,
      isOpenDeleteWatchlistModal: false,
      isOpenRemoveSymbolModal: false,
      isOpenWatchlistDetailModal: false,
      isOpenDownloadSymbolModal: false,
      isOpenAddSymbolModal: false,
      isdisplay: true,
      boxHeight: 0,
      tHeight: 0,

      width: innerWidth,

      trainingData: [
        {
          id: 1,
          name: 'Open',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'High',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Low',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Close',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Volume',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'Value',
          price: '$220.32',
        },
        {
          id: 1,
          name: 'VWAP',
          price: '$220.32',
        },
      ],
      symbols: [],

      windowHeight: window.innerHeight,
      initialHeight: 82, // Initial height of the box
      boxHeight: 535, // 535 Current height of the box
      boxTop: 328, // 328 Initial top position of the box
      startY: 0, // Initial mouse Y position
      startHeight: 0, // Initial height before resizing
      startTop: 0, // Initial top position before resizing
      isResizing: false, // Track whether top resizing is active
      deleteWatchId: null,
      initialWidth: 200, // Initial width of the box
      boxWidth: 420, // Current width of the box
      boxLeft: 0, // Initial left position of the box
      startX: 0, // Initial mouse X position
      startWidth: 0, // Initial width before resizing
      startLeft: 0, // Initial left position before resizing
      isLeftResizing: false, // Track whether left resizing is active
      removeSymbol: null,
    }
  },
  components: {
    TrainingModeComp,
    StackInfoVue,
    ScanResultVue,
    MyWatchListVue,
    Minimize,
    SymbolTable,
    TickerInfo,
    BaseModal,
    RemoveWatchlistModal,
    EditWatchlistModal,
    RemoveSymbolModal,
    RightBarHead,
    ScannerUi,
    RightBarComp,
    WatchList,
    ScannerResult,
    TrainingMode,
    WatchlistModalTv,
    DownloadSymbolModal,
    AddWatchlistSymbolModal,
    ComparingSymbolsRight,
  },
  props: {
    selectedRight: String,
    wHeight: Number,
  },

  methods: {
    ...mapActions([
      'addToWatchlist',
      'updatedWatchlist',
      'removedWatchlist',
      'updateCopySymbol',
    ]),

    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },

    // WatchlistModalTv
    handleOpenWatchlistDetailModal() {
      this.isOpenWatchlistDetailModal = true
    },

    handleCloseWatchlistDetailModal() {
      this.isOpenWatchlistDetailModal = false
    },

    handleCloseRemoveSymbolModal() {
      this.removeSymbol = null
      this.isOpenRemoveSymbolModal = false
    },
    closeWatchlistModal() {
      this.isAddModal = false
      this.isOpenWatchlistDetailModal = false
    },

    // RemoveWatchlistSymbolModal
    openAddSymbolModal() {
      this.isOpenAddSymbolModal = true
      console.log('openAddSymbolModal', this.isOpenAddSymbolModal)
    },

    handlecloseSymbolModal() {
      this.isOpenRemoveSymbolModal = false
    },

    // EditWatchlistModal
    handleOpenEditWatchlistModal() {
      this.isOpenEditWatchlistModal = true
    },
    handleOpenRemoveWatchlistModal(id) {
      this.deleteWatchId = id
      this.isOpenDeleteWatchlistModal = true
    },
    handleCloseWatchlistSymbolModal() {
      this.isOpenAddSymbolModal = false
    },
    handleOpenAddWatchlistModal() {
      this.isAddModal = true
      this.isOpenEditWatchlistModal = true
    },

    handleCloseEditWatchlistModal() {
      this.isAddModal = false
      this.isOpenEditWatchlistModal = false
      this.updateCopySymbol([])
    },
    handleCloseRemoveWatchlistModal() {
      this.deleteWatchId = null
      this.isOpenDeleteWatchlistModal = false
    },
    // OpenDownloadSymbolModal

    handleOpenDownloadSymbolModal(watchlist) {
      this.isOpenDownloadSymbolModal = true
      // conosole.log(watchlistdataSymbol)
      // if (watchlist.length > 0) {
      //   this.isOpenDownloadSymbolModal = true
      //   console.log('download 1')
      // } else if (this.getSelectedWatchlist.length > 0) {
      //   this.isOpenDownloadSymbolModal = true
      //   console.log('download 2')
      // } else {
      //   alert('First add symbol in your watchlist.')
      //   console.log('download 3')
      // }
    },

    handleCloseDownloadSymbolModal() {
      this.isOpenDownloadSymbolModal = false
    },

    openSettingsModal() {
      alert('Under construction SettingsModal')
    },

    // Resizable box
    startResize(event) {
      if (this.$refs.box) {
        this.startY = event.clientY
        this.startHeight = this.$refs.box.offsetHeight
        this.startTop = this.boxTop
        this.isResizing = true
        document.addEventListener('mousemove', this.resize)
        document.addEventListener('mouseup', this.stopResize)
      }
    },
    resize(event) {
      if (this.isResizing && this.$refs.outerContainer) {
        const dy = event.clientY - this.startY
        const newHeight = this.startHeight - dy
        const newTop = this.startTop + dy

        // Get the parent container height
        const containerHeight = this.$refs.outerContainer.offsetHeight

        // Prevent the box from going outside the container and below its initial height
        if (
          newHeight >= this.initialHeight &&
          newTop >= 0 &&
          newTop + newHeight <= containerHeight
        ) {
          this.boxHeight = newHeight
          this.boxTop = newTop
        }
      }
    },
    stopResize() {
      this.isResizing = false
      document.removeEventListener('mousemove', this.resize)
      document.removeEventListener('mouseup', this.stopResize)
    },
    startLeftResize(event) {
      if (this.$refs.box) {
        this.startX = event.clientX
        this.startWidth = this.$refs.box.offsetWidth
        this.startLeft = this.boxLeft
        this.isLeftResizing = true
        document.addEventListener('mousemove', this.leftResize)
        document.addEventListener('mouseup', this.stopLeftResize)
      }
    },
    leftResize(event) {
      if (this.isLeftResizing && this.$refs.outerContainer) {
        const dx = event.clientX - this.startX
        const newWidth = this.startWidth - dx
        const newLeft = this.startLeft + dx

        // Get the parent container width
        const containerWidth = this.$refs.outerContainer.offsetWidth

        // Prevent the box from going outside the container and below its initial width
        if (
          newWidth >= this.initialWidth &&
          newLeft >= 0 &&
          newLeft + newWidth <= containerWidth
        ) {
          this.boxWidth = newWidth
          this.boxLeft = newLeft
        }
      }
    },
    stopLeftResize() {
      this.isLeftResizing = false
      document.removeEventListener('mousemove', this.leftResize)
      document.removeEventListener('mouseup', this.stopLeftResize)
    },

    // openAddSymbolModal(id) {
    //   this.$emit('openAddSymbolModal', id)
    // },
    openEditWatchlist(item) {
      console.log('edit emit', item)
      this.$emit('openEditWatchlist', item)
    },
    
    handleAddWatchlist() {
      this.$emit('handleAddWatchlist')
    },
    onDeleteWatchlistSymbol(item) {
      this.$emit('openRemoveWatchlistSymbol', item)
    },
    // handleChartUpdate(sym) {
    //   this.$emit('updateChart', sym)
    // },
    // openDownloadSymbol(item) {
    //   console.log('download item', item)
    //   this.$emit('openDownloadSymbol', item)
    // },
  },
  computed: {
    ...mapGetters(['getSelectedWatchlist', 'getSymbolPrice']),

    // rboxStyle() {
    //   if (this.windowHeight >= 756) {
    //     this.boxHeight = 535
    //     this.boxTop = 328
    //     console.log(
    //       'this.boxHeight',
    //       this.boxHeight,
    //       'this.boxTop',
    //       this.boxTop
    //     )
    //   }
    // },
  },
  mounted() {
    
    
    
    const openRemoveSymbolModalFunc = (sym) => {
      this.removeSymbol = sym
      this.isOpenRemoveSymbolModal = true
      console.log('delete 1', this.isOpenRemoveSymbolModal)
    }
    
    window.addEventListener('resize', this.updateScreenWidth)

    chartEvents.$on('openRemoveSymbolModal', openRemoveSymbolModalFunc)

    // Ensure refs are set correctly
    if (this.$refs.box) {
      this.boxHeight = this.$refs.box.offsetHeight
      this.boxWidth = this.$refs.box.offsetWidth
      this.boxTop = this.$refs.box.offsetTop
      this.boxLeft = this.$refs.box.offsetLeft
    }

    if (this.windowHeight >= 800) {
      this.boxTop = 328
      this.boxHeight = 535
    } else if (this.windowHeight >= 756) {
      this.boxTop = 280
      this.boxHeight = 420
    } else {
      this.boxTop = 779
    }
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateScreenWidth)
    chartEvents.$off('openRemoveSymbolModal', sym)
  },
  watch: {
    selectedRight() {
      console.log('selectedRightW', this.selectedRight)
    },
    rboxStyle() {
      windowHeight
    },
  },
}
</script>
<style scoped>
.table-container {
  overflow-y: auto;
  max-height: 400px; /* Adjust height as needed */
}

th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Background color for the header */
  z-index: 10;
}

.outer-container {
  position: relative;
  width: 32.5rem;
  height: 960px;
  /* background-color: green; Maximum height of the container */
  overflow: hidden; /* Prevent overflow */
}

.resizable-box {
  position: absolute;
  width: 100%;
  overflow: auto;
  transition: all;
}

.content {
  height: 100%;
  width: 100%;
  /* background-color: gray; Darker slate color */
  /* padding: 1rem; */
}

.resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  width: 100%;
  cursor: ns-resize;
  background-color: rgba(0, 0, 0, 0.1);
}

.left-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.5px;
  cursor: ew-resize;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
}
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
} */
</style>

<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Events'">
      <events-filter :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Technical Indicators'">
      <IndicatorsComp :id="id" />
    </div>
  </div>
</template>
    
    <script>
import EventsFilter from './EventsFilter.vue'
import Filters from './Filters.vue'
import IndicatorsComp from '../../IndicatorsComp.vue'

export default {
  name: 'Indicators',
  components: { Filters, EventsFilter, IndicatorsComp },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: 'indicators',
      dialogId: 'chartEvent',
    }
  },
  methods: {},
  computed: {},
  mounted() {},
}
</script>
    
    <style>
</style>
<template>
  <div class="space-y-2 w-full h-full">
    <!-- first filter -->
    <Wrapper
      header="Higher High Higher Low"
      id="hhhl"
      @is-active="isActiveHandler"
      :activeClass="true"
      :selectedKey="'bigcandle'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">Pattern Detection</h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>No input required.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`This scan identifies stocks that are consistently making higher highs and higher lows, signaling a potential continuation of an uptrend.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'HigherHighHigherLowComp',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      isToggle: false,

      scanType: 'HigherHighHigherLowComp',
    }
  },
  components: { DescriptionInput, Wrapper },
  methods: {
    ...mapActions(['sendHigherHighHigherLowPatternRequest', 'cancelGuruScans']),
    isActiveHandler(){
      console.log("isActiveHandler");
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendHigherHighHigherLowPatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
  },
  computed: {
    /** @returns {Boolean} */
    isValid() {
      return true
    },
  },
}
</script>

<style></style>

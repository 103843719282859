<template>
  <div
    class="xxs:w-[88vw] lg:!w-[70vw] xl:!w-[48vw] h-[48rem] rounded-[8px] -bg-slate-400 overflow-hidden"
  >
    <!--Header-->

    <div
      class="w-full h-[60px] -bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
    >
      <div @click="watchlistOpen" v-if="isWatchlistSelect == false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </div>
      <ModalHeader :header="`Watchlists`" @close="closeWatchlistDetailModal" />
    </div>

    <!-- Search Bar -->

    <!-- <div
      class="w-full border-t border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-4 py-[8px] items-center flex space-x-2 dark:border-[#232323]"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <path
            stroke="#a3a6af"
            d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
          ></path>
        </svg>
      </span>
      <span class="w-full">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Search lists"
          class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
        />
      </span>
    </div> -->

    <!-- main  -->

    <div class="flex w-full h-[80%] border-t overflow-hidden">
      <!-- Left Area -->
      <div class="xxs:!w-full qm:!w-[275px] flex flex-col justify-center">
        <!-- Watchlist -->
        <div
          v-if="isWatchlistSelect"
          class="flex flex-col justify-between xxs:!w-full qm:!w-[275px] qm:shrink-0 border-r dark:border-[#2D2D2D] h-full py-3"
        >
          <div class="w-full h-full">
            <div
              v-if="
                width <= 768
                  ? (widthIsLessThan768 = true)
                  : (widthIsLessThan768 = false)
              "
              class="w-full h-[94%] overflow-y-scroll scroll-container"
            >
              <div
                @mouseover="mouseOverOnWatchlist(item.id)"
                @mouseleave="mouseLeaveWatchlist()"
                @click="handleSelectWatchlist(item.id, index)"
                v-for="(item, index) in watchLists"
                :key="index"
                :class="
                  getSelectedWatchlist?.id == item.id
                    ? 'bg-appBtnActive -hover:!bg-[#2962FF] -dark:border-appBtnActive dark:bg-blueHover text-appBtnActiveTxt'
                    : 'hover:bg-[#F0F3FA] dark:hover:bg-[#FFFFFF1A] text-[#000]'
                "
                class="w-full h-[3rem] flex xxs:px-5 qm:px-3 cursor-default justify-between items-center border-b border-[#f0f0f3] dark:border-[#2D2D2D]"
              >
                <div
                  :class="
                    getSelectedWatchlist?.id == item.id
                      ? 'text-appBtnActiveTxt'
                      : 'text-[#000] dark:text-[#F6F6F6]'
                  "
                  class="w-full flex justify-between text-[15px] h-auto py-1 -bg-slate-500"
                >
                  <div
                    class="xxs:w-[46%] xs:w-[40%] sm:w-[30%] flex items-center justify-between"
                  >
                    <div class="w-auto">
                      <span
                        @click="gotoSymbols(item.id, index)"
                        class="w-auto shrink-0 cursor-pointer"
                        :class="
                          widthIsLessThan768 < 768
                            ? '!border-b'
                            : '!border-none'
                        "
                      >
                        {{ item?.name }}
                      </span>
                    </div>

                    <div class="flex space-x-2 !mr-[-5rem]">
                      <div
                        @click="openEditWatchlist(index)"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-[#F6F6F6] hover:bg-[#2250ce] dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-[#ced0d6] dark:hover:bg-[#FFFFFF1A]',

                          // isMouseOverOnWatchlist == item.id
                          //   ? 'visible'
                          //   : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-[#F6F6F6]'
                              : 'text-[#000] dark:text-[#F6F6F6] '
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                      </div>

                      <div
                        @click="openDeleteWatchlist(item?.id)"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-appBtnTxt hover:bg-[#2250ce] dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-appBtnHover dark:hover:bg-[#FFFFFF1A]',

                          // isMouseOverOnWatchlist == item.id
                          //   ? 'visible'
                          //   : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-[#F6F6F6]'
                              : 'text-[#000] dark:text-[#F6F6F6]'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 18 18"
                          width="18"
                          height="18"
                        >
                          <path
                            fill="currentColor"
                            d="M12 4h3v1h-1.04l-.88 9.64a1.5 1.5 0 0 1-1.5 1.36H6.42a1.5 1.5 0 0 1-1.5-1.36L4.05 5H3V4h3v-.5C6 2.67 6.67 2 7.5 2h3c.83 0 1.5.67 1.5 1.5V4ZM7.5 3a.5.5 0 0 0-.5.5V4h4v-.5a.5.5 0 0 0-.5-.5h-3ZM5.05 5l.87 9.55a.5.5 0 0 0 .5.45h5.17a.5.5 0 0 0 .5-.45L12.94 5h-7.9Z"
                          ></path>
                        </svg>
                      </div>

                      <div
                        @click="handleCopy(item)"
                        v-if="item.symbolList?.length > 0"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-appBtnTxt hover:bg-[#2250ce] dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-[#ced0d6] dark:hover:bg-[#FFFFFF1A]',

                          // isMouseOverOnWatchlist == item.id
                          //   ? 'visible'
                          //   : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-appBtnTxt'
                              : 'text-[#000] dark:text-[#F6F6F6]'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="
                    getSelectedWatchlist?.id == item.id
                      ? 'text-appBtnActiveTxt'
                      : 'text-[#000] dark:text-[#F6F6F6]'
                  "
                  class="text-[15px] text-[#000] flex justify-between items-center h-auto"
                >
                  {{ item?.symbolList?.length }}
                </div>
              </div>
            </div>

            <!-- watchlist show when width is geater than 768 -->
            <div
              v-if="widthIsLessThan768 == false"
              class="w-full h-[94%] overflow-y-scroll scroll-container"
            >
              <div
                @mouseover="mouseOverOnWatchlist(item.id)"
                @mouseleave="mouseLeaveWatchlist()"
                @click="handleSelectWatchlist(item.id, index)"
                v-for="(item, index) in watchLists"
                :key="index"
                :class="
                  getSelectedWatchlist?.id == item.id
                    ? 'bg-appBtnActive -hover:!bg-[#2962FF] -dark:border-appBtnActive dark:bg-blueHover text-[#F6F6F6]'
                    : 'hover:bg-appBtnHover dark:hover:bg-[#FFFFFF1A] text-[#000]'
                "
                class="w-full h-[3rem] flex xxs:px-5 qm:px-3 cursor-default justify-between items-center border-b border-[#f0f0f3] dark:border-[#2D2D2D]"
              >
                <div
                  :class="
                    getSelectedWatchlist?.id == item.id
                      ? 'text-appBtnActiveTxt'
                      : 'text-[#000] dark:text-[#F6F6F6]'
                  "
                  class="w-full flex justify-between text-[15px] h-auto py-1 -bg-slate-500"
                >
                  <div class="flex items-center space-x-8">
                    <!-- <div
                    :class="[
                      isActive == item.id
                        ? 'text-[#F6F6F6] hover:bg-[#2250ce] dark:hover:bg-appBtnActive'
                        : 'text-[#000000] dark:hover:!bg-[#2D2D2D] hover:bg-[#ced0d6]',

                      isMouseOverOnWatchlist == item.id
                        ? 'visible'
                        : 'invisible',
                    ]"
                    class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center mr-2 p-1 cursor-pointer transition"
                  >
                    <svg
                      :class="
                        isActive == item.id
                          ? 'text-[#F6F6F6]'
                          : 'text-[#000000] dark:!text-[#F6F6F6]'
                      "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                      />
                    </svg>
                  </div> -->
                    <span @click="gotoSymbols(item.id, index)" class="w-full">
                      {{ item?.name }}
                    </span>

                    <div class="flex space-x-2">
                      <div
                        @click="openEditWatchlist(index)"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-appBtnTxt hover:bg-BlackBtnHover dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-[#ced0d6] dark:hover:bg-[#FFFFFF1A]',

                          isMouseOverOnWatchlist == item.id
                            ? 'visible'
                            : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-appBtnActiveTxt'
                              : 'text-[#000] dark:text-[#F6F6F6] '
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                          />
                        </svg>
                      </div>

                      <div
                        @click="openDeleteWatchlist(item?.id)"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-appBtnTxt hover:bg-BlackBtnHover dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-[#ced0d6] dark:hover:bg-[#FFFFFF1A]',

                          isMouseOverOnWatchlist == item.id
                            ? 'visible'
                            : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-appBtnActiveTxt'
                              : 'text-[#000] dark:text-[#F6F6F6]'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 18 18"
                          width="18"
                          height="18"
                        >
                          <path
                            fill="currentColor"
                            d="M12 4h3v1h-1.04l-.88 9.64a1.5 1.5 0 0 1-1.5 1.36H6.42a1.5 1.5 0 0 1-1.5-1.36L4.05 5H3V4h3v-.5C6 2.67 6.67 2 7.5 2h3c.83 0 1.5.67 1.5 1.5V4ZM7.5 3a.5.5 0 0 0-.5.5V4h4v-.5a.5.5 0 0 0-.5-.5h-3ZM5.05 5l.87 9.55a.5.5 0 0 0 .5.45h5.17a.5.5 0 0 0 .5-.45L12.94 5h-7.9Z"
                          ></path>
                        </svg>
                      </div>

                      <div
                        @click="handleCopy(item)"
                        v-if="item.symbolList?.length > 0"
                        :class="[
                          getSelectedWatchlist?.id == item.id
                            ? 'text-appBtnTxt hover:bg-BlackBtnHover dark:hover:bg-appBtnActive'
                            : 'text-[#000000] hover:bg-[#ced0d6] dark:hover:bg-[#FFFFFF1A]',

                          isMouseOverOnWatchlist == item.id
                            ? 'visible'
                            : 'invisible',
                        ]"
                        class="w-[1.8rem] h-[1.8rem] flex rounded-md justify-center items-center p-1 cursor-pointer transition"
                      >
                        <svg
                          :class="
                            getSelectedWatchlist?.id == item.id
                              ? 'text-appBtnActiveTxt'
                              : 'text-[#000] dark:text-[#F6F6F6]'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="
                    getSelectedWatchlist?.id == item.id
                      ? 'text-appBtnActiveTxt'
                      : 'text-[#000] dark:text-[#F6F6F6]'
                  "
                  class="text-[15px] text-[#000] flex justify-between items-center h-auto"
                >
                  {{ item?.symbolList?.length }}
                </div>
              </div>
            </div>

            <!-- <div
            @click="myHotists"
            :class="!isMyWatchlists ? 'bg-[#F0F3FA] dark:bg-[#2D2D2D]' : ''"
            class="flex px-3 hover:bg-[#F0F3FA] dark:hover:bg-[#FFFFFF1A] text-[15px] font cursor-pointer space-x-4 py-2.5"
          >
            <div>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 28"
                  width="28"
                  height="28"
                >
                  <path
                    fill="currentColor"
                    d="M12.18 2.11a.5.5 0 0 1 .54-.06c1.82.9 3.82 2.45 5.02 4.6a8.53 8.53 0 0 1 .53 7.26 2.47 2.47 0 0 0 1.61-1.2c.45-.77.55-1.87.15-3.05a.5.5 0 0 1 .82-.52l.09.09c.85.83 1.83 1.79 2.5 3.08a7.75 7.75 0 0 1 .55 5.29c-.7 3.44-3.17 6.4-6.34 7.38a.5.5 0 0 1-.37-.03 5.85 5.85 0 0 1-2.44-1.96 5.38 5.38 0 0 1-.79-2.47 7.04 7.04 0 0 0-1.54 1.87c-.4.74-.51 1.43-.51 2.11a.5.5 0 0 1-.62.48A9.76 9.76 0 0 1 4.32 18c-.69-2.55-.1-5.1 1.1-7.21a11.83 11.83 0 0 1 4.86-4.74.5.5 0 0 1 .66.68c-.63 1.23-.56 1.82-.47 2.06l.01.02a6.39 6.39 0 0 0 1.7-2.48c.3-.92.26-2.03-.16-3.7a.5.5 0 0 1 .16-.52Zm-1.84 7.87-.01-.01a.78.78 0 0 1-.1-.05 1.48 1.48 0 0 1-.68-.78 2.45 2.45 0 0 1-.04-1.47c-1.23.88-2.4 2.14-3.23 3.61a8.54 8.54 0 0 0-1 6.45c.83 3 3 5.2 5.75 6.11.07-.61.24-1.25.6-1.92a9.13 9.13 0 0 1 2.56-2.82.5.5 0 0 1 .81.4c.01 1.4.25 2.3.68 2.95.4.6 1 1.06 1.86 1.51 2.7-.92 4.84-3.5 5.47-6.56.42-2.04.12-3.5-.47-4.64A8.1 8.1 0 0 0 21.28 11c.02.8-.16 1.57-.53 2.21A3.6 3.6 0 0 1 17.5 15a.5.5 0 0 1-.46-.7c1.26-2.85.9-5.26-.17-7.16a10.41 10.41 0 0 0-3.65-3.67c.22 1.23.2 2.24-.09 3.16-.37 1.18-1.17 2.14-2.28 3.23a.5.5 0 0 1-.5.12Z"
                  ></path>
                </svg>
              </span>
            </div>
            <div class="w-full flex items-center justify-between">
              <div class="text-[14px] dark:text-[#F6F6F6]">Hotists</div>
            </div>
          </div> -->
          </div>

          <div class="w-full h-auto">
            <div
              @click="handleNewWatchlist"
              class="flex px-3 hover:bg-appBtnHover dark:hover:bg-[#FFFFFF1A] text-[15px] items-center font cursor-pointer space-x-4 py-2.5"
            >
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    width="28"
                    height="28"
                  >
                    <path
                      fill="currentColor"
                      d="M16.59 22.21 14 21.1 5 25V3h18v10h-1V4H6v19.48L14 20l3 1.3-.41.91Z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M22 19v-4h1v4h4v1h-4v4h-1v-4h-4v-1h4ZM9 8h10V7H9v1ZM9 12h10v-1H9v1ZM17 16H9v-1h8v1Z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="text-[14px] dark:text-[#F6F6F6]">Create new list</div>
            </div>

            <div
              @click="openDownloadSymbol(getSelectedWatchlist?.symbolList)"
              :class="
                watchLists?.length > 0 ? '' : 'pointer-events-none opacity-50'
              "
              class="flex px-3 hover:bg-appBtnHover dark:hover:bg-[#FFFFFF1A] text-[15px] items-center cursor-pointer space-x-4 py-2.5"
            >
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="none"
                  >
                    <path
                      stroke="currentColor"
                      d="M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 5V17m-4-3.5l4 4l4-4"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="text-[14px]dark:text-[#F6F6F6]">Import list</div>
            </div>
          </div>
        </div>

        <!-- Symbols -->

        <div
          v-if="isWatchlistSelect == false"
          class="py-3 qm:hidden h-full !w-full -bg-slate-400"
        >
          <div
            v-if="filteredSymbols?.length > 0"
            class="w-full h-full object-top"
          >
            <!-- <div
            class="w-full h-[3.5rem] flex justify-between items-center border-b dark:border-[#2D2D2D] -bg-slate-600"
          >
            <div class="w-[23%] h-auto pl-14 py-1 -bg-slate-500">
              CREATED LISTS
            </div>
            <div class="w-[20%] h-auto">SYMBOLS</div>
          </div> -->

            <SymbolTable
              :display="displayHidden"
              :filteredSymbols="filteredSymbols"
            />
          </div>

          <div
            v-else
            class="w-full h-full text-[1.5rem] text-appBtnActive flex items-center justify-center"
          >
            No symbol found.
          </div>
        </div>
      </div>

      <!-- Right Area-->

      <!-- My Watchlist  -->
      <div
        class="xxs:!hidden qm:!block py-3 h-full w-[75%] -bg-gray-400 -bg-slate-400"
      >
        <!-- Search Bar -->

        <div
          class="w-full -border-t border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-4 py-[6px] -mt-4 items-center flex space-x-2 dark:border-[#232323]"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
            >
              <path
                stroke="#a3a6af"
                d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
              ></path>
            </svg>
          </span>
          <span class="w-full">
            <input
              type="text"
              v-model="searchTerm"
              placeholder="Search Symbols"
              class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
            />
          </span>
        </div>

        <div
          v-if="filteredSymbols?.length > 0"
          class="w-full h-full object-top"
        >
          <!-- <div
            class="w-full h-[3.5rem] flex justify-between items-center border-b dark:border-[#2D2D2D] -bg-slate-600"
          >
            <div class="w-[23%] h-auto pl-14 py-1 -bg-slate-500">
              CREATED LISTS
            </div>
            <div class="w-[20%] h-auto">SYMBOLS</div>
          </div> -->

          <SymbolTable
            :filteredSymbols="filteredSymbols"
            :isDisplay="isdisplay"
          />
        </div>

        <div
          v-else
          :class="watchLists?.length > 0 ? 'block' : 'hidden'"
          class="w-full h-full text-[1.5rem] text-appBtnActive flex items-center justify-center"
        >
          No symbol found.
        </div>

        <div
          :class="watchLists?.length > 0 ? 'hidden' : 'block'"
          class="w-full h-full text-[1.5rem] text-appBtnActive flex items-center justify-center"
        >
          No watchlist found.
        </div>
      </div>

      <!-- Hotists -->

      <!-- <div
        v-if="!isMyWatchlists"
        class="py-3 flex justify-center items-center h-full text-[18px] overflow-y-auto no-scrollbar w-[75%] -bg-slate-400"
      >
        <strong>Hotists</strong>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'
import SymbolTable from './SymbolTable.vue'

export default {
  name: 'WatchlistModalTv',
  data() {
    return {
      showDetail: false,
      width: window.innerWidth,
      searchTerm: '',
      isWatchlistSelect: true,
      isMouseOverOnWatchlist: null,
      // isActive: null,
      selectedSymbolList: [],
      widthIsLessThan768: false,
      isdisplay: false,
      createdWatchlist: null,
    }
  },
  components: {
    ModalHeader,
    ModalFooter,
    SymbolTable,
  },

  computed: {
    ...mapGetters(['watchLists', 'getSelectedWatchlist', 'selectedWatch']),

    filteredSymbols() {
      if (this.searchTerm) {
        console.log(
          'watchlist==>',
          this.getSelectedWatchlist?.symbolList?.map((item) => item?.name)
        )
        return this.getSelectedWatchlist?.symbolList?.filter((item) => {
          // No need to set `this.filteredSymbolList` inside the loop
          console.log('item==>', item?.name)
          return item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        })
      } else {
        // If there's no search term, return the entire symbol list
        return this.getSelectedWatchlist?.symbolList || []
      }
    },
  },

  methods: {
    ...mapActions([
      'toggleIndicator',
      'updateIndicatorList',
      'setSelectedWatch',
      'updateCopySymbol',
    ]),

    handleCopy(item) {
      this.updateCopySymbol(item.symbolList)
      this.handleNewWatchlist()
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },

    openRemoveSymbolModal(sym) {
      this.removeSymbol = sym
      this.isOpenRemoveSymbolModal = true
    },

    gotoSymbols(id, ind) {
      if (this.width < 768) {
        this.isWatchlistSelect = false
      }
      this.setSelectedWatch(ind)
      // this.isActive = id
    },

    onCloseHandler() {
      this.$emit('close')
    },

    openEditWatchlist() {
      this.$emit('handleOpenEditWatchlistModal')
    },

    openDownloadSymbol(watchlist) {
      this.$emit('handleOpenDownloadSymbolModal')
      console.log('watchListsno', this.watchLists, watchilst)
    },

    watchlistOpen() {
      this.isWatchlistSelect = true
    },

    handleSelectWatchlist(id, ind) {
      this.setSelectedWatch(ind)
      // this.isActive = id
    },

    mouseOverOnWatchlist(id) {
      this.isMouseOverOnWatchlist = id
      // console.log('isMouseOverOnWatchlist', id, this.isMouseOverOnWatchlist)
    },

    mouseLeaveWatchlist() {
      this.isMouseOverOnWatchlist = null
      // console.log('isMouseOverWatchlist', this.isMouseOverOnWatchlist)
    },

    myWatchlists() {
      this.isMyWatchlists = true
      // console.log('myWatchlists', this.isMyWatchlists)
    },
    myHotists() {
      this.isMyWatchlists = false
      console.log('myWatchlists', this.isMyWatchlists)
    },
    openDeleteWatchlist(id) {
      this.$emit('handleOpenRemoveWatchlistModal', id)
    },

    closeWatchlistDetailModal() {
      this.$emit('closeModal')
      console.log('closseWatchlistDetailModal tv')
    },
    handleNewWatchlist() {
      this.$emit('handleOpenAddWatchlistModal')
    },
    getRightArea() {
      // [(showDetail && width < 520 ) ?'block w-[100%]':'hidden',width >= 520?'w-[70%] block':'']
      if (this.width >= 520) {
        return 'w-[70%] border-l border-[#D1D1D1] dark:border-[#232323]'
      } else {
        if (this.showDetail || this.searchTerm) {
          return 'w-[100%] block'
        } else {
          return 'hidden'
        }
      }
    },

    getLeftArea() {
      // [(showDetail && width < 520 ) ?'hidden':'block w-[100%]',width >= 520?'w-[30%]':'']
      if (this.width >= 520) {
        return 'w-[30%]'
      } else {
        if (this.showDetail || this.searchTerm) {
          return 'hidden'
        } else {
          return 'w-[100%] block'
        }
      }
    },

    onResize() {
      this.width = window.innerWidth
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    if (this.getSelectedWatchlist?.id) {
      // this.isActive = this.getSelectedWatchlist?.id
    }
    window.addEventListener('resize', this.onResize)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    window.addEventListener('resize', this.updateScreenWidth)
  },
  watch: {
    watchLists(val) {
      console.log('watchlist list', val)
    },
  },
}
</script>

<style>
/* Scrollbar styles */
.scroll-container {
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media only screen and (max-width: 1400px) {
  .indicatorContainer {
    width: 60vw !important;
  }
}
@media only screen and (max-width: 860px) {
  .indicatorContainer {
    width: 80vw !important;
  }
}
@media only screen and (max-width: 540px) {
  .indicatorContainer {
    width: 90vw !important;
  }
}
@media only screen and (max-width: 520px) {
  .indicatorContainer {
    width: 85vw !important;
  }
}
</style>

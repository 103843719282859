<template>
  <!-- icon dark:bg-[#FFFFFF1A] -->
  <!-- icon dark:text-[#B0B0B0] -->
  <!-- btn-bg dark:hover:bg-[#232323]  -->
  <!-- btn-bg-light dark:hover:bg-[#F6F6F6]  -->
  <!-- txt dark:text-[#F6F6F6] -->
  <!-- dark:!border-[#2D2D2D] -->

  <div
    class="xxs:w-[88vw] lg:!w-[70vw] xl:!w-[48vw] h-[69] rounded-[8px] -bg-slate-400 !overflow-hidden"
  >
    <div class="w-full h-full text-[#000000]">
      <div
        class="w-full !h-[60px] border-b border-[#E2E8F0] -!bg-slate-500 px-3 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader header="Symbol lookup" @close="closeModal" />
      </div>
      <!-- Search Bar -->
      <div
        class="px-3 mt-2 w-full flex flex-col justify-around xxs:h-[65vh] -bg-slate-600 md:h-[69vh]"
      >
        <div class="xxs:h-auto md:!h-[6vh] md:py-1 -bg-green-500">
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] xxs:!leading-[10px] xxs:text-[12px] md:text-[13px] font-normal"
            >Enter the stock symbol or company name to search for real-time data
            and trading information. For example, type 'AAPL' for Apple Inc. or
            'GOOGL' for Alphabet Inc.</span
          >
        </div>

        <div
          class="flex items-center xxs:h-auto md:!h-[4vh] shrink-0 w-full space-x-1 -bg-red-600 dark:hover:bg-[#23232370] dark:!border-[#2D2D2D] qm:mt-0 xxs:mt-4 px-2 py-1.5 text-sm hover:bg-appBtnHover dark:bg-[#121212] bg-[#F6F6F6] rounded-xl"
          :class="
            getTheme ? '!border-[#1a1a1a] border' : 'border border-[#D1D1D1]'
          "
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
              stroke="#6D6D6D"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5 17.5L13.875 13.875"
              stroke="#6D6D6D"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <input
            v-model="filteredtext"
            v-on:keyup="keyUpSearch"
            class="bg-transparent text-[#181818] uppercase dark:text-[#ffffff80] placeholder:text-[#6D6D6D] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full"
            placeholder="Symbol, eg: AAPL"
          />
          <div class="flex items-center space-x-3">
            <div @click="clearInput" class="text-[#7C7C7C] text-[14px]">
              Clear
            </div>
            <svg
              width="2"
              class="text-[#E7E7E7] dark:text-[#7C7C7C]"
              height="28"
              viewBox="0 0 2 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2" height="28" fill="currentColor" />
            </svg>

            <svg
              @click="closeModal"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#7C7C7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#7C7C7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div
          class="bg-[#FFFFFF] xxs:h-[75%] md:h-[80%] dark:bg-[#121212] qm:mt-2 xxs:mt-6 shrink-0 rounded-[12px] -shadow-lg"
        >
          <div
            class="w-full flex h-[10%] items-center mt-1 space-x-3 px-2 overflow-x-auto no-scrollbar"
          >
            <div
              v-for="(metadata, i) in markets"
              :key="metadata.id"
              v-show="metadata.show"
              className="flex items-center"
              @click="changeMarketState(i, 'markets', 'showCategory')"
            >
              <div
                :ref="`marketDiv_${metadata.code}`"
                class="flex p-1.5 w-fit px-4 rounded-3xl text-[14px] suggestionText whitespace-nowrap font-[400]"
                :class="
                  metadata.selected
                    ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                    : 'bg-[#F6F6F6] cursor-pointer hover:bg- dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'
                "
              >
                {{ metadata.desc }}
              </div>
            </div>
          </div>
          <div
            class="w-full flex h-[10%] items-center space-x-3 px-2 overflow-x-auto no-scrollbar"
          >
            <div
              v-for="(metadata, i) in categoryByMarket"
              :key="metadata.id"
              className="flex items-center"
              v-show="showCategory && metadata.show"
              @click.prevent="
                updateContract(i, metadata.desc, 'category', 'showContracts')
              "
            >
              <div
                :ref="`categoryDiv_${metadata.desc}`"
                class="flex p-1.5 w-fit px-4 rounded-3xl text-[14px] suggestionText whitespace-nowrap font-[400]"
                :class="
                  metadata.selected
                    ? 'text-[#F6F6F6] cursor-default bg-[#292929] font-[500]'
                    : 'bg-[#F6F6F6] cursor-pointer hover:bg-[#e2e2e2] dark:hover:bg-[#232323]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'
                "
              >
                {{ metadata.desc }}
              </div>
            </div>
          </div>

          <!-- Rows -->
          <div
            class="w-full h-[75%] overflow-y-auto no-scrollbar oveflow-x-hidden"
          >
            <div
              class="w-full h-full flex items-center justify-center text-[16px] text-[#292929] dark:text-[#F6F6F6] font-[500]"
              v-if="isLoading"
            >
              <Loader></Loader>
            </div>
            <div
              class="w-full h-full sm:text-[16px] text-[12px]"
              v-else-if="symbolData?.length > 0"
            >
              <div
                v-for="(items, key) in symbolData"
                :key="key"
                @click="selectSymbol(items)"
                class="flex items-start justify-between cursor-pointer px-2 dark:hover:!bg-[#2D2D2D30] hover:bg-[#E4E4E460] border-b dark:border-b-[#2D2D2D] py-4 border-b-[#E7E7E7]"
              >
                <div class="flex items-start sm:space-x-4 space-x-1">
                  <div>
                    <div
                      class="w-12 h-12 flex uppercase items-center dark:bg-[#676868] text-[white] dark:text-[#f6f6f6] justify-center rounded-full bg-[#6B7280]"
                    >
                      <TickerIcon :ticker="items.symbol" />
                    </div>
                  </div>
                  <div class="dark:text-[#F6F6F6]">
                    <div
                      class="font-[300] text-[1.3rem]"
                      :title="items?.companyName"
                    >
                      <span
                        class="font-[600] mr-2"
                        :class="screenWidth < 361 ? 'text-[12px]' : ''"
                        >{{ items?.symbol }}</span
                      ><span class="!font-normal dark:text-[#ffffff90]">{{
                        getTruncateText(items?.companyName)
                      }}</span>
                    </div>
                    <div class="text-[#BDBDBD] mt-1 sm:text-[14px]">
                      {{ items?.exchange }}
                    </div>
                  </div>
                </div>

                <div class="flex sm:items-center sm:space-x-5">
                  <div>
                    <div class="font-[600] dark:text-white priceText">
                      {{ items?.close ? `$ ${items?.close}` : '$0'
                      }}<span
                      :class=" items?.change < 0 ?'text-errorText':' text-[#037950]'"
                        class="text-[12px] changeText ml-1 font-[500]"
                        >
                       {{ items?.change?.toFixed(2) || '0' }}
                        {{
                          items?.changepercent
                            ? `${items.changePercent?.toFixed(2)}%`
                            : '(0.0%)'
                        }}</span
                      >
                    </div>
                    <div>
                      <div
                        class="text-[#BDBDBD] sm:text-right font-[600] text-[9px]"
                        :class="screenWidth < 361 ? 'text-[7px]' : ''"
                      >
                        Last updated: Yesterday
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full h-full flex items-center justify-center text-[16px] text-[#292929] dark:text-[#F6F6F6] font-[500]"
              v-else
            >
              <p>No Symbol Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import postRequest from '../api/postRequest'
import { serverUrl } from '../api/serverUrl'
import Loader from '../component/Loading.vue'
import symbol from '../services/symbol'
import { fetchSymbolByName } from '../services/symbol'
import { marketsData } from '../data/markets'
import ModalHeader from './ModalHeader.vue'
import TickerIcon from './TickerIcon.vue'

const categoryList = [
  // {
  //   show: true,
  //   id: 0,
  //   desc: '0-9',
  //   keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  //   selected: false,
  // },
  {
    show: true,
    id: 1,
    desc: 'A-D',
    keys: ['A', 'B', 'C', 'D'],
    selected: false,
  },
  {
    show: true,
    id: 2,
    desc: 'E-G',
    keys: ['E', 'F', 'G'],
    selected: false,
  },
  {
    show: true,
    id: 3,
    desc: 'H-L',
    keys: ['H', 'I', 'J', 'K', 'L'],
    selected: false,
  },
  {
    show: true,
    id: 4,
    desc: 'M-R',
    keys: ['M', 'N', 'O', 'P', 'Q', 'R'],
    selected: false,
  },
  {
    show: true,
    id: 5,
    desc: 'S-V',
    keys: ['S', 'T', 'U', 'V'],
    selected: false,
  },
  {
    show: true,
    id: 6,
    desc: 'W-Z',
    keys: ['W', 'X', 'Y', 'Z'],
    selected: false,
  },
]

const localMarket = marketsData.map((m) => {
  return { ...m, show: true, categoryList }
})

const getLocalMarketClone = () => {
  return JSON.parse(JSON.stringify(localMarket))
}

export default {
  name: 'searchSymbol',

  data() {
    return {
      filtered2: [],
      symbolData: [],
      inputValue: '',
      isLoading: false,
      selectedMarket: 'ASX',
      category: JSON.parse(JSON.stringify(categoryList)),
      header: [
        { id: 1, name: 'All', value: 'all' },
        { id: 2, name: 'Recently Searched', value: 'recentlySearched' },
        { id: 3, name: 'Frequently Searched', value: 'frequentlySearched' },
        { id: 4, name: 'Good Stocks', value: 'goodStocks' },
        { id: 5, name: '{Scanner}', value: 'scanner' },
        { id: 6, name: '{Scanner}', value: 'scanner1' },
        { id: 7, name: '{Scanner}', value: 'scanner2' },
        { id: 8, name: '{Scanner}', value: 'scanner3' },
      ],
      selectedRange: '0-9',
      markets: getLocalMarketClone(),
      alphabets: [
        { key: 'a-d', value: 'A-D' },
        { key: 'e-g', value: 'E-G' },
        { key: 'h-l', value: 'H-L' },
        { key: 'm-r', value: 'M-R' },
        { key: 's-v', value: 'S-V' },
        { key: 'w-z', value: 'W-Z' },
      ],
      selectedAlphabet: { key: 'a-d', value: 'A-D' },
      selectedVal: { key: 'all', value: 'All' },
      screenWidth: window.innerWidth,
      tickerInfo: [],
      showCategory: true,
      activateSearch: true,
      filteredtext: '',
      searchMode: false,
    }
  },

  components: {
    Loader,
    ModalHeader,
    TickerIcon,
  },

  computed: {
    ...mapGetters(['getTheme']),
    filteredContent() {
      return this.tickerInfo?.filter((item) =>
        item?.ticker?.toLowerCase().includes(this.inputValue?.toLowerCase())
      )
    },
    categoryByMarket() {
      if (this.markets && this.markets.length) {
        let selectedCategory = this.markets.find(
          (m) => m.code === this.selectedMarket
        )
        return selectedCategory && selectedCategory.categoryList
          ? selectedCategory.categoryList
          : []
      }
      return []
    },
  },
  methods: {
    keyUpSearch(e) {
      const ignoredKeyCodes = [13, 35, 36, 37, 38, 39, 40, 16, 17]
      const { keyCode, target } = e
      const searchText = target.value.trim()
      if (searchText === '') {
        if (this.searchMode) {
          this.filteredText(searchText)
        }
        this.searchMode = false
        return
      } else {
        this.searchMode = true
      }

      if (ignoredKeyCodes.indexOf(keyCode) > -1) {
        return
      }
      this.activateSearch = true
      this.filteredText(searchText)
    },

    async filteredText(text, updateText = true) {
      console.log('filter text')
      let isRange = !updateText
      if (this.activateSearch) {
        this.isLoading = true
        let filtered2 = await fetchSymbolByName(
          this,
          text,
          false,
          isRange,
          isRange ? this.selectedMarket : '',
          true,
          true
        )
        if (typeof filtered2 !== 'string') {
          this.filtered2 = filtered2
          this.isLoading = false
          this.filterData(updateText)
        } else {
          let textIsNotExist = text === null || text.length === 0
          if (textIsNotExist) {
            this.isLoading = false
            this.clearSymbolSearch()
          }
        }
      } else {
        this.filterData(updateText)
      }
    },
    clearSymbolSearch() {
      this.filteredtext = ''
      this.filtered2 = []
      this.symbolData = []
      this.activateSearch = true
      this.markets = getLocalMarketClone()
    },
    filterData(updateText) {
      let filterData = this.filtered2
      if (filterData) {
        let selectedRange = this.category.find(
          (d) => d.desc === this.selectedRange
        )
        let selectedMarketData = filterData[this.selectedMarket]
        let filterMarkets = []
        let firstMarketKey
        let firstCategory
        if (updateText) {
          let marketKeys = Object.keys(filterData)
          if (marketKeys.length) {
            for (const marketKey of marketKeys) {
              let marketData = filterData[marketKey]
              if (!firstMarketKey) firstMarketKey = marketKey
              let keys = Object.keys(marketData)

              let localMarketClone = getLocalMarketClone()
              let filterMarket = localMarketClone.find(
                (m) => m.code === marketKey
              )
              if (filterMarket) {
                let categoryList = filterMarket.categoryList
                let filteredCatList = categoryList.filter((m) => {
                  let categoryKeys = m.keys
                  let result = categoryKeys.find((element) =>
                    keys.includes(element)
                  )
                  return result
                })

                if (filteredCatList.length) {
                  filterMarket.categoryList = filteredCatList
                  firstCategory = filteredCatList[0].desc
                }

                filterMarkets.push(filterMarket)
              }
            }
          }

          this.markets = filterMarkets
        }

        let alphaCategoryData = []
        if (selectedRange) {
          let keys = selectedRange.keys
          for (const selectedMarketDataKey in selectedMarketData) {
            if (keys.includes(selectedMarketDataKey)) {
              alphaCategoryData.push(
                ...selectedMarketData[selectedMarketDataKey]
              )
            }
          }
        }

        this.symbolData = alphaCategoryData
        this.showCategory = true
        this.showContracts = true

        if (firstMarketKey) {
          this.selectedMarket = firstMarketKey
          setTimeout(() => {
            this.selectMarketRef(this.selectedMarket)
          }, 300)
        }
      }
    },
    selectMarketRef(selectedMarket) {
      let marketRef = this.$refs[`marketDiv_${selectedMarket}`]
      if (marketRef && marketRef[0]) {
        marketRef[0].click()
      }
    },
    async updateContract(id, desc) {
      this.selectedRange = desc
      let allMarkets = this.markets
      if (allMarkets && allMarkets.length) {
        let selectedMarket = allMarkets.find(
          (m) => m.code === this.selectedMarket
        )
        if (selectedMarket) {
          let categoryList = selectedMarket.categoryList
          categoryList = categoryList.map((v, i) => {
            if (i === id) {
              v.selected = true
            } else {
              v.selected = false
            }
            return v
          })
          selectedMarket.categoryList = categoryList
        }
      }

      if (this.filteredtext && this.filteredtext.length) {
        this.activateSearch = false
      }
      await this.filteredText(desc, false)
    },
    changeMarketState(id, prop, changeProp) {
      console.log('data==>', id, prop, changeProp)
      this[prop] = this[prop].map((v, i) => {
        if (i === id) {
          v.selected = true
        } else {
          v.selected = false
        }
        return v
      })
      this[changeProp] = true
      this.selectedMarket = this[prop][id].code
      this.symbolData = []
      let filterMarket = this.markets.find(
        (m) => m.code === this.selectedMarket
      )
      if (
        filterMarket &&
        filterMarket.categoryList &&
        filterMarket.categoryList.length
      ) {
        let selectedRange = filterMarket.categoryList[0].desc
        let divRefs = this.$refs[`categoryDiv_${selectedRange}`]
        if (divRefs && divRefs[0]) {
          divRefs[0].click()
        }
      }
    },
    selectSymbol(item) {
      console.log('item', item)
      // this.$emit('handleSymbol', item?.symbol)
      this.$emit('handleSymbol', item)
      this.closeModal()
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },

    clearInput() {
      this.inputValue = ''
    },
    getTruncateText(val) {
      let maxLength = 15
      if (this.screenWidth > 500) {
        if (val?.length > maxLength && this.screenWidth < 1151) {
          return val?.substring(0, maxLength) + '..'
        } else {
          if (val?.length > 30) {
            return val?.substring(0, 30) + '..'
          } else {
            return val
          }
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
    handleData(item) {
      this.selectedVal = item
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },

  watch: {},

  mounted() {
    this.changeMarketState(0, 'markets', 'showCategory')
    // Add event listener when the component is mounted
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}
</style>

<template>
  <!-- icon dark:bg-[#FFFFFF1A] -->
  <!-- icon dark:text-[#B0B0B0] -->
  <!-- btn-bg dark:hover:bg-[#232323]  -->
  <!-- btn-bg-light dark:hover:bg-[#F6F6F6]  -->
  <!-- txt dark:text-[#F6F6F6] -->

  <div
    class="xxs:w-[88vw] xs:w-[70vw] md:w-[55vw] -h-[28.2vh] lg:w-[30vw] -px-3 space-y-1 bg-white !overflow-hidden"
  >
    <div class="w-full h-full text-[#000000] !overflow-x-hidden">
      <!-- <div
        class="flex justify-between border-b border-[#E2E8F0] h-[4vh] dark:border-[#2D2D2D] py-2 px-3 items-center"
      >
        <p class="font-[500] dark:text-[#F6F6F6] text-[#000000] text-[14px]">
          Remove {{ data }} from
          <span>“{{ getSelectedWatchlist?.name }}"</span> ?
        </p>
        <div
          class="p-2 rounded-full block bg-[#F6F6F6] cursor-pointer hover:bg-[#e4e3e3] dark:hover:bg-[#232323] dark:bg-[#FFFFFF1A]"
          @click="closeRemoveModal"
        >
          <div class="dark:text-[#B0B0B0] text-[#BCBCBC]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 4L12 12"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div> -->

      <!--Header-->

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-3 flex flex-row justify-between items-center space-x-3"
      >
        <ModalHeader
          :header="`Remove “${data}” from “${getSelectedWatchlist?.name}”?`"
          @close="closeRemoveModal"
        />
      </div>

      <!-- Search Bar -->
      <div class="px-8 py-3 border-b border-t border-[#E2E8F0] w-full flex flex-col -justify-center min-h-[130px] -min-h-[158px]">
        <div class="h-full flex flex-col gap-2 justify-center">
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[16px] font-normal"
            >Are you sure you want to remove this ticker from your watchlist?
          </span>
          <span
            class="text-[#6D6D6D] dark:text-[#F6F6F6] text-[16px] font-normal"
            >Don't worry, you can always add it back whenever you need.
          </span>
          <span v-if="showError" class="text-errorText text-[13px] font-normal"
            >{{ showError }}
          </span>
        </div>
      </div>

      <!-- <div
        :class="isLoading ? 'pointer-events-none' : ''"
        class="flex justify-end gap-2 border-t border-[#E2E8F0] h-[5vh] dark:border-[#2D2D2D] py-2 px-3 items-center"
      >
        <div
          @click="closeRemoveModal"
          class="text-[#292929] font-[600] text-[13px] hover:bg-[#F6F6F6] dark:text-[#F6F6F6] dark:hover:bg-[#232323] dark:bg-[#FFFFFF1A] dark:!border-[#2D2D2D] px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px]"
        >
          No, Keep this
        </div>
        <div
          @click="saveRemovedSymbol()"
          class="text-[#FFFFFF] bg-appBlue flex items-center space-x-2 font-[600] text-[13px] hover:bg-blueHover px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px] dark:!border-[#2D2D2D]"
        >
          <span> Yes, Remove </span>
          <span v-if="isLoading">
            <Loader customClass="w-4 h-4" />
          </span>
        </div>
      </div> -->

      <!-- Footer -->

      <div
        class="w-full !h-[50px] -!bg-slate-500 px-4 flex flex-row justify-between items-center space-x-3"
      >
        <ModalFooter
          firstTitle="No, Keep this"
          secondTitle="Yes, Remove"
          colorType="color"
          :isLoading="isLoading"
          @close="closeRemoveModal"
          @save="saveRemovedSymbol"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '../component/Loading.vue'
import ModalHeader from './ModalHeader.vue'
import ModalFooter from './ModalFooter.vue'

export default {
  name: 'RemoveSymbolModal',

  data() {
    return {
      isLoading: false,
      screenWidth: window.innerWidth,
      showError: '',
    }
  },

  props: {
    data: String,
  },

  components: {
    Loader,
    ModalFooter,
    ModalHeader,
  },

  computed: {
    ...mapGetters(['getTheme', 'getSelectedWatchlist']),
  },

  methods: {
    ...mapActions(['removeCurrentSymbolFromList']),

    async saveRemovedSymbol() {
      this.isLoading = true
      let res = await this.removeCurrentSymbolFromList(this.data)
      if (res?.data) {
        this.isLoading = false
        this.closeRemoveModal()
      } else {
        this.showError = 'Error while removing symbol from watchlist'
        setTimeout(() => {
          this.showError = ''
        }, 5000)
      }
      this.isLoading = false
    },

    closeRemoveModal() {
      this.$emit('closeRemoveModal')
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },

  watch: {},

  mounted() {
    // Add event listener when the component is mounted
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyDown)
    window.addEventListener('resize', this.updateScreenWidth)
  },
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .suggestionText {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .suggestionText {
    font-size: 7px !important;
    white-space: nowrap;
  }
}

.scroll-container {
  /* Adjust height as needed */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>

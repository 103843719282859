<script>
import { Overlay, Tool } from '../../@trading-vue-js/src/index'
import Pin from './primitives/pin'
import Math2 from './primitives/math.js'
import { chartEvents } from '../../chartEvents.js';
import {mapGetters} from "vuex";

import ToolSavingMixins from "./ToolSavingMixins";
export default {
  name: 'EmojiTool',
  mixins: [Overlay, Tool,ToolSavingMixins],
 
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.0.0' }
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Text', //icon: Icons['segment.png'],
        type: 'Label',
        hint: 'Emoji label',
        data: [], // Default data
        settings: {
          text: '😑',
          x: 50,
          y: 50,
          color: '#23a776',
          fontSize: 24,
          settingsList: ['fontSize'],
          firstRun: false
        } // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      this.pins.push(new Pin(this, 'p1'))
      this.registerMouseEvent(this)
      this.set_state('finished')
      this.$emit('drawing-mode-off')
      // if(this.$props.settings.firstRun){
      //   this.$emit('object-selected')
      // }
      this.$emit('object-selected')
      chartEvents.$emit('emoji-selected')
    },
    /**
     * @param {CanvasRenderingContext2D} ctx
     */
    draw(ctx) {
      if (!this.p1) return

      const { t2screen, $2screen } = this.layout
      const fontSize = this.sett.fontSize || 20
      const fontColor = this.sett.color || 'dodgerblue'
      const fontFamily = 'Arial'
      const text = this.sett.text || '🥺'

      const offset = 5
      const x = t2screen(this.p1[0]) || this.sett.x
      const y = ($2screen(this.p1[1]) || this.sett.y) + fontSize + offset

      // draw text
      ctx.font = `${fontSize}px ${fontFamily}`
      ctx.fillStyle = fontColor

      let lineHeight = fontSize
      const lines = text.split('\n')
      let width = 0
      for (let i = 0; i < lines.length; i++) {
        ctx.fillText(lines[i], x, y + i * lineHeight)
        const textMeasures = ctx.measureText(lines[i])
        width = Math.max(textMeasures.width, width)
        // lineHeight = textMeasures.actualBoundingBoxAscent
      }
      // ctx.fillText(text, x, y)
      if (this.showBackPanel) {
        ctx.globalAlpha = 0.5
        ctx.fillRect(
          x - offset,
          y - lineHeight - offset,
          width + offset,
          (lineHeight + offset) * lines.length
        )
      }
      // y is the bottom point of the text
      this.collisions.push(
        this.make(
          [x - offset, y - lineHeight - offset],
          [x + width, y + (lineHeight + offset) * Math.max(lines.length - 1, 0)]
        )
      )

      this.render_pins(ctx)
    },
    use_for() {
      return ['EmojiTool']
    },
    make(p1, p2) {
      return (x, y) => {
        return Math2.is_within_rectangle([x, y], p1, p2)
      }
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['themePrimaryColor','themeSecondaryColor','themeFontColor','getEmojiIcon']),
    sett() {
      console.log("props==>", this.$props.settings)
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    color() {
      return this.sett.color || '#42b28a'
    },
    text() {
      return this.sett.text || '💙'
    },
    showBackPanel() {
      return false
    },
   
  },
  created() {
    console.log("emoji setting", JSON.stringify(this.$props.settings))
    this.updateSettingsState(this)
  }
}
</script>

<template>
  <div class="p-4">
    <div class=" h-[40rem] flex justify-center items-center" v-if="selectedButtonTypeName == 'ProReversal Signal'">
     <h1> ProReversal Signal Setting still not Yet.</h1></div>
    <Filters v-if="selectedButtonTypeName == 'Filters'" :id="id" />
  </div>
</template>

<script>
import Filters from './Filters.vue'
export default {
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  name: 'ProReversalSignal',
  data() {
    return {
      id: 'proReversalSignal',
    }
  },
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
  components: { Filters },
}
</script>

<style>
</style>
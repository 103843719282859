<template>
  <div class="space-y-2 w-full h-full">
    <!-- first filter -->
    <Wrapper
      @stop="onScanStop"
      @ok="onScanClick"
      header="Big Candle"
      :valid="isValid"
      :isLoading="isLoading"
      :scanType="scanType"
      @is-active="isActiveHandler"
      :activeClass="true"
      :selectedKey="'bigcandle'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Candle Size Comparison
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span>Find a candle</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="1"
            v-model="settings.candleSizeMultiplier"
          />
          <span>times larger than the previous</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="1"
            v-model="settings.previousCandlePeriod"
          />
          <span>candles.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Set the scan to detect candles that are significantly larger than a specified number of preceding candles, indicating potential strong price action.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'BigCandleComp',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      isToggle: false,

      scanType: 'bigCandleScan',
    }
  },
  components: { DescriptionInput, Wrapper },
  methods: {
    ...mapActions([
      'setBigCandleScanSettings',
      'sendBigCandleScanRequest',
      'cancelGuruScans',
      'updateIntraDayModeState',
    ]),
    async onScanClick() {
      console.log('call==')
      this.isLoading = true
      await this.updateIntraDayModeState(false)
      await this.sendBigCandleScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },

    getActive(key1, key2) {
      console.log('key is finding===>', key1, key2)
      return this.settings.mode == key1

      // const filters = this.filterSettingsForScan(this.modalID)
      // if (key1 === 'isIgnoreResultsWithNoRangeForLastDay') {
      //   console.log('isIgnore===>', key1)
      //   return filters[key1]
      // } else if (key1 == 'isExcludeIntraDayData') {
      //   return filters[key1]
      // } else {
      //   return filters[key1][key2]
      // }
    },
    isActiveHandler(key) {
      this.isToggle = !this.isToggle
      console.log('key is finding===>', key)
      const val = this.getActive(key, 'isActive')
      // this.setSetting(key, 'isActive', !val)
    },
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return (
        this.settings.candleSizeMultiplier > 0 &&
        this.settings.previousCandlePeriod > 0
      )
    },
    settings: {
      get() {
        console.log('get value===>')
        return this.gurusScanSettings.bigCandle
      },
      set(value) {
        console.log('set value===>', value)
        this.setBigCandleScanSettings(value)
      },
    },
  },
}
</script>

<style></style>

<template>
  <div class="w-full h-full">
    <div
      class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
    >
      <span>Ranges</span>
    </div>
    <ul
      class="!w-full h-full bg-white -overflow-y-scroll dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] overflow-y-scroll scroll-container"
    >
      <div class="w-full h-full flex flex-col -space-y-1">
        <li
          class="flex w-full h-[5rem] items-center space-x-2 gap-2 px-8 py-4 -border-b text-[#323232] dark:!text-[#6C707A] -rounded-md"
          v-for="(range, index) in rangeList"
          :key="index"
          @click="handleSelectRange(range)"
          :class="
            getChartVisibleRange == range.key
              ? 'bg-appBtnActive dark:!bg-transparent text-appBtnActiveTxt -!border-appBlue'
              : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
          "
        >
          <div class="text-[16px]">{{ range.fullName }}</div>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { chartEvents } from '../../chartEvents'
import { RangeList } from '../../utils/TimeRangesConfig'
import { parse } from 'postcss'
export default {
  name: 'IntervalVue',
  data() {
    return {
      // selectedRange: localStorage.getItem('SET_TIME_RANGE'),
      selectedRange: { key: '1Y', label: '1Y', fullName: '1 Year' },
      rangeList: RangeList,

      rangeList: [
        // { key: '1D', label: '1D', fullName: '1 Day' },
        // { key: '1W', label: '1W', fullName: '1 Week' },
        { key: '1M', label: '1M', fullName: '1 Month' },
        { key: '3M', label: '3M', fullName: '3 Months' },
        { key: '6M', label: '6M', fullName: '6 Months' },
        { key: '1Y', label: '1Y', fullName: '1 Year' },
        { key: '50Y', label: 'max', fullName: 'Max' },
      ],
    }
  },
  components: {},
  methods: {
    ...mapActions([
      'setNewChartType',
      'toggleTheme',
      'handleNextSymbol',
      'handlePreviousSymbol',
      'toggleScannerModal',
      'setNewChartTimeFrame',
      'updateTrainingModeState',
      'setChartVisibleRange',
    ]),

    handleSelectRange(item) {
      this.selectedRange = item
      this.setChartVisibleRange(item.key)
      chartEvents.$emit('update-range', item)
      console.log('isItem', item)
      this.$emit('closeRange')
    },
  },
  computed: {
    ...mapGetters(['timeFrame', 'getChartVisibleRange']),
  },
  mounted() {
    console.log('open', this.selectedRange)
    this.selectedInterval = this.timeFrame
  },
  watch: {},
}
</script>
<style scoped></style>

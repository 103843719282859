<template>
  <div class="p-8">
    <div class="-bg-black -text-white py-2">
      <div
        class="flex justify-start !items-center gap-2 w-auto h-[40px] -bg-slate-300"
      >
        <span
          class="-bg-green-400 font-[700] text-[20px] text-[#454545] leading-5"
          >Higher High Higher Low Pattern Detection</span
        >
        <span
          class="w-[32px] h-[32px] flex justify-center items-center -border"
        >
          <svg
            viewBox="0 0 24 25"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            transform="matrix(-1, 0, 0, -1, 0, 0)"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M12 7h1v7h-1zm1.5 9.5a1 1 0 1 0-1 1 1.002 1.002 0 0 0 1-1zm9.3-4A10.3 10.3 0 1 1 12.5 2.2a10.297 10.297 0 0 1 10.3 10.3zm-1 0a9.3 9.3 0 1 0-9.3 9.3 9.31 9.31 0 0 0 9.3-9.3z"
              ></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
            </g>
          </svg>
        </span>
      </div>
      <span class="-bg-yellow-300 -pt-1">
        Automatically detect patterns where a stock forms consecutive higher highs and higher lows, indicating a strong uptrend.</span
      >
    </div>
    <!-- Button Tabs Components -->

    <div v-if="selectedButtonTypeName == 'Higher High, Higher Low'">
      <HigherHighHigherLowComp :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
  </div>
</template>
<script>
import ChartingPatternComp from '../../dialogBoxes/patterns/ChartPatternsIndicator.vue'
import EventsFilter from '../../dialogBoxes/patterns/EventsFilter.vue'
import HigherHighHigherLowComp from './HigherHighHigherLowComp.vue'
export default {
  name: 'HigherHighHigherLow',
  data() {
    return {
      id: 'HigherHighHigherLow',
      dialogId: 'chartEvent',
    }
  },
  components: {
    EventsFilter,
    HigherHighHigherLowComp,
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  methods: {},
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>

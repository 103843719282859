<template>
  <!-- <Multichart /> -->
  <div class="w-[100vw]  h-[100svh] overflow-hidden" :class="getTheme?'bg-[#1a1a1a]':''">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multichart from './Multichart.vue'

export default {
  name: 'App',
  data() {
    return {
      inputBackColor: 'white',
      SelectedItem: 'white',
    }
  },
  components: {
    Multichart,
  },
  computed: {
    ...mapGetters(['getTheme']),
  },
  mounted() {},

  watch: {},
}
</script>

<style scoped></style>


export default {
    // USER_API:"http://45.32.71.124:5000/",
    USER_API:"https://api.protradingscans.com/",
    // USER_API:"http://192.168.0.108:8010",
    // USER_API:"http://localhost:9090",
    CHARTING_API:"https://core.protradingscans.com/api/v1",
    // serverUrl : "https://core.protradingscans.com/api/v1"
    // CHARTING_API:"http://192.168.0.39:5000/api/v1",
    // CHARTING_API:"http://192.168.0.3:5000/api/v1",
    SocketHub:"https://core.protradingscans.com/scannerHub",
    // SocketHub:"http://localhost:5000/scannerHub",
    // CHARTING_API:"http://192.168.0.102:5000/api/v1",
    // CHARTING_API:"http://192.168.0.113:5000/api/v1",
}

// export default {
    // USER_API:"http://45.32.71.124:5000/",
    // USER_API:"https://api.protradingscans.com/",
    // USER_API:"http://192.168.0.108:8010",
    // USER_API:"http://localhost:9090",
    // CHARTING_API:"https://core.protradingscans.com/api/v1",
    // serverUrl : "https://core.protradingscans.com/api/v1"
    // CHARTING_API:"http://192.168.100.8:5000/api/v1",
    // SocketHub:"https://core.protradingscans.com/scannerHub",
    // SocketHub:"http://localhost:5000/scannerHub",
    // CHARTING_API:"http://192.168.0.102:5000/api/v1",
    // CHARTING_API:"http://192.168.0.113:5000/api/v1",
    
// }
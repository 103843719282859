<template>
  <div class="space-y-2 w-full h-full">
    <!-- first filter -->
    <Wrapper
      header="Heikin Ashi"
      @is-active="isActiveHandler"
      :activeClass="true"
      :selectedKey="'heikenAshi'"
    >
      <div class="flex flex-col justify-between -items-center w-full h-full">
        <h3 class="text-[16px] font-[700] leading-[15px]">
          Trend Change Period
        </h3>
        <div class="w-[100%] gap-3 flex items-center">
          <span> Find a period of</span>
          <input
            class="px-3 rounded-[2.5px] border-2 border-appBlue"
            style="width: 11%; height: 45px; font-size: 16px"
            type="number"
            min="1"
            v-model="settings.period"
          />
          <span> days when the Heiken Ashi trend changes.</span>
        </div>

        <div>
          <DescriptionInput
            :description="`Set the scan to detect when the Heiken Ashi candlestick pattern indicates a change in trend within a specified number of days.`"
          />
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import DescriptionInput from '../../DescriptionInput.vue'
import { mapActions, mapGetters } from 'vuex'
import Wrapper from '../../../component/Wrapper.vue'
export default {
  name: 'HeikenAshiComp',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      isToggle: false,

      scanType: 'HeikinAshiScan',
    }
  },
  components: { DescriptionInput, Wrapper },
  methods: {
    ...mapActions([
      'setHeikenAshiScanSettings',
      'sendHeikenAshiScanRequest',
      'cancelGuruScans',
      'updateIntraDayModeState',
    ]),
    getActive(key1, key2) {
      console.log('key is finding===>', key1, key2)
      return this.settings.mode == key1
    },
    isActiveHandler(key) {
      console.log('active clicking....')
      this.isToggle = !this.isToggle
      // console.log('key is finding===>', key)
      // const val = this.getActive(key, 'isActive')
    },
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return this.settings.period > 0
    },
    settings: {
      get() {
        console.log('get value===>')
        return this.gurusScanSettings.heikenAshi
      },
      set(value) {
        console.log('set value===>', value)
        this.setHeikenAshiScanSettings(value)
      },
    },
  },
}
</script>

<style></style>

<template>
  <div class="p-8">
    <div v-if="selectedButtonTypeName == 'Filters'">
      <Filters :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Measures'">
      <Measures :id="id" />
    </div>

    <div v-if="selectedButtonTypeName == 'Capitalization'">
      <Capitalization :id="id" />
    </div>
    <div v-if="selectedButtonTypeName == 'Returns'">
      <Returns :id="id" />
    </div>
  </div>
</template>

<script>
import Filters from './Filters.vue'
import Measures from './Measures.vue'
import Capitalization from './Capitalization.vue'
import Returns from './Returns.vue'
export default {
  components: { Filters, Measures, Capitalization, Returns },
  name: 'FundamentalScanner',
  data() {
    return {
      id: 'fundementals',
    }
  },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log("selectedButtonTypeName",this.selectedButtonTypeName);
  },
  methods: {},
  computed: {},
  watch: {
    selectedButtonTypeName(value){
      console.log("value==>",value);
    }
  },
}
</script>

<style>
</style>  